import React, { Component } from "react";
import { Radio, List, Popover, Empty, Tag } from "antd";
import { UserOutlined, ShoppingOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import i18n from "../../core/i18n";
import CurrencyStore from "../../stores/Currency/CurrencyStore";
import NewOrderStore from "../../stores/Order/NewOrderStore";
import CarCategoryStore from "../../stores/CarCategory/CarCategoryStore";
import GetCarImg from "./GetCarImg";
import getAmountInForeignCurrency from "../../utils/newOrderStore/getAmountInForeignCurrency";
import UserListStore from "../../stores/User/UserListStore";
import getLuggageCapacityContent from "./getLuggageCapacityContent";
import moment from "moment-timezone";

class TopCars extends Component {
  static propTypes = {
    carCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSelect: PropTypes.func.isRequired,
    selectedCar: PropTypes.string,
  };

  static defaultProps = {
    selectedCar: null,
  };

  state = {
    validatedSelectedCar: this.props.selectedCar,
  };

  componentDidMount() {
    this.verifySelectedCarExists(true);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.carCategories !== this.props.carCategories ||
      prevProps.selectedCar !== this.props.selectedCar
    ) {
      this.verifySelectedCarExists(false);
    }
  }

  verifySelectedCarExists(isInitialLoad) {
    const { carCategories, selectedCar } = this.props;
    const carExists = carCategories.some(
      (item) => item.id.toString() === selectedCar
    );
    if (!carExists && selectedCar !== null) {
      this.setState({ validatedSelectedCar: null });
      if (!isInitialLoad) {
        NewOrderStore.resetSelectedCarsSelect();
      }
    } else if (carExists) {
      this.setState({ validatedSelectedCar: selectedCar }, () => {
        if (!isInitialLoad) {
          this.handleSelectionSideEffects(
            carCategories.find((item) => item.id.toString() === selectedCar)
          );
        }
      });
    }
  }

  handleSelectionSideEffects(selectedItem) {
    this.props.onSelect(selectedItem);
    NewOrderStore.filterExtras();
  }

  getItemNameAndDescription(item) {
    let itemName = "";
    let itemDescription = "";
    if (item.combination) {
      item.combination.forEach((carCategory) => {
        if (itemName !== "") {
          itemName += ", ";
        }
        itemName += `${carCategory.carCount}x ${i18n.t(carCategory.nameWithDictionaryPrefix, {
          defaultValue: carCategory.name,
        })}`;
        itemDescription += i18n.t(carCategory.descriptionWithDictionaryPrefix, {
          defaultValue: carCategory.description,
        });
      });
    } else {
      itemName = i18n.t(item.nameWithDictionaryPrefix, { defaultValue: item.name });
      itemDescription = i18n.t(item.descriptionWithDictionaryPrefix, { defaultValue: item.description });
    }
    return { name: itemName, description: itemDescription };
  }

  getIsRetunrDiscountTag = (item) => {
    if (
      item.isreturnDiscount &&
      item.discountPercentage > 0 &&
      !UserListStore.user.isPartner
    ) {
      return (
        <span style={{ display: "inline-block", margin: "4px" }}>
          <Tag color="#f5e79d" style={{ color: "#0d0c0c" }}>
            {i18n.t("Discount for return")}{" "}
            <b style={{ color: "#7a4848" }}>{item.discountPercentage}%</b>
          </Tag>
        </span>
      );
    }
    return null;
  };

  getPayInAdvanceTag = (item) => {
    let requiresAdvancePayment = item.payAdvance;
    if (item.combination) {
      requiresAdvancePayment = item.combination.some(
        (carCategory) => carCategory.payAdvance
      );
    }
    if (requiresAdvancePayment && !UserListStore.user.isPartner) {
      return (
        <span style={{ display: "inline-block", margin: "4px" }}>
          <Popover
            content={i18n.t("Booking must be prepaid at least 24 hours in advance.")}
            title={false}
          >
            <Tag color="orange">{i18n.t("Payment in advance required")}</Tag>
          </Popover>
        </span>
      );
    }
    return null;
  };

  getRefundTag = (item) => {
    let highestRefundCancellation = item.refundCancellation;
    if (item.combination) {
      highestRefundCancellation = item.combination.reduce(
        (max, carCategory) => Math.max(max, carCategory.refundCancellation),
        highestRefundCancellation
      );
    }
    if (highestRefundCancellation === null || highestRefundCancellation === undefined) {
      return null;
    } else if (highestRefundCancellation === 0) {
      return (
        <span style={{ display: "inline-block", margin: "4px" }}>
          <Tag color="magenta">{i18n.t("non refundable")}</Tag>
        </span>
      );
    } else {
      return (
        <span style={{ display: "inline-block", margin: "4px" }}>
          <Popover
            content={`${i18n.t("A full refund will be issued if the cancellation is at least ")} ${highestRefundCancellation} ${i18n.t("hours in advance.")}`}
            title={false}
          >
            <Tag color="#e7ffdd" style={{ color: "#0d0c0c" }}>
              {i18n.t("Free-cancellation")}{" "}
              <b style={{ color: "#7a4848" }}>
                {highestRefundCancellation} {i18n.t("hrs")}
              </b>
            </Tag>
          </Popover>
        </span>
      );
    }
  };

  // --- Helper Methods Using the "there..." Fields for Discount/Surcharge ---

  // For single items, check the discount period using thereDiscountMinDate/MaxDate.
  checkDiscountFrom = (item) => {
    if (!item) return false;
    const pickupDate = moment(NewOrderStore.getFormattedPickupDates().pickupDateTime);
    const discountMinDate = item.thereDiscountMinDate ? moment(item.thereDiscountMinDate) : null;
    const discountMaxDate = item.thereDiscountMaxDate ? moment(item.thereDiscountMaxDate) : null;
    if (!discountMinDate || !discountMaxDate) return false;
    return pickupDate.isBetween(discountMinDate, discountMaxDate, null, "[]");
  };

  // For single items, return the discount value from thereDiscount.
  getCurrentDiscount = (item) => {
    if (item.combination && item.combination.length > 0) {
      return item.thereDiscount || item.combination[0].thereDiscount;
    }
    return item.thereDiscount;
  };

  // For checking back-route discount period.
  checkDiscountTo = (dataCollection) => {
    if (!dataCollection) return false;
    const pickupDate = moment(NewOrderStore.getFormattedPickupDates().returnPickupDateTime);
    const discountMinDate = dataCollection.backDiscountMinDate ? moment(dataCollection.backDiscountMinDate) : null;
    const discountMaxDate = dataCollection.backDiscountMaxDate ? moment(dataCollection.backDiscountMaxDate) : null;
    if (!discountMinDate || !discountMaxDate) return false;
    return pickupDate.isBetween(discountMinDate, discountMaxDate, null, "[]");
  };

  // --- Price Calculation Helpers ---

  // getTotalOriginalPrice: For combination items, sum up each sub-car's (base price * carCount); for single items, return item.price.
  getTotalOriginalPrice = (item) => {
    if (item.combination && item.combination.length > 0) {
      return item.combination.reduce((sum, subCar) => {
        const count = subCar.carCount || 1;
        return sum + subCar.price * count;
      }, 0);
    }
    return item.price;
  };

  // getCombinationTotals: For combination items, compute:
  // - totalFinal: Sum over each sub-car of its “final” price (see rules below)
  // - totalOriginal: Sum of each sub-car's base price * carCount.
  // - allDiscounted: true if every sub-car does not use its base price.
  getCombinationTotals = (item) => {
    let totalFinal = 0;
    let totalOriginal = 0;
    let allDiscounted = true;
    item.combination.forEach((subCar) => {
      const count = subCar.carCount || 1;
      totalOriginal += subCar.price * count;
      let subFinal = subCar.price; // default is base price
      // Rule: if there is a surcharge defined and greater than 0, then use surcharge.
      if (subCar.thereSurcharges && subCar.thereSurcharges > 0) {
        subFinal = subCar.thereSurcharges;
      }
      // Else if a discount exists and is lower than the base price, then use discount.
      else if (subCar.thereDiscount && subCar.thereDiscount < subCar.price) {
        subFinal = subCar.thereDiscount;
      } else {
        allDiscounted = false;
      }
      totalFinal += subFinal * count;
    });
    return { totalFinal, totalOriginal, allDiscounted };
  };

  // getTotalFinalPrice: For combination items, use getCombinationTotals; for single items, use similar logic.
  getTotalFinalPrice = (item) => {
    if (item.combination && item.combination.length > 0) {
      const totals = this.getCombinationTotals(item);
      return totals.totalFinal;
    } else {
      let final = item.price;
      if (item.thereSurcharges && item.thereSurcharges > 0 && (!item.thereDiscount || item.thereDiscount >= item.price)) {
        final = item.thereSurcharges;
      } else if (item.thereDiscount && item.thereDiscount < item.price) {
        final = item.thereDiscount;
      }
      return final;
    }
  };

  // getTotalSurchargePrice: For single or combination items, sum the surcharge values. (This is used to determine if any surcharge exists.)
  getTotalSurchargePrice = (item) => {
    if (item.combination && item.combination.length > 0) {
      return item.combination.reduce((sum, subCar) => {
        const count = subCar.carCount || 1;
        const surcharge = subCar.thereSurcharges && subCar.thereSurcharges > 0
          ? subCar.thereSurcharges
          : 0;
        return sum + surcharge * count;
      }, 0);
    }
    return item.thereSurcharges || 0;
  };

  // --- End Helper Methods ---

  render() {
    const { carCategories, onSelect } = this.props;
    const { validatedSelectedCar } = this.state;
    const getPriceLabelKey = (item) =>
      UserListStore.user.isPartner ? "Partnerská cena" : "Jednosměrný transfer";

    return (
      <Radio.Group value={validatedSelectedCar} style={{ width: "100%" }}>
        <List
          itemLayout="horizontal"
          bordered
          style={{
            borderColor:
              NewOrderStore.error &&
              !NewOrderStore.selectedCars[NewOrderStore.getRouteWay()].length &&
              !NewOrderStore.isLoading
                ? "#f5222d"
                : null,
          }}
        >
          {!NewOrderStore.formatPickupDateTimes().pickupDateTime &&
            !NewOrderStore.car &&
            !NewOrderStore.isLoading &&
            !NewOrderStore.backRouteStep && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={i18n.t("Pro zobrazení dostupných vozidel nejdříve vyberte místo z-do, datum a čas.")}
              />
            )}

          {carCategories.slice(0, NewOrderStore.showAllTopCarsCount).map((item) => {
            const isSelected = item.id.toString() === validatedSelectedCar;
            // For combination items, always assume discount check is true; for single items, check discount period.
            const discountCheck =
              item.combination && item.combination.length > 0
                ? true
                : this.checkDiscountFrom(item);

            const originalPrice = this.getTotalOriginalPrice(item);
            const finalDisplayedPrice = this.getTotalFinalPrice(item);

            // For single items, if finalDisplayedPrice is lower than originalPrice, we want to cross out original.
            // For combination items, we do not show the crossed-out price.
            const showCrossedOut = !item.combination && finalDisplayedPrice < originalPrice;

            // For display, show discount block if any surcharge exists or the discount check returns true or if the back-discount period is valid.
            const displayDiscountBlock =
              this.getTotalSurchargePrice(item) > 0 ||
              discountCheck ||
              this.checkDiscountTo(item);

            const nameAndDescription = this.getItemNameAndDescription(item);

            return (
              <List.Item
                className="order-form__custom-car"
                style={
                  isSelected
                    ? {
                        background: "linear-gradient(145deg,rgb(255, 255, 255),rgb(239, 236, 255))",
                        borderRadius: "8px",
                        border: "2px solid #005093",
                        //transform: "scale(1.02)",
                        //transition: "all 0.3s ease-in-out",
                      }
                    : {
                        //transition: "all 0.3s ease-in-out",
                      }
                }
                onClick={() => {
                  onSelect(item);
                  NewOrderStore.filterExtras();
                }}
                key={`car_${item.id}`}
              >
                <Radio value={item.id.toString()} />
                {NewOrderStore.mode !== "hour-rental" ? (
                  <Popover
                    content={
                      nameAndDescription.description
                        ? nameAndDescription.description
                        : nameAndDescription.name
                    }
                    title={false}
                  >
                    <div className="order-form__custom-car-image">
                      <GetCarImg item={item} />
                    </div>
                  </Popover>
                ) : (
                  <div className="order-form__custom-car-image">
                    <GetCarImg item={item} />
                  </div>
                )}
                <div className="order-form__top-car-info">
                  <p className="order-form__top-car-title">{nameAndDescription.name}</p>
                  <div>
                    <UserOutlined style={{ color: "#005093" }} />
                    <span>{i18n.t("Kapacita")}: </span>
                    <strong style={{ color: "#005093" }}>{item.capacity}</strong>
                  </div>
                  <div>
                    <Popover
                      overlayClassName="order-form__popover"
                      content={getLuggageCapacityContent(item.luggageCapacity)}
                    >
                      <ShoppingOutlined style={{ color: "#005093" }} />
                      <span>{i18n.t("počet zavazadel")}: </span>
                      <strong style={{ color: "#005093" }}>{item.luggageCapacity}</strong>
                    </Popover>
                  </div>
                </div>
                <div className="order-form__top-car-info_tags">
                  {this.getIsRetunrDiscountTag(item)}
                  {this.getRefundTag(item)}
                  {this.getPayInAdvanceTag(item)}
                </div>
                <div className="order-form__top-car-price">
                  <div className="order-form__price-label">
                    {i18n.t(getPriceLabelKey(item))}
                  </div>
                  {displayDiscountBlock ? (
                    <>
                      {/* For single items, show crossed out original if applicable */}
                      {showCrossedOut && (
                        <del
                          style={{
                            display: "flex",
                            color: "#ff2400",
                            fontSize: "13px",
                            fontWeight: "normal",
                          }}
                        >
                          {CurrencyStore.selectedCurrency !== CurrencyStore.CZK &&
                            CurrencyStore.currencies &&
                            getAmountInForeignCurrency(originalPrice, CurrencyStore.selectedCurrency) && (
                              <span style={{ display: "inline-block" }}>
                                {getAmountInForeignCurrency(originalPrice, CurrencyStore.selectedCurrency)}
                              </span>
                            )}
                          {CurrencyStore.selectedCurrency === CurrencyStore.CZK && (
                            <span style={{ display: "inline-block" }}>
                              {originalPrice} {CurrencyStore.CZK}
                            </span>
                          )}
                        </del>
                      )}
                      <span style={{ display: "block" }}>
                        {CurrencyStore.selectedCurrency !== CurrencyStore.CZK &&
                          CurrencyStore.currencies &&
                          getAmountInForeignCurrency(finalDisplayedPrice, CurrencyStore.selectedCurrency)}
                        {CurrencyStore.selectedCurrency === CurrencyStore.CZK && (
                          <span>
                            {finalDisplayedPrice} {CurrencyStore.CZK}
                          </span>
                        )}
                      </span>
                    </>
                  ) : (
                    <>
                      {CurrencyStore.selectedCurrency !== CurrencyStore.CZK &&
                        CurrencyStore.currencies &&
                        getAmountInForeignCurrency(item.price, CurrencyStore.selectedCurrency) && (
                          <>
                            <span style={{ display: "block" }}>
                              {getAmountInForeignCurrency(item.price, CurrencyStore.selectedCurrency)}
                            </span>
                            <span className="order-from-price-small">
                              {item.price} {CurrencyStore.CZK}
                            </span>
                          </>
                        )}
                      {CurrencyStore.selectedCurrency === CurrencyStore.CZK && (
                        <span style={{ display: "block" }}>
                          {item.price} {CurrencyStore.CZK}
                        </span>
                      )}
                    </>
                  )}
                </div>
              </List.Item>
            );
          })}

          {carCategories.length === 0 &&
            !CarCategoryStore.isLoading &&
            NewOrderStore.formatPickupDateTimes().pickupDateTime && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={i18n.t("Žádné vozidla pro daný výběr")}
              />
            )}
        </List>
        {NewOrderStore.error &&
          !NewOrderStore.selectedCars[NewOrderStore.getRouteWay()].length &&
          !NewOrderStore.isLoading && (
            <span style={{ color: "#f5222d" }}>{i18n.t("Vyberte vozidlo")}</span>
          )}
      </Radio.Group>
    );
  }
}

export default observer(TopCars);
