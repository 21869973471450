import { observable, makeObservable } from "mobx";

export default class OrderRouteDecorator {
    id = null;

    carCategory;

    driver;

    driverStatus = 0;

    priceDriver;

    priceClient;

    paymentCurrency;

    paymentStatus = 0;

    tags = [];

    state = 10;

    constructor(data) {
        makeObservable(this, {
            carCategory: observable,
            driver: observable,
            driverStatus: observable,
            priceDriver: observable,
            priceClient: observable,
            paymentCurrency: observable,
            paymentStatus: observable,
            tags: observable,
            state: observable,
        });

        if (data) {
            this.setAll(data);
        }
    }

    setAll(data) {
        this.id = data.id || null;
        this.carCategory = data.carCategory || null;
        this.driver = data.driver || null;
        this.driverStatus = data.driverStatus || 0;
        this.priceDriver = data.priceDriver || null;
        this.priceClient = data.priceClient || null;
        this.paymentCurrency = data.paymentCurrency || null;
        this.state = data.state || 10;
        this.paymentStatus = data.paymentStatus || 0;
        this.tags = data.tagOrderRouteCars || [];
    }
}
