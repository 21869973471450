import { observable, runInAction, action, makeObservable } from "mobx"; // eslint-disable-line max-lines
import moment from "moment-timezone";
import Router from "next/router";
import { message } from "antd";
import RootStore from "../RootStore";
import NotificationStore from "../NotificationStore";
import ExtraStore from "../Extra/ExtraStore";
import i18n from "../../core/i18n";
import OrdersListStore from "./OrdersListStore";
import CarCategoryStore from "../CarCategory/CarCategoryStore";
import CarCategoryDecorator from "../CarCategory/CarCategoryDecorator";
import PaymentStore from "../Payment/PaymentStore";
import ZoneStore from "../Zone/ZoneStore";
import config from "../../config/config";
import { pushRoute } from "../../components/Router.tsx";
import LocationStore from "../Location/LocationStore";
import CurrencyStore from "../Currency/CurrencyStore";
import UserStore from "../UserStore";
import LanguageStore from "../Language/LanguageStore";
import gptManager from "../../utils/gptManager";
import SklikManager from "../../utils/sklikManager";
import MsManager from "../../utils/msManager";
import dayjs from "dayjs";

class NewOrderStore {
    isLoading = false;

    isExtraDiscount = false;

    addressesFromUrl = [];

    rentHoursFromUrl = null;

    excursionPointsFromUrl = [];

    passengersCountHasBeenSet = false;

    waitingTimesFromUrl = [];

    carCategoryIdFromUrl = null;

    passengersCountFromUrl = null;

    pickupDateFromUrl = null;

    isSaving = false;

    isPhoneaaded = false;

    isReturnPath = false;

    usePickupDateLive = false;

    step = 1;

    contactPhone = null;

    contactTagName = null;

    defaultValues = {
        withLuggage: false,
        withoutLuggage: true,
        excessiveLuggage: false,
    };

    tagNameParts = {
        firstName: "",
        lastName: "",
        greeting: "Pan",
        email: "",
    };

    formValues = {
        routes: [],
        customerData: {},
    };

    pickupDate = this.getDefaultPickupDate();
    pickupTime = this.getDefaultPickupDate();

    returnPickupDate = null;

    returnPickupTime = null;

    getDefaultPickupDate() {
        // next day
        const nextNextDay = dayjs().add(1, "day");

        // Set the time to 12:00 noon
        const roundedTime = nextNextDay.set("hour", 12).set("minute", 0).set("second", 0).set("millisecond", 0);

        return roundedTime;
    }

    backRouteStepBackButton = false;

    editId = null;

    editRouteId = null;

    addReturnRoute = false;

    billingInformationChecked = false;

    conditionsApproved = true;

    /**
     * Bude i zpáteční cesta? (Button: Zpáteční transfer?)
     * @type {boolean}
     */
    backRoute = false;

    /**
     * Bude se zpáteční cesta upravovat ručně? (Button: Upravit vozidlo zpáteční cesty v dalším kroku)
     * @type {boolean}
     */
    backRouteStep = false;

    /**
     * Je zapnutý režim zpáteční cesty?
     * @type {boolean}
     */
    returnRouteMode = false;

    returnRouteModeInit = false;

    startExtras = [];

    selectedExtras = {
        thereRoute: [],
        backRoute: [],
    };

    filteredExtras = [];

    startPrice = 0;

    paymentStatus = null;

    originalPaymentType = null;

    startPriceCars = 0;

    endPriceCars = 0;

    price = 0;

    priceCars = 0;

    passengersCount = 2;

    car = null;

    /**
     * Slouží pro uchování dat o vybraných vozidel
     */
    selectedCars = {
        thereRoute: [],
        backRoute: [],
    };

    start = null;

    startValue = null;

    existingRoutes = [];

    finish = null;

    finishValue = null;

    startName = null;

    finishName = null;

    routeDistance = null;

    routeDuration = null;

    pricesByCarCategories = {};

    selectedExtraCategory = 0;

    extraAmounts = {};

    showAllTopCarsCount = 2;

    showCustomCarsCount = 3;

    showAllPayments = false;

    paymentCurrency = "CZK";

    error = null;

    setError(error) {
        this.error = error;
    }

    clearError() {
        this.error = null;
    }

    scrollToError = false;

    isShuttleAvailable = false;

    flightNumber = null;

    busNumber = null;

    trainNumber = null;

    chooseCustomCar = {
        backRoute: false,
        thereRoute: false,
    };

    chooseChild = {
        backRoute: false,
        thereRoute: false,
    };

    showNotes = false;

    paymentType = PaymentStore.TYPE_PAY_LATER;

    kids1 = {
        thereRoute: null,
        backRoute: null,
    };

    kids2 = {
        thereRoute: null,
        backRoute: null,
    };

    kids3 = {
        thereRoute: null,
        backRoute: null,
    };

    haveToPayInAdvance = {
        thereRoute: false,
        backRoute: false,
    };

    isForPricing = true;

    mode = "";

    editOrder = false;

    rentHours = 1;

    excursionPoints = [
        { place: "", waitingTime: 0, description: "", locations: "" },
        { place: "", waitingTime: 0, description: "", locations: "" },
    ];

    routeTotalDuration = null;

    resetForm = false;

    prefixLength = null;

    translatedLocations = {};

    isModalOpened = false;

    isDuplicateModalOpened = false;

    checkDuplicateError = true;

    duplicateRouteIds = [];

    isstartRed = false;

    isFinishRed = false;

    isNotice1 = false;

    isNotice2 = false;

    isCancelingModal = false;

    isf1Address = false;

    checkValidation = false;

    a_aid = false;

    preventRouteByUrlParameter = false;

    myRef = {};

    onCreate = null;

    modeToTracker = {
        order: "transfer",
        "hour-rental": "pronajem",
        excursion: "exkurze",
    };

    loadCategoriesWithTimeout = null;

    tagName = null;

    flightTimeToArrive = [];

    roundTimeToNearest15Minutes(time) {
        const [hours, minutes] = time.split(":").map(Number);
        const roundedMinutes = Math.ceil(minutes / 15) * 15;
        const roundedHours = roundedMinutes === 60 ? hours + 1 : hours;
        const finalMinutes = roundedMinutes === 60 ? 0 : roundedMinutes;
        return `${roundedHours.toString().padStart(2, "0")}:${finalMinutes.toString().padStart(2, "0")}`;
    }

    setTagName(tagName) {
        this.tagName = tagName;
    }

    setCreateCallback(cb) {
        this.onCreate = cb;
    }

    clearCreateCallback() {
        this.onCreate = null;
    }

    toggleNotesCard() {
        this.showNotes = !this.showNotes;
    }

    updateShowAllTopCarsCount() {
        if (typeof window !== "undefined") {
            const screenWidth = window.innerWidth;
            if (screenWidth <= 768) {
                // Breakpoint for tablets and mobile devices
                this.showAllTopCarsCount = 3;
            } else {
                this.showAllTopCarsCount = 4; // Default for desktop devices
            }
        }
    }

    calculateFlightTimeRecommended(routeNumber, dm) {
        if (dm == null || dm.length < 5) {
            return false;
        }

        if (routeNumber === 0 && this.flightTimeToArrive.length > 0) {
            if (this.flightTimeToArrive[routeNumber] == null) {
                return false;
            }
            const flightInfo = this.flightTimeToArrive[routeNumber];
            const timeToArrive = flightInfo.length > 0 ? flightInfo[0].timeToArrive : null;
            const timeNewFlight = flightInfo.length > 0 ? flightInfo[0].timeNew : null;
            if (flightInfo.length > 0 && flightInfo[0].flightNumber !== dm) {
                return false;
            }
            if (timeToArrive && timeToArrive !== "") {
                const times = timeToArrive.split(" - ");
                const displayTime = times.length > 1 ? times[1] : times[0];
                const minutes = parseInt(CarCategoryStore.routeDuration);
                const originalTime = dayjs(displayTime, "HH:mm");
                const newTime = originalTime.subtract(minutes, "minute");
                const roundedNewTime = this.roundTimeToNearest15Minutes(newTime.format("HH:mm"));
                const selectedPickup = dayjs(this.pickupTime, "HH:mm");
                const roundedSelectedPickup = this.roundTimeToNearest15Minutes(selectedPickup.format("HH:mm"));
                if (dayjs(roundedSelectedPickup, "HH:mm").isAfter(dayjs(roundedNewTime, "HH:mm"))) {
                    return roundedNewTime;
                }
            } else if (timeNewFlight) {
                const selectedPickup = dayjs(this.pickupTime, "HH:mm");
                const roundedSelectedPickup = this.roundTimeToNearest15Minutes(selectedPickup.format("HH:mm"));
                const timeNewFlightPickup = moment(timeNewFlight);
                const roundedTimeNewFlightPickup = this.roundTimeToNearest15Minutes(timeNewFlightPickup.format("HH:mm"));
                if (roundedSelectedPickup !== roundedTimeNewFlightPickup) {
                    return "255";
                }
            }
        }

        if (routeNumber === 1 && this.flightTimeToArrive.length > 1) {
            if (this.flightTimeToArrive[routeNumber] == null) {
                return false;
            }
            const flightInfo2 = this.flightTimeToArrive[routeNumber];
            const timeToArrive2 = flightInfo2.length > 0 ? flightInfo2[0].timeToArrive : null;
            const timeNewFlight2 = flightInfo2.length > 0 ? flightInfo2[0].timeNew : null;
            if (flightInfo2.length > 0 && flightInfo2[0].flightNumber !== dm) {
                return false;
            }
            if (timeToArrive2 && timeToArrive2 !== "") {
                const times2 = timeToArrive2.split(" - ");
                const displayTime2 = times2.length > 1 ? times2[1] : times2[0];
                const minutes2 = parseInt(CarCategoryStore.routeDuration);
                const originalTime2 = dayjs(displayTime2, "HH:mm");
                const newTime2 = originalTime2.subtract(minutes2, "minute");
                const roundedNewTime2 = this.roundTimeToNearest15Minutes(newTime2.format("HH:mm"));
                const selectedPickup2 = dayjs(this.returnPickupTime, "HH:mm");
                const roundedSelectedPickup2 = this.roundTimeToNearest15Minutes(selectedPickup2.format("HH:mm"));
                if (dayjs(roundedSelectedPickup2, "HH:mm").isAfter(dayjs(roundedNewTime2, "HH:mm"))) {
                    return roundedNewTime2;
                }
            } else if (timeNewFlight2) {
                const selectedPickup3 = dayjs(this.returnPickupTime, "HH:mm");
                const roundedSelectedPickup3 = this.roundTimeToNearest15Minutes(selectedPickup3.format("HH:mm"));
                const timeNewFlightPickup2 = moment(timeNewFlight2);
                const roundedTimeNewFlightPickup2 = this.roundTimeToNearest15Minutes(timeNewFlightPickup2.format("HH:mm"));
                if (roundedSelectedPickup3 !== roundedTimeNewFlightPickup2) {
                    return this.roundTimeToNearest15Minutes(timeNewFlightPickup2.format("HH:mm"));
                }
            }
        }
        return false;
    }

    formatPickupDate(date, time) {
        // Ensure date and time are converted using dayjs
        const formattedDate = dayjs(date).format("YYYY-MM-DD");
        const formattedTime = dayjs(time).format("HH:mm");

        // Merge date and time using dayjs and the specified format
        const mergedDateTime = dayjs(`${formattedDate} ${formattedTime}`, config.apiDateTimeFormat);

        // Log the formatted date, time, and merged date-time
        //console.log("Formatted Date:", formattedDate);
        //console.log("Formatted Time:", formattedTime);
        //console.log("Merged DateTime:", mergedDateTime.format('YYYY-MM-DD HH:mm'));

        // Return the final formatted -time string
        return mergedDateTime.format("YYYY-MM-DD HH:mm");
    }

    getFormattedPickupDates() {
        const pickupDateTime = this.pickupDate && this.pickupTime ? this.formatPickupDate(this.pickupDate, this.pickupTime) : "";
        const returnPickupDateTime =
            this.returnPickupDate && this.returnPickupTime ? this.formatPickupDate(this.returnPickupDate, this.returnPickupTime) : "";

        // Log the formatted pickup and return dates
        //console.log("Formatted Pickup DateTime:", pickupDateTime);
        //console.log("Formatted Return Pickup DateTime:", returnPickupDateTime);

        return { pickupDateTime, returnPickupDateTime };
    }

    addDiscountTimes() {
        const discountPickupDateTime = this.pickupDate && this.pickupTime ? this.formatPickupDate(this.pickupDate, this.pickupTime) : "";
        const returnDiscountPickupDateTime =
            this.returnPickupDate && this.returnPickupTime ? this.formatPickupDate(this.returnPickupDate, this.returnPickupTime) : "";

        return { discountPickupDateTime, returnDiscountPickupDateTime };
    }

    formatPickupDateTimes() {
        let pickupDateTime = this.pickupDate && this.pickupTime ? this.formatPickupDate(this.pickupDate, this.pickupTime) : "";
        let returnPickupDateTime =
            this.returnPickupDate && this.returnPickupTime ? this.formatPickupDate(this.returnPickupDate, this.returnPickupTime) : "";

        // returnRouteMode - Je zapnutý režim zpáteční cesty?
        // backRoute - bude zpáteční cesta?
        // backRouteStep - bude se upravovat zpátečka

        const isOrder = this.mode === "order";
        const manualBackRoute = !this.returnRouteMode && this.backRouteStep;
        const noBackRoute = !this.backRoute;
        const inBackRoute = isOrder && this.returnRouteMode;

        // není to transfer nebo bude upravovat ručně zpátečku nebo není zpátečka
        if (noBackRoute || !isOrder || manualBackRoute) {
            returnPickupDateTime = "";
        }

        // upravuje zpátečku
        if (inBackRoute) {
            pickupDateTime = "";
        }

        return { pickupDateTime, returnPickupDateTime };
    }

    getOriginTranslation(translatedLocation, lang = "cs", compareLang = null) {
        // Check if locations are loaded
        if (LocationStore.isLoading) {
            // Optionally, handle loading state (e.g., return a placeholder or throw an error)
            console.warn("Locations are still loading.");
            return translatedLocation;
        }

        if (LocationStore.error) {
            // Handle error state
            console.error("Error loading locations:", LocationStore.error);
            return translatedLocation;
        }

        const newLocations = JSON.parse(JSON.stringify(LocationStore.locations));
        const options = compareLang ? { lng: compareLang } : {};

        if (translatedLocation) {
            const location = newLocations.slice(0, 4).find((location) => translatedLocation.includes(i18n.t(location.name, options)));

            const address = translatedLocation.split(" - ");

            let route = location ? i18n.t(location.name, { lng: lang }) : translatedLocation;
            if (location && location.name && address[1]) {
                route = `${route} - ${address[1]}`;
            }

            return route;
        }
        return "";
    }

    create(data) {
        const dataTemp = { ...data };

        dataTemp.routes = [];
        data.routes[0].haveToPayInAdvance = this.haveToPayInAdvance.thereRoute;
        data.routes[0].isForPricing = this.isForPricing;
        if (this.isReturnPath) {
            data.routes[0].id = null;
        }

        if (data.routes[1]) {
            data.routes[1].haveToPayInAdvance = this.haveToPayInAdvance.backRoute;
            data.routes[1].isForPricing = this.isForPricing;
        }

        let transportPrice = 0;

        //console.log('Initial Data:', JSON.stringify(data, null, 2));  // Log initial data received

        data.routes.forEach((route) => {
            const paymentType = PaymentStore.types.find((type) => type.id === route.paymentType);
            route.paymentCurrency = route.paymentCurrency ? route.paymentCurrency : paymentType.paymentCurrency;
            route.paymentType = paymentType ? route.paymentType : PaymentStore.TYPE_CASH;
            route.pickupDate = route.pickupDate.locale("en").format(config.apiDateTimeFormat);
            route.language = LanguageStore.getLocale(Router);
            route.start = this.getOriginTranslation(route.start);
            route.finish = this.getOriginTranslation(route.finish);
            if (route.contactPersonTagName) {
                route.contactPersonTagName = route.contactPersonTagName.trim();
            }
            if (moment().diff(moment(route.pickupDate.split("+")[0]), "d") === 0) {
                route.sendSms = true;
            }
            if (route.excursionPoints) {
                route.excursionPoints.forEach((point) => {
                    point.beforeChangePlace = point.place;
                });
            }
            route.sendEmailUpdate = !!this.editId;
            route.sendEmail = true;

            //console.log('Route before unGroupCarCategoriesByIdForRoute:', JSON.stringify(route, null, 2));

            dataTemp.routes.push(this.unGroupCarCategoriesByIdForRoute(route));

            //console.log('Route after unGroupCarCategoriesByIdForRoute:', JSON.stringify(dataTemp.routes, null, 2));

            if (!this.isForPricing) {
                route.orderRouteCars.forEach((car) => {
                    transportPrice += car.priceClient * car.carCount;
                });
            }
        });

        //console.log('Final Data to be sent:', JSON.stringify(dataTemp, null, 2));

        const trimmedFirstName = data.customerData.firstName.trim();
        const trimmedLastName = data.customerData.lastName.trim();

        const locale = LanguageStore.getLocale(Router);

        dataTemp.customerData = {
            ...data.customerData,
            firstName: trimmedFirstName,
            lastName: trimmedLastName,
            language: locale,
        };

        if (this.editId) {
            return this.simpleUpdate(dataTemp);
        }

        this.isSaving = true;
        NotificationStore.reset();

        dataTemp.locale = locale;
        dataTemp.a_aid = this.a_aid;

        RootStore.api
            .post("/orders/", dataTemp)
            .then((response) => {
                if (this.onCreate) {
                    this.onCreate(response);
                    this.clearCreateCallback();
                }

                // Get customer email and assign it to a global variable for Google Ads tracking
                if (dataTemp.customerData && dataTemp.customerData.email) {
                    window.customerEmail = dataTemp.customerData.email;
                }
                if (response.redirectPayUrl && response.redirectPayUrl !== "error") {
                    const url = `${window.location.origin}/${locale}/orders/?redirectPayUrl=${response.redirectPayUrl}`;
                    window.location.replace(url);
                } else {
                    OrdersListStore.setCallback(() => {
                        if (this.editOrder) {
                            message.success(i18n.t("Objednávka byla úspěšně upravena", 3));
                        } else {
                            if (data.routes[0].paymentType === PaymentStore.TYPE_DIRECT_DEBIT) {
                                message.success(i18n.t("Objednávka byla úspěšně vytvořena", 3));
                                NotificationStore.processSuccess(
                                    i18n.t("Na email byl zaslán zálohový daňový doklad, prosíme o provedení platby"),
                                    "profile"
                                );
                            } else if (
                                data.routes[0].isForPricing ||
                                data.routes.find((route) =>
                                    route.orderRouteCars.find((orderRouteCar) => !orderRouteCar.carCategory.isDistributable)
                                )
                            ) {
                                message.success(i18n.t("Vaše rezervace byla dokončena a bude potvrzena co nejdříve.", 3));
                            } else if (response.redirectPayUrl && response.redirectPayUrl === "error") {
                                message.success(i18n.t("Objednávka byla úspěšně vytvořena", 3));
                                message.error(
                                    i18n.t(
                                        "Vyskytla se chyba na platební bráně. Opakujte platbu později nebo zvolte jinou platební možnost"
                                    )
                                );
                            } else {
                                message.success(i18n.t("Objednávka byla úspěšně vytvořena", 3));
                            }
                        }
                    });
                    pushRoute(Router, "orders", { lang: Router.query.lang, path: "orders" }, { shallow: true });

                    window.scrollTo({
                        top: 0,
                        behavior: "auto",
                    });

                    this.isSaving = false;
                    this.reset();
                }

                if (!this.editId) {
                    const mode = this.modeToTracker[this.mode] || "new-order";
                    gptManager.orderSubmit(NewOrderStore.price, CurrencyStore.selectedCurrency, response.id, transportPrice, mode);
                    SklikManager.orderSubmit();
                    MsManager.orderSubmit();
                }
            })
            .catch((e) => {
                this.isSaving = false;
                NotificationStore.processException(e, "new-order");
            });

        return null;
    }

    /**
     * Zjištuje o který typ transféru se jedná a podle toho získává ceny s auty přes API
     */
    loadCarCategories(selectedCarsReset = false) {
        const invalidKeywords = ["choosemap", "startcurrentLocation", "finishcurrentLocation", "%20"];

        let loadPromise;

        if (!this.passengersCountHasBeenSet) {
            const urlString = window.location.href;
            const url = new URL(urlString);
            const passengersNew = url.searchParams.get("passengers");

            if (passengersNew !== null) {
                this.passengersCount = parseInt(passengersNew, 10);
                this.passengersCountHasBeenSet = true;
            }
        }

        if (this.mode === "order" || this.mode === "hour-rental") {
            const isStartInvalid = !this.start || invalidKeywords.some((keyword) => this.start.includes(keyword));
            const isFinishInvalid = !this.finish || invalidKeywords.some((keyword) => this.finish.includes(keyword));

            if (isStartInvalid || isFinishInvalid) {
                return Promise.resolve();
            }

            if (this.start && this.finish && this.mode === "order") {
                loadPromise = CarCategoryStore.loadTransfer(this.start, this.finish, this.passengersCount, selectedCarsReset);
            } else if (this.start && this.finish && this.mode === "hour-rental") {
                loadPromise = CarCategoryStore.loadHourRental(
                    {
                        start: this.start,
                        finish: this.finish,
                        passengersCount: this.passengersCount,
                        rentHours: this.rentHours,
                    },
                    selectedCarsReset
                );
            }
        } else if (this.mode === "excursion" && this.excursionPoints.length) {
            loadPromise = CarCategoryStore.loadExcursion(this.excursionPoints, this.passengersCount, selectedCarsReset);
        }

        if (selectedCarsReset && !this.carCategoryIdFromUrl) {
            this.selectedCars[this.getRouteWay()] = [];
            this.car = null;
            this.resetSelectedCarsCustom();
        }

        this.calculatePrice();
        return loadPromise;
    }

    clearDuplicateRouteIds() {
        this.duplicateRouteIds = [];
    }

    setIsDuplicateModalOpened(isDuplicateModalOpened) {
        this.isDuplicateModalOpened = isDuplicateModalOpened;
    }

    setCheckDuplicateError(checkDuplicateError) {
        this.checkDuplicateError = checkDuplicateError;
    }

    addDuplicateRouteIds(duplicateRouteIds) {
        duplicateRouteIds.forEach((id) => {
            this.duplicateRouteIds.push(id);
        });
    }

    async isTransferFirstPageDuplicate(routeValues) {
        if (!UserStore.user) {
            return false;
        }

        const query = {
            customerId: UserStore.user.userId,
            contactPersonTagName: this.tagName,
            passengersCount: this.passengersCount,
        };

        let isDuplicate = false;

        if (!this.backRoute) {
            const routeThereDuplicate = await this.checkRouteThere(query, routeValues);
            if (routeThereDuplicate) {
                isDuplicate = true;
            }
        }

        if (this.backRoute) {
            const routeThereDuplicate = await this.checkRouteThere(query, routeValues);
            const routeBackDuplicate = await this.checkRouteBack(query, routeValues);
            if (routeThereDuplicate || routeBackDuplicate) {
                isDuplicate = true;
            }
        }

        return isDuplicate;
    }

    checkRouteThere(query, routeValues) {
        const modifiedQuery = { ...query };
        modifiedQuery.start = this.getOriginTranslation(this.start);
        modifiedQuery.finish = this.getOriginTranslation(this.finish);
        modifiedQuery.pickupDate = routeValues.routes[0].pickupDate;

        return this.checkDuplicates(modifiedQuery);
    }

    checkRouteBack(query, routeValues) {
        const modifiedQuery = { ...query };
        modifiedQuery.start = this.getOriginTranslation(this.finish);
        modifiedQuery.finish = this.getOriginTranslation(this.start);
        modifiedQuery.pickupDate = routeValues.routes[1].pickupDate;

        return this.checkDuplicates(modifiedQuery);
    }

    async checkDuplicates(query) {
        const response = await RootStore.api.get("/orders/duplicates/", query);
        if (response.length > 0) {
            this.addDuplicateRouteIds(response);
            return true;
        }
        return false;
    }

    isTransferSecondPageDuplicate(routeValues) {
        if (!UserStore.user) {
            return false;
        }

        const query = {
            customerId: UserStore.user.userId,
            contactPersonTagName: this.tagName,
            passengersCount: this.passengersCount,
        };

        query.start = this.getOriginTranslation(this.start);
        query.finish = this.getOriginTranslation(this.finish);
        query.pickupDate = routeValues.routes[1].pickupDate;

        return this.checkDuplicates(query);
    }

    simpleUpdate(data) {
        this.isSaving = true;
        NotificationStore.reset();

        RootStore.api
            .put(`/orders/${this.editId}/`, data)
            .then(() => {
                pushRoute(Router, "orders", { lang: Router.query.lang, path: "orders" }, { shallow: true });
                OrdersListStore.setCallback(() => {
                    NotificationStore.processSuccess(i18n.t("Objednávka byla úspěšně upravena"), "profile");
                });

                this.isSaving = false;
            })
            .catch((e) => {
                this.isSaving = false;
                NotificationStore.processException(e, "new-order");
            });
    }

    calculatePrice() {
        let price = 0;

        if (this.selectedCars[this.getRouteWay()]) {
            this.selectedCars[this.getRouteWay()].forEach((carCategory) => {
                //if (carCategory?.combination) {
                //    carCategory.combination.forEach((combination) => {
                //        price += combination.price * combination.carCount;
                //    });
                //} else {
                    price += carCategory?.price * carCategory?.carCount || 0;
                //}
            });
        }

        if (this.start || this.excursionPoints) {
            this.priceCars = price;
        }

        if (this.start || this.excursionPoints) {
            this.price = price;
        }
    }

    calculateExtrasPrice() {
        let price = 0;
        this.selectedExtras.thereRoute.forEach((selected) => {
            price += selected.price * selected.amount;
        });

        this.selectedExtras.backRoute.forEach((selected) => {
            price += selected.price * selected.amount;
        });

        return price;
    }

    calculateExtrasPriceThereRoute() {
        let price = 0;
        this.selectedExtras.thereRoute.forEach((selected) => {
            price += selected.price * selected.amount;
        });

        return price;
    }

    calculateExtrasPriceThereRouteForeignCurrency() {
        let price = 0;
        if (CurrencyStore.selectedCurrency !== CurrencyStore.CZK && CurrencyStore.currencies) {
            this.selectedExtras.thereRoute.forEach((selected) => {
                price += (selected.price / CurrencyStore.currencies[CurrencyStore.selectedCurrency].rate).toFixed(2) * selected.amount;
            });
        }

        return price;
    }

    calculateExtrasPriceBackRoute() {
        let price = 0;
        this.selectedExtras.backRoute.forEach((selected) => {
            price += selected.price * selected.amount;
        });

        return price;
    }

    calculateExtrasPriceBackRouteForeignCurrency() {
        let price = 0;
        if (CurrencyStore.selectedCurrency !== CurrencyStore.CZK && CurrencyStore.currencies) {
            this.selectedExtras.backRoute.forEach((selected) => {
                price += (selected.price / CurrencyStore.currencies[CurrencyStore.selectedCurrency].rate).toFixed(2) * selected.amount;
            });
        }

        return price;
    }

    loadCarCategoriesAndSetPaymentType() {
        if ((this.start && this.start !== this.finish) || (this.mode === "excursion" && this.excursionPoints.length)) {
            const loadPromise = this.loadCarCategories(false);
            if (loadPromise) {
                loadPromise.then(() => {
                    if (this.editOrder) {
                        // Preserve the original paymentType during edit
                        this.paymentType = this.originalPaymentType;
                    } else {
                        if (this.hasSelectedCarsZeroPrice()) {
                            this.paymentType = PaymentStore.TYPE_PAY_LATER;
                        } else {
                            this.paymentType = PaymentStore.getDefaultPaymentType();
                        }
                    }
                });
            }
        }
    }

    onSelectRoutePoint(value, index, elementName) {
        let isRouteChanged = false;

        if (elementName === "start" && this.start !== value) {
            this.start = value;
            isRouteChanged = true;
        } else if (elementName === "finish" && this.finish !== value) {
            this.finish = value;
            isRouteChanged = true;
        }

        if (isRouteChanged) {
            this.loadCarCategoriesAndSetPaymentType();
        }
    }

    getPickupDateFromUrl() {
        const nextDayTwelveHours = moment().add(1, "day").startOf("day").add(12, "hour");

        return this.pickupDateFromUrl[0] || nextDayTwelveHours;
    }

    setPickupDateFromUrl() {
        const pickupDateFromUrlMoment = moment(this.getPickupDateFromUrl());

        this.formValues.routes[0].pickupDate = moment(pickupDateFromUrlMoment, config.apiDateTimeFormat);
        this.setPickupDate(pickupDateFromUrlMoment);
        this.setPickupTime(pickupDateFromUrlMoment);
    }

    setPickupDate(date) {
        this.pickupDate = dayjs(date);
    }

    setPickupTime(time) {
        this.pickupTime = dayjs(time);
    }

    setReturnPickupDate(date) {
        this.returnPickupDate = dayjs(date);
    }

    setReturnPickupTime(time) {
        this.returnPickupTime = dayjs(time);
    }

    setCarCategoriesFromUrl() {
        this.loadCarCategories(false).then(() => {
            const carIndex = CarCategoryStore.carCategories.findIndex(
                (carCategory) => carCategory.id.toString() === this.carCategoryIdFromUrl
            );
            if (carIndex !== -1) {
                const tempCar = CarCategoryStore.carCategories[carIndex];
                CarCategoryStore.carCategories.splice(carIndex, 1);
                CarCategoryStore.carCategories.unshift(tempCar);
                this.selectCar(tempCar);
            }
        });
    }

    loadCarCategoriesWithTimeout() {
        if (this.loadCategoriesWithTimeout) {
            clearTimeout(this.loadCategoriesWithTimeout);
        }
        this.loadCategoriesWithTimeout = setTimeout(() => {
            this.loadCarCategories(false);
            //this.resetSelectedCars();
        }, 1000);
    }

    setDefaultExcursionPoints() {
        this.excursionPoints = [
            {
                place: LocationStore.AIRPORT_ADDRESS,
                name: "",
                waitingTime: 0,
                description: "",
            },
            {
                place: LocationStore.PRAGUE_CENTER_ADDRES,
                name: "",
                waitingTime: 2,
                description: "",
            },
        ];
    }

    setStartAndFinish(start = null, finish = null) {
        this.start = start || LocationStore.AIRPORT_ADDRESS;
        this.startValue = start || LocationStore.AIRPORT_ADDRESS;
        this.finish = finish || LocationStore.PRAGUE_CENTER_ADDRES;
        this.finishValue = finish || LocationStore.PRAGUE_CENTER_ADDRES;
    }

    setAddressesFromUrl() {
        this.setStartAndFinish(this.addressesFromUrl[0], this.addressesFromUrl[1]);
    }

    getWaitingTimesFromUrl(index) {
        if (index === 0) {
            return 0;
        }

        return this.waitingTimesFromUrl[index];
    }

    setExcursionPointsFromUrl() {
        this.excursionPoints = [];

        const waitingTimesLength = this.waitingTimesFromUrl.length;
        const addressesLength = this.addressesFromUrl.length;

        this.addressesFromUrl.forEach((point, index) => {
            this.excursionPoints.push({
                place: point,
                name: "",
                waitingTime: waitingTimesLength < addressesLength - 1 ? 0 : this.getWaitingTimesFromUrl(index),
                description: "",
            });
        });
    }

    setDefaultAddressesAndExcursionPoints() {
        if (this.mode === "excursion") {
            this.setDefaultExcursionPoints();
        } else {
            this.setDefaultAddresses();
        }
    }

    setDefaultAddresses() {
        this.start = LocationStore.AIRPORT_ADDRESS;
        this.startValue = LocationStore.AIRPORT_ADDRESS;
        this.finish = LocationStore.PRAGUE_CENTER_ADDRES;
        this.finishValue = LocationStore.PRAGUE_CENTER_ADDRES;
    }

    setAddressesAndPoints() {
        if (this.addressesFromUrl.length >= 2) {
            this.setExcursionPointsFromUrl();
            this.setAddressesFromUrl();
        } else {
            this.setDefaultAddressesAndExcursionPoints();
        }
    }

    setEmptyAddresses() {
        this.startValue = this.startValue ? this.startValue : "";
        this.finishValue = this.finishValue ? this.finishValue : "";
    }

    setRouteByUrlParameter() {
        if (this.preventRouteByUrlParameter) {
            return;
        }

        const urlString = window.location.href;
        const url = new URL(urlString);

        this.addressesFromUrl = url.searchParams.getAll("addresses[]");
        this.carCategoryIdFromUrl = url.searchParams.get("car");
        this.passengersCountFromUrl = url.searchParams.get("passengers");
        this.rentHoursFromUrl = url.searchParams.get("rentHours");
        this.waitingTimesFromUrl = url.searchParams.getAll("wts[]");
        this.pickupDateFromUrl = url.searchParams.getAll("pickupDate");

        const extrasCategoryId = url.searchParams.get("extras");
        if (extrasCategoryId) {
            this.selectedExtraCategory = parseInt(extrasCategoryId, 10);
            this.setFilteredExtras(this.selectedExtraCategory);
            this.scrollToExtraCategory();
        }

        if (this.passengersCountFromUrl) {
            this.passengersCount = this.passengersCountFromUrl;
        }

        if (this.rentHoursFromUrl && this.mode === "hour-rental") {
            this.rentHours = this.rentHoursFromUrl;
        }

        // set showAllTopCarsCount to 2 when carCategoryIdFromUrl is set
        if (this.carCategoryIdFromUrl) {
            this.showAllTopCarsCount = 2; // Set to 2 when a specific car is selected
        } else {
            // Ensure the count is updated based on device
            this.updateShowAllTopCarsCount();
        }

        if (this.addressesFromUrl.length !== 0 || this.carCategoryIdFromUrl) {
            this.setPickupDateFromUrl();
            this.setAddressesAndPoints();
            this.setCarCategoriesFromUrl();
        } else {
            this.setEmptyAddresses();
        }

        this.passengersCountHasBeenSet = true;
        this.preventRouteByUrlParameter = true;
    }

    scrollToExtraCategory() {
        const extrasSection = document.getElementById("extras-section");

        if (extrasSection) {
            extrasSection.scrollIntoView({ behavior: "smooth" });

            setTimeout(() => {
                window.scrollBy(0, 0);
            }, 500);
        }
    }

    reset() {
        runInAction(() => {
            CarCategoryStore.carCategories = [];
            this.isForPricing = true;
            this.editId = null;
            this.editOrder = false;
            this.carCategoryIdFromUrl = null;
            this.contactTagName = null;
            this.contactPhone = null;
            this.flightNumber = null;
            this.error = null;
            this.resetForm = true;
            this.step = 1;
            this.paymentStatus = null;
            this.formValues = {
                routes: [],
                customerData: {},
            };
            this.returnPickupTime = null;
            this.returnPickupDate = null;
            this.start = "";
            this.startValue = "";
            this.finish = "";
            this.finishValue = "";
            this.startName = null;
            this.finishName = null;
            UserStore.isEmailExists = false;
            UserStore.isHashVerified = false;
            UserStore.sentRegisterHash = false;
            this.excursionPoints = [
                {
                    place: "",
                    name: "",
                    waitingTime: 0,
                    description: "",
                },
                {
                    place: "",
                    name: "",
                    waitingTime: 0,
                    description: "",
                },
            ];
            this.rentHours = 1;
            this.mode = "order";
            this.editId = null;
            this.editRouteId = null;
            this.addReturnRoute = false;
            this.backRoute = false;
            this.backRouteStep = false;
            this.returnRouteMode = false;
            this.returnRouteModeInit = false;

            this.chooseCustomCar = {
                backRoute: false,
                thereRoute: false,
            };

            this.showNotes = false;

            this.chooseChild = {
                backRoute: false,
                thereRoute: false,
            };

            this.selectedExtras = {
                thereRoute: [],
                backRoute: [],
            };

            this.filteredExtras = [];

            this.haveToPayInAdvance = {
                thereRoute: false,
                backRoute: false,
            };

            const paymentMethod = PaymentStore.getSelectedCurrencies();
            let paymentMethodId = null;
            if (paymentMethod[0]) {
                paymentMethodId = paymentMethod[0].id;
            }

            this.paymentType = PaymentStore.TYPE_PAY_LATER;

            this.kids1 = {
                thereRoute: null,
                backRoute: null,
            };

            this.kids2 = {
                thereRoute: null,
                backRoute: null,
            };

            this.kids3 = {
                thereRoute: null,
                backRoute: null,
            };

            this.selectedCars = {
                backRoute: [],
                thereRoute: [],
            };

            this.passengersCount = 2;
            this.defaultValues = {
                withLuggage: false,
                withoutLuggage: true,
                excessiveLuggage: false,
            };
            this.tagNameParts = {
                firstName: "",
                lastName: "",
                greeting: i18n.t("Pan"),
                email: "",
            };

            // Resetting price-related variables to ensure no leftover values affect new calculations
            this.price = 0;
            this.priceCars = 0;
            this.startPriceCars = 0;
            this.endPriceCars = 0;
            this.extraDiscountedPrice = 0;
            this.discountedFromPrice = 0;
            this.discountedToPrice = 0;
            this.surchargesFromPrice = 0;
            this.surchargesToPrice = 0;

            this.car = null;
            this.start = null;
            this.finish = null;
            this.usePickupDateLive = false;

            CarCategoryStore.routeDistance = null;
            CarCategoryStore.routeDuration = null;
            CarCategoryStore.routeTotalDuration = null;
            CarCategoryStore.zoneInfo = null;

            this.pricesByCarCategories = {};
            this.selectedExtraCategory = 0;
            ExtraStore.extras.forEach((extra) => {
                extra.amount = 0; // Reset the amount of each extra
            });
            this.extraAmounts = {};
            this.updateShowAllTopCarsCount();

            this.showCustomCarsCount = 3;
            this.showAllPayments = false;
            this.existingRoutes = [];
            this.isShuttleAvailable = false;

            this.startExtras = [];
            this.startPrice = 0;
            this.startPriceCars = 0;

            this.isstartRed = false;
            this.isFinishRed = false;
            this.chargeLastExcursionPoint = true;

            // Resetting orderRouteCars to empty or default
            if (this.formValues.routes.length > 0) {
                this.formValues.routes.forEach((route) => {
                    route.orderRouteCars = [];
                });
            }
        });
    }

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            isExtraDiscount: observable,
            addressesFromUrl: observable,
            rentHoursFromUrl: observable,
            excursionPointsFromUrl: observable,
            passengersCountHasBeenSet: observable,
            waitingTimesFromUrl: observable,
            carCategoryIdFromUrl: observable,
            passengersCountFromUrl: observable,
            pickupDateFromUrl: observable,
            isSaving: observable,
            isPhoneaaded: observable,
            isReturnPath: observable,
            usePickupDateLive: observable,
            step: observable,
            contactPhone: observable,
            contactTagName: observable,
            defaultValues: observable,
            tagNameParts: observable,
            formValues: observable,
            pickupDate: observable,
            pickupTime: observable,
            returnPickupDate: observable,
            returnPickupTime: observable,
            backRouteStepBackButton: observable,
            editId: observable,
            editRouteId: observable,
            addReturnRoute: observable,
            billingInformationChecked: observable,
            conditionsApproved: observable,
            backRoute: observable,
            backRouteStep: observable,
            returnRouteMode: observable,
            returnRouteModeInit: observable,
            startExtras: observable,
            selectedExtras: observable,
            filteredExtras: observable,
            startPrice: observable,
            paymentStatus: observable,
            originalPaymentType: observable,
            startPriceCars: observable,
            endPriceCars: observable,
            price: observable,
            priceCars: observable,
            passengersCount: observable,
            car: observable,
            selectedCars: observable,
            start: observable,
            startValue: observable,
            existingRoutes: observable,
            finish: observable,
            finishValue: observable,
            startName: observable,
            finishName: observable,
            routeDistance: observable,
            routeDuration: observable,
            pricesByCarCategories: observable,
            selectedExtraCategory: observable,
            extraAmounts: observable,
            showAllTopCarsCount: observable,
            showCustomCarsCount: observable,
            showAllPayments: observable,
            paymentCurrency: observable,
            error: observable,
            setError: action,
            clearError: action,
            scrollToError: observable,
            isShuttleAvailable: observable,
            flightNumber: observable,
            busNumber: observable,
            trainNumber: observable,
            chooseCustomCar: observable,
            chooseChild: observable,
            showNotes: observable,
            paymentType: observable,
            kids1: observable,
            kids2: observable,
            kids3: observable,
            haveToPayInAdvance: observable,
            isForPricing: observable,
            mode: observable,
            editOrder: observable,
            rentHours: observable,
            excursionPoints: observable,
            routeTotalDuration: observable,
            resetForm: observable,
            prefixLength: observable,
            translatedLocations: observable,
            isModalOpened: observable,
            isDuplicateModalOpened: observable,
            checkDuplicateError: observable,
            duplicateRouteIds: observable,
            isstartRed: observable,
            isFinishRed: observable,
            isNotice1: observable,
            isNotice2: observable,
            isCancelingModal: observable,
            isf1Address: observable,
            checkValidation: observable,
            a_aid: observable,
            preventRouteByUrlParameter: observable,
            flightTimeToArrive: observable,
            toggleNotesCard: action.bound,
            updateShowAllTopCarsCount: action,
            calculateFlightTimeRecommended: action,
            setPickupDate: action,
            setPickupTime: action,
            setReturnPickupDate: action,
            setReturnPickupTime: action,
            scrollToExtraCategory: action,
            setFilteredExtras: action,
        });

        this.updateShowAllTopCarsCount();
    }

    scrollToMyRef() {
        window.scrollTo(0, 0);
        // window.scrollTo({
        //     top: this.myRef.current.offsetTop,
        //     behavior: "smooth",
        // });
    }

    /**
     * Tato metoda slouží pro zformátování car Categories z frontend logiky pro backend logiku
     */
    unGroupCarCategoriesByIdForRoute(route) {
        // eslint-disable-line class-methods-use-this
        const routeTemp = { ...route };
        routeTemp.orderRouteCars = [];
        route.orderRouteCars.forEach((category) => {
            let index = 0;
            do {
                routeTemp.orderRouteCars.push(category);
                index += 1;
            } while (index < category.carCount);
        });

        return routeTemp;
    }

    /**
     * Tato metoda slouží pro zformátování car Categories z backend logiky pro frontend logiku
     */
    groupCarCategoriesByIdFromRoute(orderRouteCars) {
        // eslint-disable-line class-methods-use-this
        const groupedCarCategories = [];

        orderRouteCars.forEach((routeCar) => {
            const tempCarCategory = {
                carCategoryId: routeCar.carCategory ? routeCar.carCategory.id : null,
                carCategoryName: routeCar.carCategory ? i18n.t(routeCar.carCategory.name) : null,
                carCategoryDescription: routeCar.carCategory.description,
                price: routeCar.priceClient,
                carCount: 1,
                statusName: routeCar.statusName,
                capacity: routeCar.carCategory && routeCar.carCategory.carModels.length ? routeCar.carCategory.carModels[0] : 0,
                luggageCapacity:
                    routeCar.carCategory && routeCar.carCategory.carModels.length ? routeCar.carCategory.carModels[0].luggageCapacity : 0,
            };

            let exists = false;

            groupedCarCategories.forEach((carCategory) => {
                if (carCategory.id === tempCarCategory.carCategoryId) {
                    if (!exists) {
                        carCategory.carCount += 1; // eslint-disable-line no-param-reassign
                        exists = true;
                    }
                }
            });

            if (!exists) {
                groupedCarCategories.push(new CarCategoryDecorator(tempCarCategory));
            }
        });

        return groupedCarCategories;
    }

    addDuplicateRoutes(item) {
        this.reset();
        this.mode = "order";
        if (item.isExcursion) {
            this.mode = "excursion";
        } else if (item.action.activeRoute.rentHours > 0) {
            this.mode = "hour-rental";
        }

        const { customer } = item.order;
        if (item.route.contactPersonPhoneOnlyPrefix) {
            this.prefixLength = item.route.contactPersonPhoneOnlyPrefix.length + 1;
        }
        this.formValues = {
            customerData: {
                firstName: customer.firstName,
                lastName: customer.lastName,
                tagName: customer.tagName,
                phone: item.route.contactPersonPhone,
                email: customer.email,
                createNewEntity: customer.createNewEntity,
            },
            routes: [item.action.activeRoute],
        };
        this.formValues.routes[0].pickupDate = moment(item.action.activeRoute.pickupDate, config.apiDateTimeFormat);
        this.defaultValues.withoutLuggage = !item.action.activeRoute.withLuggage;
        this.defaultValues.withLuggage = item.action.activeRoute.withLuggage;
        this.defaultValues.excessiveLuggage = item.action.activeRoute.excessiveLuggage;

        this.usePickupDateLive = item.action.activeRoute.usePickupDateLive;

        if (item.route.kids1 || item.route.kids2 || item.route.kids3) {
            this.chooseChild.thereRoute = true;
        }

        this.pickupDate = moment(item.date, config.webDateFormat) || null;
        this.pickupTime = moment(item.time, config.webTimeFormat) || null;

        this.kids1 = {
            thereRoute: item.route.kids1,
        };

        this.kids2 = {
            thereRoute: item.route.kids2,
        };

        this.kids3 = {
            thereRoute: item.route.kids3,
        };

        this.paymentStatus = item.route.paymentStatus;

        this.paymentType = item.route.paymentType.id;
        if (PaymentStore.getSelectedCurrencies().length) {
            if (!PaymentStore.getSelectedCurrencies().find((currency) => currency.id === this.paymentType)) {
                this.paymentType = PaymentStore.getSelectedCurrencies()[0].id;
            }
        } else {
            this.paymentType = null;
        }

        // this.paymentType=2;
        // console.log(this.paymentType);

        this.start = item.action.activeRoute.start;
        this.finish = item.action.activeRoute.finish;
        this.startValue = item.action.activeRoute.start;
        this.finishValue = item.action.activeRoute.finish;

        if (item.isExcursion) {
            this.excursionPoints = item.action.activeRoute.excursionPoints;
        }

        this.passengersCount = item.action.activeRoute.passengersCount;
        this.rentHours = item.action.activeRoute.rentHours;

        if (item.action.activeRoute.orderRouteCars.length) {
            this.selectedCars.thereRoute = this.groupCarCategoriesByIdFromRoute(item.action.activeRoute.orderRouteCars);
            this.car = this.selectedCars.thereRoute[this.selectedCars.thereRoute.length - 1];
            this.loadCarCategories(false);
        }

        if (item.action.activeRoute.orderRouteCars.length > 1) {
            this.chooseCustomCar.thereRoute = true;
        }

        this.backRoute = false;

        this.step = 1;
        // this.editId = item.action.activeRoute.order.id;
        // this.editRouteId = item.action.activeRoute.id;

        if (item.isExcursion) {
            pushRoute(Router, "excursion", { lang: Router.query.lang, path: "excursion" }, { shallow: true });
        } else if (this.rentHours) {
            pushRoute(Router, "hour-rental", { lang: Router.query.lang, path: "hour-rental" }, { shallow: true });
        } else {
            pushRoute(Router, "index", { lang: Router.query.lang }, { shallow: true });
        }
        window.scrollTo(0, 0);
    }

    addReturnRoutes(item) {
        this.reset();
        this.mode = "order";

        if (item.isExcursion) {
            this.mode = "excursion";
        } else if (item.action.activeRoute.rentHours > 0) {
            this.mode = "hour-rental";
        }

        this.editOrder = true;
        this.isReturnPath = true;

        const { customer } = item.order;
        if (item.route.contactPersonPhoneOnlyPrefix) {
            this.prefixLength = item.route.contactPersonPhoneOnlyPrefix.length + 1;
        }
        this.formValues = {
            customerData: {
                firstName: customer.firstName,
                lastName: customer.lastName,
                tagName: customer.tagName,
                phone: item.route.contactPersonPhone,
                email: customer.email,
                createNewEntity: customer.createNewEntity,
            },
            routes: [item.action.activeRoute],
        };
        this.formValues.routes[0].pickupDate = moment(item.action.activeRoute.pickupDate, config.apiDateTimeFormat);
        this.defaultValues.withoutLuggage = !item.action.activeRoute.withLuggage;
        this.defaultValues.withLuggage = item.action.activeRoute.withLuggage;
        this.defaultValues.excessiveLuggage = item.action.activeRoute.excessiveLuggage;

        this.usePickupDateLive = item.action.activeRoute.usePickupDateLive;

        if (item.route.kids1 || item.route.kids2 || item.route.kids3) {
            this.chooseChild.thereRoute = true;
        }

        this.pickupDate = moment(item.date, config.webDateFormat) || null;
        this.pickupTime = moment(item.time, config.webTimeFormat) || null;

        this.kids1 = {
            thereRoute: item.route.kids1,
        };

        this.kids2 = {
            thereRoute: item.route.kids2,
        };

        this.kids3 = {
            thereRoute: item.route.kids3,
        };

        this.paymentStatus = item.route.paymentStatus;

        this.paymentType = item.route.paymentType.id;
        if (PaymentStore.getSelectedCurrencies().length) {
            if (!PaymentStore.getSelectedCurrencies().find((currency) => currency.id === this.paymentType)) {
                this.paymentType = PaymentStore.getSelectedCurrencies()[0].id;
            }
        } else {
            this.paymentType = null;
        }

        // this.paymentType=2;
        // console.log(this.paymentType);

        this.start = item.action.activeRoute.finish;
        this.finish = item.action.activeRoute.start;
        this.startValue = item.action.activeRoute.finish;
        this.finishValue = item.action.activeRoute.start;

        if (item.isExcursion) {
            this.excursionPoints = item.action.activeRoute.excursionPoints;
        }

        this.passengersCount = item.action.activeRoute.passengersCount;
        this.rentHours = item.action.activeRoute.rentHours;

        if (item.action.activeRoute.orderRouteCars.length) {
            this.selectedCars.thereRoute = this.groupCarCategoriesByIdFromRoute(item.action.activeRoute.orderRouteCars);
            this.car = this.selectedCars.thereRoute[this.selectedCars.thereRoute.length - 1];
            this.loadCarCategories(false);
        }

        if (item.action.activeRoute.orderRouteCars.length > 1) {
            this.chooseCustomCar.thereRoute = true;
        }

        this.backRoute = false;

        this.step = 1;
        this.editId = item.action.activeRoute.order.id;
        this.editRouteId = item.action.activeRoute.id;

        if (item.isExcursion) {
            pushRoute(Router, "excursion", { lang: Router.query.lang, path: "excursion" }, { shallow: true });
        } else if (this.rentHours) {
            pushRoute(Router, "hour-rental", { lang: Router.query.lang, path: "hour-rental" }, { shallow: true });
        } else {
            pushRoute(Router, "index", { lang: Router.query.lang }, { shallow: true });
        }
        window.scrollTo(0, 0);
    }

    doEdit(item) {
        this.reset();
        this.mode = "order";

        if (item.isExcursion) {
            this.mode = "excursion";
        } else if (item.action.activeRoute.rentHours > 0) {
            this.mode = "hour-rental";
        }

        this.editOrder = true;

        const { customer } = item.order;
        if (item.route.contactPersonPhoneOnlyPrefix) {
            this.prefixLength = item.route.contactPersonPhoneOnlyPrefix.length + 1;
        }
        this.formValues = {
            customerData: {
                firstName: customer.firstName,
                lastName: customer.lastName,
                tagName: customer.tagName,
                phone: item.route.contactPersonPhone,
                email: customer.email,
                createNewEntity: customer.createNewEntity,
            },
            routes: [item.action.activeRoute],
        };
        this.formValues.routes[0].pickupDate = moment(item.action.activeRoute.pickupDate, config.apiDateTimeFormat);
        this.defaultValues.withoutLuggage = !item.action.activeRoute.withLuggage;
        this.defaultValues.withLuggage = item.action.activeRoute.withLuggage;
        this.defaultValues.excessiveLuggage = item.action.activeRoute.excessiveLuggage;

        this.usePickupDateLive = item.action.activeRoute.usePickupDateLive;

        if (item.route.kids1 || item.route.kids2 || item.route.kids3) {
            this.chooseChild.thereRoute = true;
        }

        this.pickupDate = moment(item.date, config.webDateFormat) || null;
        this.pickupTime = moment(item.time, config.webTimeFormat) || null;

        this.kids1 = {
            thereRoute: item.route.kids1,
        };

        this.kids2 = {
            thereRoute: item.route.kids2,
        };

        this.kids3 = {
            thereRoute: item.route.kids3,
        };

        this.paymentStatus = item.route.paymentStatus;

        this.originalPaymentType = item.route.paymentType.id;
        this.paymentType = this.originalPaymentType;

        // Ensure the paymentType is valid
        if (PaymentStore.getSelectedCurrencies().length) {
            if (!PaymentStore.getSelectedCurrencies().find((currency) => currency.id === this.paymentType)) {
                this.paymentType = PaymentStore.getSelectedCurrencies()[0].id;
            }
        } else {
            this.paymentType = null;
        }
        console.log(this.paymentType);

        this.start = item.action.activeRoute.start;
        this.finish = item.action.activeRoute.finish;
        this.startValue = item.action.activeRoute.start;
        this.finishValue = item.action.activeRoute.finish;

        if (item.isExcursion) {
            this.excursionPoints = item.action.activeRoute.excursionPoints;
        }

        this.passengersCount = item.action.activeRoute.passengersCount;
        this.rentHours = item.action.activeRoute.rentHours;

        if (item.action.activeRoute.orderRouteCars.length) {
            this.selectedCars.thereRoute = this.groupCarCategoriesByIdFromRoute(item.action.activeRoute.orderRouteCars);
            this.car = this.selectedCars.thereRoute[this.selectedCars.thereRoute.length - 1];
            this.loadCarCategories(false);
        }

        if (item.action.activeRoute.orderRouteCars.length > 1) {
            this.chooseCustomCar.thereRoute = true;
        }

        this.backRoute = false;

        this.step = 1;
        this.editId = item.action.activeRoute.order.id;
        this.editRouteId = item.action.activeRoute.id;

        if (item.isExcursion) {
            pushRoute("excursion", { lang: Router.query.lang, path: "excursion" });
        } else if (this.rentHours) {
            pushRoute("hour-rental", { lang: Router.query.lang, path: "hour-rental" });
        } else {
            pushRoute("index", { lang: Router.query.lang });
        }
        window.scrollTo(0, 0);
    }

    /**
     * Filtered / selectedExtras is used in extras basket (contains image).
     */
    transformExtraToFilteredExtra(extra, amount) {
        return {
            key: extra.id,
            id: extra.id,
            img: extra.documents,
            item: i18n.exists(extra.name.key) ? i18n.t(extra.name.key) : extra.name.cz,
            amount,
            price: extra.price,
            totalPrice: amount * extra.price,
            isActive: extra.isActive,
        };
    }

    filterExtras = () => {
        this.filterSelectedExtrasBySelectedCars(this.selectedCars[this.getRouteWay()]);
        this.setFilteredExtras(this.selectedExtraCategory);
    };

    /**
     * Vrací true pokud je alespon jedno z aut v extra
     */
    isCarInExtra = (extra, cars) => {
        let carIsInExtras = false;
        extra.carCategories.forEach((carCategory) =>
            cars.forEach((car) => {
                if (carCategory && car && carCategory.id === car.id) {
                    carIsInExtras = true;
                }
            })
        );
        return carIsInExtras;
    };

    isCashAndPartlyPaid = (route) =>
        route.paymentType.id === PaymentStore.TYPE_CASH && route.arrear > 0 && route.arrear !== route.totalPrice;

    /**
     * Extras for selected category. Contains images.
     */
    setFilteredExtras(extraCategoryId) {
        this.selectedExtraCategory = parseInt(extraCategoryId, 10);
        this.filteredExtras = [];

        ExtraStore.extras.forEach((extra) => {
            const cars = this.selectedCars[this.getRouteWay()] || CarCategoryStore.carCategories;
            const carIsInExtra = this.isCarInExtra(extra, cars);

            if (carIsInExtra && extra.extraCategory.id === this.selectedExtraCategory && extra.isInStock) {
                this.filteredExtras.push(this.transformExtraToFilteredExtra(extra, 1));
            }
        });
    }

    /**
     * Filtruje nákupní košík podle toho zda je alespon jedno auto z aut je v extras carCategories
     */
    filterSelectedExtrasBySelectedCars(cars) {
        const routeWay = this.getRouteWay();
        this.selectedExtras[routeWay] = this.selectedExtras[routeWay].filter((selectedExtra) => {
            const extraByCarId = ExtraStore.extras.find((extra) => extra.id === selectedExtra.id);
            return this.isCarInExtra(extraByCarId, cars);
        });
    }

    addExcursionPoint(index) {
        this.excursionPoints.splice(index, 0, {
            place: "",
            name: "",
            waitingTime: 0,
            description: "",
        });
    }

    getSelectedCarId = () => {
        let car = null;

        if (CarCategoryStore.isLoading) {
            return null;
        }

        car = this.selectedCars[this.getRouteWay()][this.selectedCars[this.getRouteWay()].length - 1] || CarCategoryStore.carCategories[0];

        if (car && car.id != null) {
            return car.id.toString();
        }
        return null;
    };

    getSelectedCarsTotalCount() {
        let count = 0;

        this.selectedCars[this.getRouteWay()].forEach((category) => {
            count += category.carCount;
        });

        return count;
    }

    getSelectedCarsCount(carModelId) {
        let count = 0;

        this.selectedCars[this.getRouteWay()].forEach((category) => {
            if (category.id === carModelId) {
                count = category.carCount;
            }
        });
        return count;
    }

    /**
     * Slouží pro zjištění, zda se aplikace nachází ve výběru pro cestu zpět, nebo tam.
     */
    getRouteWay() {
        return this.returnRouteMode ? "backRoute" : "thereRoute";
    }

    isOneOfSelectedCarsPayInAdvance() {
        return !!this.selectedCars[this.getRouteWay()].find((selectedCar) => selectedCar.payAdvance);
    }

    getRouteNumber() {
        if (this.returnRouteMode) {
            return 1;
        }

        return 0;
    }

    hasSelectedCarsZeroPrice() {
        const allSelectedCars = this.selectedCars.thereRoute.concat(this.selectedCars.backRoute);
        let isZeroPrice = allSelectedCars.length === 0;
        allSelectedCars.forEach((sc) => {
            if (sc && sc.price <= 0) isZeroPrice = true;
        });
        if (this.isForPricing) {
            isZeroPrice = true;
        }

        return isZeroPrice;
    }

    isOneOfSelectedExtrasHaveToPayInAdvance() {
        let extraHaveToPayInAdvance = false;
        this.selectedExtras[this.getRouteWay()].forEach((selectedExtra) => {
            const extraFromStore = ExtraStore.extras.find((extra) => extra.id === selectedExtra.id);
            if (extraFromStore.isPayInAdvance) {
                extraHaveToPayInAdvance = true;
            }
        });
        return extraHaveToPayInAdvance;
    }

    setIfRouteHasToBePaidInAdvance() {
        runInAction(() => {
            const routeWay = this.getRouteWay();
            this.haveToPayInAdvance[routeWay] = false;

            if (this.isOneOfSelectedCarsPayInAdvance()) {
                this.haveToPayInAdvance[routeWay] = true;
            }
            if (
                // From bus or train station payInAdvance
                CarCategoryStore.zoneInfo === ZoneStore.TYPE_TRAIN_FROM ||
                CarCategoryStore.zoneInfo === ZoneStore.TYPE_BUS_FROM
            ) {
                this.haveToPayInAdvance[routeWay] = true;
            }
            if (this.isOneOfSelectedExtrasHaveToPayInAdvance()) {
                this.haveToPayInAdvance[routeWay] = true;
            }

            if (this.backRoute && this.haveToPayInAdvance[routeWay]) {
                this.haveToPayInAdvance[routeWay] = true;
            }

            if (!CarCategoryStore.isStartPrague) {
                this.haveToPayInAdvance[routeWay] = true;
            }

            if (CarCategoryStore.isStartPrague && !CarCategoryStore.isFinishPrague && this.backRoute) {
                this.haveToPayInAdvance[routeWay] = true;
            }

            const allowedPaymentTypeIds = PaymentStore.getAllowedPaymentTypes().map((pt) => pt.id);

            let { paymentType } = this;

            this.isForPricing = !CarCategoryStore.isStartPrague && !CarCategoryStore.isFinishPrague;

            if (CarCategoryStore.isCarDeliveryPrice) {
                this.isForPricing = false;
            }

            if (this.hasSelectedCarsZeroPrice()) {
                this.isForPricing = true;
                paymentType = PaymentStore.TYPE_PAY_LATER;
            } else if (allowedPaymentTypeIds.length > 0 && allowedPaymentTypeIds.indexOf(paymentType) === -1) {
                paymentType = allowedPaymentTypeIds.length ? allowedPaymentTypeIds[0] : null;
            } else {
                paymentType = PaymentStore.getDefaultPaymentType();
            }

            this.paymentType = paymentType;
        });
    }

    setThereRoute(selectedCar) {
        this.selectedCars.thereRoute[0] = selectedCar;
        this.selectedCars.thereRoute[0].carCount = 1;
    }

    setBackRoute(selectedCar) {
        this.selectedCars.backRoute[0] = selectedCar;
        this.selectedCars.backRoute[0].carCount = 1;
    }

    setRouteByRouteWay(selectedCar) {
        this.selectedCars[this.getRouteWay()][0] = selectedCar;
        this.selectedCars[this.getRouteWay()][0].carCount = 1;
    }

    /**
     * Slouží pro výběr jednoho vozidla nebo kombinace (nejedná se o ruční výběr)
     */
    selectCar(car = null) {
        let selectedCar = car;
        if (car === null) {
            // issue: 825
            // selectedCar = CarCategoryStore.carCategories.filter(cc => cc.price > 0)[0];
            selectedCar = CarCategoryStore.carCategories[0];
        }

        if (!this.backRoute) {
            this.setThereRoute(selectedCar);
        } else if (this.backRouteStep && this.returnRouteMode) {
            if (
                this.selectedCars.thereRoute === null ||
                this.selectedCars.thereRoute.length === 0 ||
                this.selectedCars.backRoute === null ||
                this.selectedCars.backRoute.length === 0
            ) {
                this.setThereRoute(selectedCar);
                this.setBackRoute(selectedCar);
            } else {
                this.setRouteByRouteWay(selectedCar);
            }
        } else {
            this.setThereRoute(selectedCar);
            this.setBackRoute(selectedCar);
        }

        this.car = selectedCar;

        // if (this.backRoute && this.getRouteWay() === "thereRoute") {
        //     this.selectedCars.backRoute = this.selectedCars;
        // }

        if (selectedCar && selectedCar.isNoBabySeat) {
            this.kids1[this.getRouteWay()] = null;
            this.kids2[this.getRouteWay()] = null;
            this.kids3[this.getRouteWay()] = null;
        }

        this.calculatePrice();
        this.setIfRouteHasToBePaidInAdvance();

        if (this.car && this.car.isreturnDiscount) {
            this.isExtraDiscount = true;
        }
    }

    /**
     * resetuje již vybrané vozidla a pokud není vybrán ruční výběr vozidla, tak se nastaví i výchozí vozidlo
     */
    resetSelectedCars() {
        this.selectedCars[this.getRouteWay()] = [];

        this.updateShowAllTopCarsCount();
        this.showCustomCarsCount = 3;
        this.chooseCustomCar[this.getRouteWay()] = !this.chooseCustomCar[this.getRouteWay()];

        // if (!this.chooseCustomCar[this.getRouteWay()]) {
        //    if (CarCategoryStore.carCategories[0]) {
        //        this.selectCar();
        //    }
        // }
        this.price = 0;
        this.priceCars = 0;
        this.startPrice = 0;
        this.startPriceCars = 0;
        this.calculatePrice();
        this.setIfRouteHasToBePaidInAdvance();
    }

    resetSelectedCarsCustom() {
        if (this.chooseCustomCar[this.getRouteWay()]) {
            this.selectedCars = {
                thereRoute: [],
                backRoute: [],
            };
            this.price = 0;
            this.priceCars = 0;
        }
    }

    resetSelectedCarsSelect() {
        this.selectedCars[this.getRouteWay()] = [];
        this.updateShowAllTopCarsCount();
        this.showCustomCarsCount = 3;

        if (CarCategoryStore.carCategories[0]) {
            this.selectCar();
        }
        this.price = 0;
        this.priceCars = 0;
        this.calculatePrice();
        this.setIfRouteHasToBePaidInAdvance();
    }

    /**
     * Slouží pro přídání vozidla v ruční výběru
     */
    addCustomCar(car) {
        const addOrUpdateCarInRoute = (routeKey, carToAdd) => {
            let exist = this.selectedCars[routeKey].some((category) => category.id === carToAdd.id);
            if (exist) {
                this.selectedCars[routeKey] = this.selectedCars[routeKey].map((category) => {
                    if (category.id === carToAdd.id && category.carCount < 20) {
                        return { ...category, carCount: category.carCount + 1 };
                    }
                    return category;
                });
            } else {
                this.selectedCars[routeKey].push({ ...carToAdd, carCount: 1 });
            }
        };

        addOrUpdateCarInRoute(this.getRouteWay(), car);

        if (this.backRoute) {
            const oppositeRoute = this.getRouteWay() === "thereRoute" ? "backRoute" : "thereRoute";
            addOrUpdateCarInRoute(oppositeRoute, car);
        }

        this.car = car;

        this.setIfRouteHasToBePaidInAdvance();
        this.calculatePrice();

        //console.log('Selected cars after addition/update:', JSON.stringify(this.selectedCars, null, 2));
    }

    /**
     * Slouží pro vymazání vybraného vozidla v ruční výběru
     */
    removeCustomCar(car) {
        const removeOrUpdateCarInRoute = (routeKey, carToRemove) => {
            let carFoundAndUpdated = false;
            const updatedCars = this.selectedCars[routeKey]
                .map((category) => {
                    if (category.id === carToRemove.id) {
                        if (category.carCount > 1) {
                            carFoundAndUpdated = true;
                            return { ...category, carCount: category.carCount - 1 };
                        } else {
                            carFoundAndUpdated = true;
                            return null;
                        }
                    }
                    return category;
                })
                .filter((category) => category !== null);

            if (carFoundAndUpdated) {
                this.selectedCars[routeKey] = updatedCars;
            }
        };

        removeOrUpdateCarInRoute(this.getRouteWay(), car);

        if (this.backRoute) {
            const oppositeRoute = this.getRouteWay() === "thereRoute" ? "backRoute" : "thereRoute";
            removeOrUpdateCarInRoute(oppositeRoute, car);
        }

        this.setIfRouteHasToBePaidInAdvance();
        this.calculatePrice();

        //console.log('Selected cars after removal/update:', JSON.stringify(this.selectedCars, null, 2));
    }

    changeExtraAmount(extra) {
        const selectedExtrasTemp = [];
        const extraTemp = { ...extra };
        extraTemp.amount = 0;
        if (this.extraAmounts[extra.id]) {
            extraTemp.amount += parseInt(
                // eslint-disable-line no-param-reassign
                this.extraAmounts[extra.id],
                10
            );
        } else {
            extraTemp.amount += 1; // eslint-disable-line no-param-reassign
        }

        let exists = false;
        this.selectedExtras[this.getRouteWay()].forEach((selected) => {
            if (selected.id === extra.id) {
                selected.amount += extraTemp.amount; // eslint-disable-line no-param-reassign
                exists = true;
            }
            selectedExtrasTemp.push(selected);
        });

        if (!exists) {
            selectedExtrasTemp.push({ ...extraTemp });
        }

        this.selectedExtras[this.getRouteWay()] = selectedExtrasTemp;
        this.calculatePrice();
    }

    deleteFromExtra(extra) {
        const selectedExtrasTemp = [];
        this.selectedExtras[this.getRouteWay()].forEach((selected) => {
            if (selected.id !== extra.id) {
                selectedExtrasTemp.push(selected);
            }
        });
        this.selectedExtras[this.getRouteWay()] = selectedExtrasTemp;
    }
}

export default new NewOrderStore();
