import React, { forwardRef } from "react";
import { TimePicker } from "antd";

export const TimePickerAutoAccept = forwardRef((props, ref) => {
    const handleSelect = (time) => {
        if (props.onChange) {
            props.onChange(time);
        }
    };

    return (
        <TimePicker 
            {...props} 
            ref={ref} 
            onCalendarChange={handleSelect} 
            allowClear={false} 
        />
    );
});
