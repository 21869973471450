import React from "react";
import { Popover, Switch } from "antd";
import { WarningOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import { observer } from "mobx-react";
import ZoneStore from "../../../../../stores/Zone/ZoneStore";
import NewOrderStore from "../../../../../stores/Order/NewOrderStore";
import i18n from "../../../../../core/i18n";
import HelpButton from "../../../../HelpButton";
import dayjs from "dayjs";

const airportType = "airport";
const trainStationType = "train_station";
const busStationType = "bus_station";
const otherType = "bus_station";

const MeansOfTransport = ({ form, formValues, routeNumber, formItemLayout, returnRouteMode }) => {
    const zoneInfo = ZoneStore.getType(returnRouteMode);
    const showInputs = [{ name: otherType, element: null }];

    const dm = form.form.getFieldValue(`routes[${routeNumber}][flightNumber]`);

    let recommededTime = NewOrderStore.calculateFlightTimeRecommended(routeNumber, dm);

    // Extract flight data for the current route
    const flightInfo = NewOrderStore.flightTimeToArrive[routeNumber]?.[0] || null;

    // Initialize actualTime as null
    let actualTime = null;

    if (flightInfo) {
        if (flightInfo.timeToArrive && flightInfo.timeToArrive.trim() !== "") {
            // If timeToArrive is available, use it
            const times = flightInfo.timeToArrive.split(" - ");
            actualTime = times.length > 1 ? times[1] : times[0];
        } else if (flightInfo.timeNew) {
            // If timeToArrive is not available, use timeNew
            actualTime = dayjs(flightInfo.timeNew).format("HH:mm");
        }
    }

    if (zoneInfo !== null) {
        if (zoneInfo === ZoneStore.TYPE_AIRPORT_FROM || zoneInfo === ZoneStore.TYPE_AIRPORT_TO) {
            showInputs.push({
                name: airportType,
                element: (
                    <AirportElement
                        form={form}
                        formItemLayout={formItemLayout}
                        routeNumber={routeNumber}
                        formValues={formValues}
                        returnRouteMode={returnRouteMode}
                        recommededTime={recommededTime}
                        zoneInfo={zoneInfo}
                        actualTime={actualTime}
                    />
                ),
            });
        }

        if (zoneInfo === ZoneStore.TYPE_TRAIN_FROM) {
            let initialValue = formValues.routes[routeNumber]?.trainNumber;
            if (returnRouteMode) {
                initialValue = initialValue || formValues.routes[1]?.trainNumber;
            }

            showInputs.push({
                name: trainStationType,
                element: form.render(`routes[${routeNumber}][trainNumber]`, { initialValue }, formItemLayout),
            });
        }

        if (zoneInfo === ZoneStore.TYPE_BUS_FROM) {
            let initialValue = formValues.routes[routeNumber]?.busNumber;
            if (returnRouteMode) {
                initialValue = initialValue || formValues.routes[1]?.busNumber;
            }

            showInputs.push({
                name: busStationType,
                element: form.render(`routes[${routeNumber}][busNumber]`, { initialValue }, formItemLayout),
            });
        }
    }

    return (
        <>
            {(ZoneStore.isTypeAirportFrom(returnRouteMode) || ZoneStore.isTypeAirportTo(returnRouteMode)) &&
                NewOrderStore.mode === "hour-rental" && (
                    <div style={{ color: "red", marginTop: "14px", marginBottom: "14px" }}>
                        <WarningOutlined style={{ marginRight: 7 }} />
                        {i18n.t("Hourly rental is not intended for pickups from and to the airport but should be booked as a transfer.")}
                    </div>
                )}
            <>
                {showInputs.map((showInput) => (
                    <React.Fragment key={showInput.name}>{showInput.element}</React.Fragment>
                ))}
            </>
        </>
    );
};

const AirportElement = ({ form, formValues, routeNumber, formItemLayout, returnRouteMode, recommededTime, zoneInfo, actualTime }) => (
    <>
        {form.render(
            `routes[${routeNumber}][flightNumber]`,
            {
                initialValue: formValues.routes[routeNumber] ? formValues.routes[routeNumber].flightNumber : null,
            },
            formItemLayout
        )}

        {ZoneStore.isTypeAirportFrom(returnRouteMode) && (
            <div style={{ marginTop: 18, fontWeight: 500 }}>
                {NewOrderStore.usePickupDateLive && (
                    <span>
                        {i18n.t("Vyzvednout v době příletu")}
                        <Popover
                            placement="top"
                            content={i18n.t(
                                "Náš řidič bude monitorovat čas příletu a vyzvedneme vás po čase aktuálního příletu bez ohledu na to zda let bude mít zpoždění nebo zrychlení."
                            )}
                        >
                            <HelpButton />
                        </Popover>
                    </span>
                )}
                {!NewOrderStore.usePickupDateLive && (
                    <span>
                        {i18n.t("Vyzvednout ve vybraný čas")}
                        <Popover placement="top" content={i18n.t("Vyzvedneme vás v tento určený čas bez ohledu na čas příletu.")}>
                            <HelpButton />
                        </Popover>
                    </span>
                )}

                <span className="white-space-nowrap">
                    <Switch
                        checked={NewOrderStore.usePickupDateLive}
                        checkedChildren={i18n.t("Ano")}
                        unCheckedChildren={i18n.t("Ne")}
                        onChange={() => {
                            NewOrderStore.usePickupDateLive = !NewOrderStore.usePickupDateLive;
                        }}
                    />
                </span>
            </div>
        )}

        <div style={{ marginTop: 20, marginBottom: 20 }}>
            {recommededTime &&
                recommededTime === "255" &&
                NewOrderStore.usePickupDateLive &&
                zoneInfo === ZoneStore.TYPE_AIRPORT_FROM &&
                actualTime && (
                    <p style={{ color: "#0958d9" }}>
                        <InfoCircleTwoTone style={{ marginRight: 7 }} />
                        {i18n.t(
                            "When choosing option Track my flight time scheduled arrival should be {{time}} or uncheck Track my flight option.",
                            { time: actualTime }
                        )}
                    </p>
                )}
            {recommededTime &&
                recommededTime !== "255" &&
                routeNumber === 1 &&
                NewOrderStore.usePickupDateLive &&
                zoneInfo === ZoneStore.TYPE_AIRPORT_TO && (
                    <p style={{ color: "#0958d9" }}>
                        <InfoCircleTwoTone style={{ marginRight: 7 }} />
                        {i18n.t("Recommeded pickup time is {{time}} But not mandatory.", { time: recommededTime })}
                    </p>
                )}
            {recommededTime && recommededTime !== "255" && routeNumber === 0 && (
                <p style={{ color: "#0958d9" }}>
                    <InfoCircleTwoTone style={{ marginRight: 7 }} />
                    {i18n.t("Recommeded pickup time is {{time}} But not mandatory.", { time: recommededTime })}
                </p>
            )}
        </div>
    </>
);

export default observer(MeansOfTransport);
