import { observable, action, makeObservable, runInAction } from "mobx";
import RootStore from "../RootStore";
import i18n from "../../core/i18n";
import CurrencyStore from "../Currency/CurrencyStore";
import dayjs from "dayjs";
import "dayjs/locale/cs";
import "dayjs/locale/de";
import "dayjs/locale/ar";
import "dayjs/locale/bg";
import "dayjs/locale/da";
import "dayjs/locale/el";
import "dayjs/locale/es";
import "dayjs/locale/fi";
import "dayjs/locale/fr";
import "dayjs/locale/he";
import "dayjs/locale/hr";
import "dayjs/locale/hu";
import "dayjs/locale/it";
import "dayjs/locale/ja";
import "dayjs/locale/ko";
import "dayjs/locale/lv";
import "dayjs/locale/nb";
import "dayjs/locale/pl";
import "dayjs/locale/pt";
import "dayjs/locale/ro";
import "dayjs/locale/ru";
import "dayjs/locale/sv";
import "dayjs/locale/th";
import "dayjs/locale/tr";
import "dayjs/locale/zh-cn";

export const DEFAULT_LANG = "en";

class LanguageStore {
    lang = DEFAULT_LANG;
    languages = [];
    translations = [];
    pageTranslations = [];
    isLoading = false;

    constructor() {
        makeObservable(this, {
            lang: observable,
            languages: observable,
            translations: observable,
            pageTranslations: observable,
            isLoading: observable,
            setInit: action,
            setLanguage: action,
        });
    }

    /**
     * Initializes the store with language data from the backend
     */
    // eslint-disable-next-line require-await
    getInit = async (language) =>
        RootStore.api.get("/init", {
            language,
        });

    /**
     * Sets initial language and related data
     */
    setInit = (init) => {
        const { language, languages, translations, pageTranslations } = init;

        runInAction(() => {
            this.lang = language;
            this.languages = languages;
            this.translations = translations;
            this.pageTranslations = pageTranslations;
        });

        i18n.addResourceBundle(language, "translation", translations, true, true);
        // Assuming i18n is initialized elsewhere

        // If "cz" is what your server uses, dayjs actually expects "cs"
        const dayJsLang = language === "cz" ? "cs" : (language === "no" ? "nb" : language);
        dayjs.locale(dayJsLang);

        // SET DEFAULT CURRENCY
        const langObj = this.languages.find((lang) => lang.language === language);
        const currency = langObj?.currency || "CZK";
        CurrencyStore.selectCurrency(currency);
        CurrencyStore.selectTourCurrency(currency);

        if (typeof window !== "undefined") {
            const currencyNew = localStorage.getItem("CurrencyStoreCode");
            if (!currencyNew) {
                localStorage.setItem("CurrencyStoreCode", currency);
            }
        }
    };

    /**
     * Sets the current language and updates related configurations
     */
    setLanguage = async (language) => {
        if (!this.isLangExists(language)) {
            console.warn(`Language "${language}" is not supported.`);
            return;
        }

        this.isLoading = true;
        try {
            const initData = await this.getInit(language);
            runInAction(() => {
                this.setInit(initData);
                this.lang = language;
                this.isLoading = false;
            });
            // Update i18n language
            i18n.changeLanguage(language);
        } catch (error) {
            runInAction(() => {
                this.isLoading = false;
            });
            console.error("Failed to initialize language data:", error);
        }
    };

    getLocaleAndDirection(lang) {
        const locales = {
            cs: require("antd/lib/locale/cs_CZ").default,
            de: require("antd/lib/locale/de_DE").default,
            ar: require("antd/lib/locale/ar_EG").default,
            bg: require("antd/lib/locale/bg_BG").default,
            da: require("antd/lib/locale/da_DK").default,
            el: require("antd/lib/locale/el_GR").default,
            es: require("antd/lib/locale/es_ES").default,
            fi: require("antd/lib/locale/fi_FI").default,
            fr: require("antd/lib/locale/fr_FR").default,
            he: require("antd/lib/locale/he_IL").default,
            hr: require("antd/lib/locale/hr_HR").default,
            hu: require("antd/lib/locale/hu_HU").default,
            it: require("antd/lib/locale/it_IT").default,
            ja: require("antd/lib/locale/ja_JP").default,
            ko: require("antd/lib/locale/ko_KR").default,
            lv: require("antd/lib/locale/lv_LV").default,
            nb: require("antd/lib/locale/nb_NO").default,
            pl: require("antd/lib/locale/pl_PL").default,
            pt: require("antd/lib/locale/pt_PT").default,
            ro: require("antd/lib/locale/ro_RO").default,
            ru: require("antd/lib/locale/ru_RU").default,
            sv: require("antd/lib/locale/sv_SE").default,
            th: require("antd/lib/locale/th_TH").default,
            tr: require("antd/lib/locale/tr_TR").default,
            zh: require("antd/lib/locale/zh_CN").default,
        };

        const rtlLanguages = ["ar", "he"]; // Define RTL languages

        const locale = locales[lang] || require("antd/lib/locale/en_US").default; // Default to enUS if not found
        const direction = rtlLanguages.includes(lang) ? "rtl" : "ltr";

        return { locale, direction };
    }

    // eslint-disable-next-line class-methods-use-this
    getPageTranslate(lang, key) {
        if (this.pageTranslations[lang] && this.pageTranslations[lang][key]) {
            return this.pageTranslations[lang][key];
        }
        return null;
    }

    getLocaleName(lang) {
        const found = this.languages.find((l) => {
            const languageKey = l.language === "cz" ? "cs" : l.language;
            return languageKey === lang;
        });
        if (found) return found.nativeName;
        return "";
    }

    isLangExists(languageKey) {
        const key = languageKey === "cs" ? "cz" : languageKey;

        let found = false;
        this.languages.forEach((language) => {
            if (language.language === key) {
                found = true;
            }
        });

        return found;
    }

    /**
     * Finds the same or alternative key based on language key
     * @param {String} languageKey
     */
    findLanguageKey(languageKey) {
        const key = languageKey === "cs" ? "cz" : languageKey;

        let match = null;
        this.languages.forEach((language) => {
            if (language.language === key) {
                match = language.alternativeLanguage ? language.alternativeLanguage : language.language;
            }
        });
        return match;
    }

    /**
     * Finds phone prefix based on language key
     * @param {String} languageKey
     */
    getPhonePrefix(languageKey) {
        const key = languageKey === "cs" ? "cz" : languageKey;

        let match = null;
        this.languages.forEach((language) => {
            if (language.language === key) {
                if (language.phonePreset) {
                    match = language.phonePreset;
                } else {
                    match = "+420";
                }
            }
        });
        return match;
    }

    getLocale = (router) => {
        const locale = router.query.lang === "cs" ? "cz" : router.query.lang;

        if (!this.languages.find((l) => l.language === locale)) {
            return this.lang;
        }

        return locale;
    };
}

export default new LanguageStore();
