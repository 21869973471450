import React from "react";
import { Form, Card, Row, Col } from "antd";
import { PhoneOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import i18n from "../../../../../core/i18n";
import UserListStore from "../../../../../stores/User/UserListStore";
import NewOrderStore from "../../../../../stores/Order/NewOrderStore";
import LanguageStore from "../../../../../stores/Language/LanguageStore";

const CustomerSummary = ({ values, formItemLayout, router }) => {
    const FormItem = Form.Item;
    const { direction } = LanguageStore.getLocaleAndDirection(router.query.lang.toString());
    const customer = values.customerData.email ? values.customerData : UserListStore.user;
    NewOrderStore.formValues.customerData = customer;

    return (
        <Card className="order-form__card order-form__card--auto-width custom-card-summary">
            <Row gutter={50}>
                <Col md={24}>
                    <FormItem {...formItemLayout} label={i18n.t("Kontaktní informace")} className="non-bold-label">
                        <span className="highlighted-value">
                            <span style={{ display: "block" }}>
                                {direction === "rtl" ? (
                                    <span>
                                        <UserOutlined />
                                        {customer.lastName}
                                        &nbsp;
                                        {customer.firstName}
                                    </span>
                                ) : (
                                    <span>
                                        <UserOutlined />
                                        {customer.firstName}
                                        &nbsp;
                                        {customer.lastName}
                                    </span>
                                )}
                            </span>
                            {customer.phone && (
                                <span style={{ display: "block" }}>
                                    <PhoneOutlined />
                                    &nbsp;
                                    {customer.phone}
                                </span>
                            )}

                            <span style={{ display: "block" }}>
                                <MailOutlined />
                                &nbsp;
                                {customer.email}
                            </span>
                        </span>
                    </FormItem>

                    {customer.companyName && (
                        <FormItem {...formItemLayout} label={i18n.t("Fakturační informace")} className="non-bold-label">
                            <span className="highlighted-value">
                                <div>
                                    {i18n.t("Název společnosti")}: {customer.companyName || null}
                                </div>
                                {customer.companyAddress && (
                                    <>
                                        {customer.companyAddress.street && (
                                            <div>
                                                {i18n.t("Ulice a ČP")}: {customer.companyAddress ? customer.companyAddress.street : null}
                                            </div>
                                        )}

                                        {customer.companyAddress.city && (
                                            <div>
                                                {i18n.t("Město")}: {customer.companyAddress ? customer.companyAddress.city : null}
                                            </div>
                                        )}

                                        {customer.companyAddress.zip && (
                                            <div>
                                                {i18n.t("Zip")}: {customer.companyAddress ? customer.companyAddress.zip : null}
                                            </div>
                                        )}
                                        {customer.companyAddress.country && (
                                            <div>
                                                {i18n.t("Země")}: {customer.companyAddress ? customer.companyAddress.country : null}
                                            </div>
                                        )}
                                        {customer.identificationNumber && (
                                            <div>
                                                {i18n.t("IČO")}: {customer.identificationNumber}
                                            </div>
                                        )}
                                        {customer.taxIdentificationNumber && (
                                            <div>
                                                {i18n.t("DIČ")}: {customer.taxIdentificationNumber}
                                            </div>
                                        )}
                                    </>
                                )}
                            </span>
                        </FormItem>
                    )}
                </Col>
            </Row>
        </Card>
    );
};

CustomerSummary.propTypes = {
    values: PropTypes.objectOf(PropTypes.any).isRequired,
    formItemLayout: PropTypes.objectOf(PropTypes.any).isRequired,
    router: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CustomerSummary;
