import React from "react";
import { Button } from "antd";
import CustomIcons from "../CustomIcons";
import i18n from "../../core/i18n";
import LocationStore from "../../stores/Location/LocationStore";
import AutoCompleteLocationStore from "../../stores/AutoCompleteLocation/AutoCompleteLocationStore";
import InvalidAddressModalStore from "../../stores/Order/InvalidAddressModalStore";
import getAddress from "../../utils/autoCompleteLocation/getAddress";
import NewOrderStore from "../../stores/Order/NewOrderStore";

const getIconName = (typeId) => {
    const typeInfo = LocationStore.TYPES.find((type) => type.id === typeId);
    if (typeInfo) {
        return typeInfo.iconName;
    }
    return LocationStore.TYPES[0].iconName;
};

const renderOtherOptions = (allLocations) =>
    allLocations.map((location) => ({
        value: getAddress(location),
        label: (
            <div className="location">
                <div className="location__icon">
                    <CustomIcons icon={getIconName(location.type)} />
                </div>
                <div className="location__content">
                    <span className="location__main">{location.structured_formatting.main_text.toUpperCase()}</span>
                    <span className="location__side">{location.structured_formatting.secondary_text}</span>
                </div>
            </div>
        ),
    }));

const renderLocationOptions = (allLocations) =>
    allLocations.map((location) => ({
        value: getAddress(location),
        label: (
            <div className="location">
                <div className="location__icon">
                    <CustomIcons icon={getIconName(location.type)} />
                </div>
                <div className="location__content">
                    <span className="location__main">{location.name}</span>
                    <span className="location__side">{location.name !== location.address && location.address}</span>
                </div>
            </div>
        ),
    }));

const addGspFeaturs = (filedsearch) => {
    const collectOptions = [];
    const filedValue = filedsearch == "startResult" ? "startcurrentLocation" : "finishcurrentLocation";
    collectOptions.push({
        value: filedValue,
        label: (
            <div className="location">
                <div className="location__icon">
                    <CustomIcons icon="choosemap" />
                </div>
                <div className="location__content">
                    <span className="location__main">{i18n.t("Current location")}</span>
                </div>
            </div>
        ),
    });
    /*
    collectOptions.push({
        value: 'choosemap',
        label: (
            <div className="location">
                <div className="location__icon">
                    <CustomIcons icon="currentLocaction" />
                </div>
                <div className="location__content">
                    <span className="location__main">
                        {i18n.t('Choose on map')}
                    </span>
                </div>
            </div>
        )
    });
    */

    return collectOptions;
};

const RenderOptions = (isStart, isGpsShow = true) => {
    // eslint-disable-line class-methods-use-this
    const varName = isStart ? "startResult" : "finishResult";
    let favoriteLocations = null;
    let googleLocations = null;
    const dataSource = [];
    let GpsLocations = null;
    GpsLocations = {
        label: "GPS",
        options: addGspFeaturs(varName),
    };

    if (AutoCompleteLocationStore[varName].favoriteLocations.length) {
        favoriteLocations = {
            label: i18n.t("Oblíbené lokality"),
            options: renderLocationOptions(AutoCompleteLocationStore[varName].favoriteLocations),
        };
        dataSource.push(favoriteLocations);
    }
    if (AutoCompleteLocationStore[varName].googleLocations.length) {
        googleLocations = {
            label: i18n.t("Výsledky z Google map"),
            options: renderOtherOptions(AutoCompleteLocationStore[varName].googleLocations),
        };
        dataSource.push(googleLocations);
    }
    if (!googleLocations && !favoriteLocations && !AutoCompleteLocationStore.isLoading) {
        dataSource.push({
            label: i18n.t("Nenašli jste svou adresu?"),
            options: [
                {
                    label: <Button onClick={() => InvalidAddressModalStore.open()}>{i18n.t("Nenašli jste svou adresu?")}</Button>,
                },
            ],
        });
    }

    if (NewOrderStore.mode != "excursion" && isGpsShow) {
        dataSource.push(GpsLocations);
    }

    return dataSource;
};

export default RenderOptions;
