import { observable, makeObservable } from "mobx";

export default class ExtraDecorator {
    id = null;

    price = 0;

    isPayInAdvance = false;

    isInStock = false;

    extraCategory = null;

    name = null;

    documents = null;

    description = null;

    carCategories = [];

    amount = 0;

    dictionary = null;

    isActive = false;

    constructor(data) {
        makeObservable(this, {
            price: observable,
            isPayInAdvance: observable,
            isInStock: observable,
            extraCategory: observable,
            name: observable,
            documents: observable,
            description: observable,
            carCategories: observable,
            amount: observable,
            dictionary: observable,
            isActive: observable,
        });

        if (data) {
            this.setAll(data);
        }
    }

    setAll(data) {
        this.id = data.id || null;
        this.documents = data.documents || [];
        this.price = data.price || 0;
        this.isPayInAdvance = data.isPayInAdvance || false;
        this.isInStock = data.isInStock || false;
        this.extraCategory = data.extraCategory || null;
        this.name = data.dictionary.title || null;
        this.carCategories = data.carCategories || [];
        this.amount = data.amount || 0;
        this.dictionary = data.dictionary || null;
        this.isActive = data.isActive || false;

        const { title, ...description } = data.dictionary;
        this.description = description;
    }
}
