import { observable, makeObservable } from "mobx";
import RootStore from "../RootStore";
import NotificationStore from "../NotificationStore";

class SettingsStore {
    isLoading = false;
    settings = {};

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            settings: observable,
        });
    }

    load() {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;
        NotificationStore.reset();

        RootStore.api
            .get("/settings/")
            .then((response) => {
                this.settings = response;

                this.isLoading = false;
            })
            .catch((e) => {
                this.isLoading = false;
                NotificationStore.processException(e, "settings-form");
            });
    }
}

export default new SettingsStore();
