import CurrencyStore from "../../stores/Currency/CurrencyStore";

export default function getAmountInForeignCurrency(price, currency) {
    if (price && currency !== CurrencyStore.CZK && CurrencyStore.currencies) {
        // If JPY, and round to the nearest whole number
        const amount =
            currency === "JPY"
                ? (price / CurrencyStore.currencies[currency].rate).toFixed(0) // Round to whole number for JPY
                : (price / CurrencyStore.currencies[currency].rate).toFixed(2); // Keep two decimal places for other currencies

        return `${amount} ${currency}`;
    }

    if (price && currency === CurrencyStore.CZK && CurrencyStore.currencies) {
        return `${price} ${currency}`;
    }
    return null;
}
