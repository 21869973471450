import React from "react";
import { Checkbox, Form } from "antd";
import { observer } from "mobx-react";
import { WarningOutlined } from "@ant-design/icons"; // Import Warning icon
import NewOrderStore from "../../../../../../stores/Order/NewOrderStore";
import i18n from "../../../../../../core/i18n";

/**
 * Gdpr Component
 * Handles GDPR consent checkbox with validation.
 */
export const Gdpr = observer(() => {
    const { conditionsApproved } = NewOrderStore;

    /**
     * Toggles the conditionsApproved state in NewOrderStore.
     */
    const handleCheckboxChange = () => {
        NewOrderStore.conditionsApproved = !NewOrderStore.conditionsApproved;
    };

    return (
        <div className="checkbox-gdpr">
            <Form.Item
                validateStatus={!conditionsApproved ? "error" : ""}
                help={
                    !conditionsApproved ? (
                        <span>
                            <WarningOutlined style={{ color: "red", marginRight: "5px" }} />
                            {`${i18n.t("Prosím vyplňte")} ${i18n.t("souhlas s podmínkami provozu").toLowerCase()}`}
                        </span>
                    ) : null
                }
            >
                <div style={{ display: "flex", justifyContent: "flex-start", padding: "5px 0px 0px 5px" }}>
                    <Checkbox style={{ margin: "1em 0em" }} checked={conditionsApproved} onChange={handleCheckboxChange}>
                        <div style={{ fontSize: 13 }}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: i18n
                                        .t(
                                            "Dokončením objednávky souhlasíte s Všeobecnými obchodními podmínkami a se zpracováním osobních údajů."
                                        )
                                        .replace("{URL}", "#"),
                                }}
                            />
                        </div>
                    </Checkbox>
                </div>
            </Form.Item>
        </div>
    );
});
