import { observable, makeObservable, action, runInAction } from "mobx";
import RootStore from "../RootStore";
import NotificationStore from "../NotificationStore";
import NewOrderStore from "../Order/NewOrderStore";
import PaymentStore from "../Payment/PaymentStore";
import NewTourOrder from "../Tours/NewTourOrder";

class CurrencyStore {
    isLoading = false;

    currencies = null;

    menuCurrencies = null;

    CZK = "CZK";

    EUR = "EUR";

    selectedCurrency = "CZK";

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            currencies: observable,
            menuCurrencies: observable,
            selectedCurrency: observable,
            load: action,
            selectCurrencyByLocalStorage: action,
            selectCurrency: action,
            selectTourCurrency: action,
            setShowPaymentModal: action,
            setPaymentTourIds: action,
            setHaveToPayInAdvance: action,
            setIsContinueToPaymentModalOpened: action,
            clearIsContinueToPaymentModalOpened: action,
            setRedirectPayUrlMessage: action,
            clearRedirectPayUrlMessage: action,
        });
    }

    // Action Methods

    load() {
        this.isLoading = true;
        NotificationStore.reset();

        RootStore.api
            .get("/currencies/")
            .then((response) => {
                const currencies = {};
                const menuCurrencies = {};
                Object(response).forEach((currency, i) => {
                    currencies[currency.code] = {
                        rate: currency.rate,
                    };
                    menuCurrencies[i] = {
                        key: currency.code,
                        label: currency.code,
                    };
                });
                runInAction(() => {
                    this.currencies = currencies;
                    this.menuCurrencies = menuCurrencies;
                    this.isLoading = false;
                });
            })
            .catch((e) => {
                runInAction(() => {
                    this.isLoading = false;
                });
                NotificationStore.processException(e, "currencies");
            });
    }

    selectCurrencyByLocalStorage() {
        const currency = localStorage.getItem("CurrencyStoreCode");
        if (currency) {
            this.selectCurrency(currency);
            this.selectTourCurrency(currency);
        } else {
            localStorage.setItem("CurrencyStoreCode", this.CZK);
            this.selectCurrency(this.CZK);
            this.selectTourCurrency(this.CZK);
        }
    }

    selectCurrency = (currency) => {
        this.selectedCurrency = currency;
        NewOrderStore.paymentCurrency = currency;
        const paymentMethod = PaymentStore.getSelectedCurrencies();
        let paymentMethodId = null;
        if (paymentMethod[0]) {
            paymentMethodId = paymentMethod[0].id;
        }
        NewOrderStore.paymentType = paymentMethodId;
        NewOrderStore.formValues.routes.forEach((route) => {
            route.paymentCurrency = currency;
        });

        PaymentStore.types.forEach((type, index) => {
            if (type.selectedCurrencies.find((selectedCurrency) => selectedCurrency.code === this.selectedCurrency)) {
                PaymentStore.types[index].selectedCurrencyToPay = this.selectedCurrency;
            } else {
                PaymentStore.types[index].selectedCurrencyToPay = type.selectedCurrencies[0].code;
            }
        });
    };

    selectTourCurrency = (currency) => {
        this.selectedCurrency = currency;
        NewTourOrder.paymentCurrency = currency;
        const paymentMethod = PaymentStore.getSelectedCurrencies();
        let paymentMethodId = null;
        if (paymentMethod[0]) {
            paymentMethodId = paymentMethod[0].id;
        }
        NewTourOrder.paymentType = paymentMethodId;

        PaymentStore.types.forEach((type, index) => {
            if (type.selectedCurrencies.find((selectedCurrency) => selectedCurrency.code === this.selectedCurrency)) {
                PaymentStore.types[index].selectedCurrencyToPay = this.selectedCurrency;
            } else {
                PaymentStore.types[index].selectedCurrencyToPay = type.selectedCurrencies[0].code;
            }
        });
    };

    // Setter Methods

    setShowPaymentModal = (showPaymentModal) => {
        this.showPaymentModal = showPaymentModal;
    };

    setPaymentTourIds = (paymentTourIds) => {
        this.paymentTourIds = paymentTourIds;
    };

    setHaveToPayInAdvance = (haveToPayInAdvance) => {
        this.haveToPayInAdvance = haveToPayInAdvance;
    };

    setIsContinueToPaymentModalOpened = (isContinueToPaymentModalOpened) => {
        this.isContinueToPaymentModalOpened = isContinueToPaymentModalOpened;
    };

    clearIsContinueToPaymentModalOpened = () => {
        this.isContinueToPaymentModalOpened = false;
    };

    setRedirectPayUrlMessage = (redirectPayUrlMessage) => {
        this.redirectPayUrlMessage = redirectPayUrlMessage;
    };

    clearRedirectPayUrlMessage = () => {
        this.redirectPayUrlMessage = "";
    };
}

export default new CurrencyStore();
