import React from "react";
import { Input } from "antd";
import i18n from "../../core/i18n";
import BaseFormSchema from "../BaseFormSchema";
import ContactUsModalStore from "../../stores/Order/ContactUsModalStore";

const { TextArea } = Input;

export default class ContactUsFormSchema extends BaseFormSchema {
    constructor(form) {
        super(form);

        this.form = form;
        this.getFieldDecorator = form.getFieldDecorator;

        this.items = {
            ...this.items,

            note: {
                id: "note",
                label: i18n.t("Zpráva"),
                options: {},
                element: <TextArea rows="3" placeholder={i18n.t("Zpráva")} />,
            },
        };
    }

    onSubmit() {
        this.form.validateFields((err, values) => {
            if (!err) {
                ContactUsModalStore.sendEmail(values);
            }
        });
    }
}
