import React from "react";
import Link from "next/link";
import { Row, Col, Button, Space, Typography } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

const PageActionHeader = (props) => (
    <div className="page-heading my-2">
        <Row align="top" className="mb-1">
            <Col>
                <Space align="start">
                    {props.backUrl && (
                        <Link href={props.backUrl}>
                            <Button icon={<EnvironmentOutlined className="anticon" />} />
                        </Link>
                    )}
                    <div>
                        <Title level={5}>{props.title}</Title>
                        {props.subTitle && <Text type="secondary">{props.subTitle}</Text>}
                        {props.footer && props.footer}
                    </div>
                </Space>
            </Col>
            <Col flex="auto" style={{ textAlign: "right" }}>
                <Space>{props.extra}</Space>
            </Col>
        </Row>
        <Row>
            {React.Children.map(props.children, (child, index) => (
                <span key={index} className="page-actions_meta">
                    {child}
                </span>
            ))}
        </Row>
    </div>
);

export default PageActionHeader;
