import React, { Component } from "react";
import { AutoComplete, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import AutoCompleteLocationStore from "../../stores/AutoCompleteLocation/AutoCompleteLocationStore";
import RenderOptions from "./Options";
import NewOrderStore from "../../stores/Order/NewOrderStore";

// Debounce utility function
function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}

// Debounced search function
const debouncedSearch = debounce((value, isStart, sessionToken) => {
    AutoCompleteLocationStore.search(value, isStart, sessionToken);
}, 300);

class AutoCompleteLocation extends Component {
    constructor(props) {
        super(props);
        this.autoCompleteRef = React.createRef();
        this.state = {
            isUserInput: false,
            sessionToken: null,
        };
    }

    static propTypes = {
        placeholder: PropTypes.string,
        id: PropTypes.string,
        onSearch: PropTypes.func,
        onSelect: PropTypes.func,
        onFocus: PropTypes.func,
        isStart: PropTypes.bool.isRequired,
        defaultValue: PropTypes.string,
        value: PropTypes.string,
        ismapModal: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        placeholder: "",
        defaultValue: "",
        value: "",
        id: null,
        onSearch: () => {},
        onSelect: () => {},
        onFocus: () => {},
        ismapModal: false,
        disabled: false,
    };

    componentDidMount() {
        if (typeof window !== "undefined" && window.google) {
            const { AutocompleteSessionToken } = window.google.maps.places;
            this.setState({ sessionToken: new AutocompleteSessionToken() });
        }
    }

    handleSearch = (value) => {
        const { onSearch, isStart } = this.props;
        onSearch(value);
        if (this.state.isUserInput) {
            debouncedSearch(value, isStart, this.state.sessionToken);
        }
    };

    handleChange = (value) => {
        this.setState({ isUserInput: true });
        this.handleSearch(value);
    };

    handleFocus = () => {
        const { onFocus, value, isStart, defaultValue } = this.props;
        onFocus();
        if (!value) {
            AutoCompleteLocationStore.search(defaultValue, isStart, this.state.sessionToken);
        }
    };

    render() {
        const { id, isStart, placeholder, defaultValue, value, ismapModal, disabled } = this.props;

        if (value) {
            return (
                <AutoComplete
                    placeholder={placeholder}
                    allowClear
                    value={value}
                    disabled={disabled}
                    ref={this.autoCompleteRef}
                    onSelect={(selectValue) => {
                        const { onSelect } = this.props;
                        AutoCompleteLocationStore.finishcurrentLocation = false;
                        AutoCompleteLocationStore.startcurrentLocation = false;
                        if (selectValue === "startcurrentLocation" || selectValue === "finishcurrentLocation") {
                            if (selectValue === "startcurrentLocation") {
                                AutoCompleteLocationStore.startcurrentLocation = true;
                                NewOrderStore.loadCarCategoriesWithTimeout();
                            }
                            if (selectValue === "finishcurrentLocation") {
                                AutoCompleteLocationStore.finishcurrentLocation = true;
                                NewOrderStore.loadCarCategoriesWithTimeout();
                            }
                        } else if (selectValue === "choosemap") {
                            AutoCompleteLocationStore.isMapChoose = true;
                            AutoCompleteLocationStore.clearResults();
                        } else {
                            AutoCompleteLocationStore.googleResults.forEach((prediction) => {
                                if (
                                    selectValue.toLowerCase() ===
                                    `${prediction.structured_formatting.main_text} - ${prediction.structured_formatting.secondary_text}`.toLowerCase()
                                ) {
                                    AutoCompleteLocationStore.getPlaceDetail(prediction.place_id);
                                }
                            });
                        }
                        AutoCompleteLocationStore.clearResults();
                        onSelect(selectValue);
                    }}
                    options={RenderOptions(isStart, ismapModal)}
                    onSearch={this.handleSearch}
                    onChange={this.handleChange}
                    filterOption={false}
                    listHeight={400}
                    onFocus={this.handleFocus}
                >
                    <Input
                        className="search-input_autocomplete"
                        id={id}
                        autoComplete="off"
                        suffix={
                            <Button className="search-btn" type="link" disabled={disabled}>
                                <SearchOutlined />
                            </Button>
                        }
                    />
                </AutoComplete>
            );
        }

        // When value is not provided, use defaultValue
        return (
            <AutoComplete
                placeholder={placeholder}
                allowClear
                defaultValue={defaultValue}
                disabled={disabled}
                ref={this.autoCompleteRef}
                onSelect={(selectValue) => {
                    const { onSelect } = this.props;
                    AutoCompleteLocationStore.finishcurrentLocation = false;
                    AutoCompleteLocationStore.startcurrentLocation = false;
                    if (selectValue === "startcurrentLocation" || selectValue === "finishcurrentLocation") {
                        if (selectValue === "startcurrentLocation") {
                            AutoCompleteLocationStore.startcurrentLocation = true;
                            NewOrderStore.loadCarCategoriesWithTimeout();
                        }
                        if (selectValue === "finishcurrentLocation") {
                            AutoCompleteLocationStore.finishcurrentLocation = true;
                            NewOrderStore.loadCarCategoriesWithTimeout();
                        }
                    } else if (selectValue === "choosemap") {
                        AutoCompleteLocationStore.isMapChoose = true;
                        AutoCompleteLocationStore.clearResults();
                    } else {
                        AutoCompleteLocationStore.googleResults.forEach((prediction) => {
                            if (
                                selectValue.toLowerCase() ===
                                `${prediction.structured_formatting.main_text} - ${prediction.structured_formatting.secondary_text}`.toLowerCase()
                            ) {
                                AutoCompleteLocationStore.getPlaceDetail(prediction.place_id);
                            }
                        });
                    }
                    AutoCompleteLocationStore.clearResults();
                    onSelect(selectValue);
                }}
                options={RenderOptions(isStart, ismapModal)}
                onSearch={this.handleSearch}
                onChange={this.handleChange}
                filterOption={false}
                listHeight={400}
                onFocus={this.handleFocus}
            >
                <Input
                    className="search-input_autocomplete"
                    id={id}
                    autoComplete="off"
                    suffix={
                        <Button className="search-btn" type="link" disabled={disabled}>
                            <SearchOutlined />
                        </Button>
                    }
                />
            </AutoComplete>
        );
    }
}

export default observer(AutoCompleteLocation);
