import React, { Component } from "react";
import { HomeOutlined, UserOutlined, LogoutOutlined, ProfileOutlined, ContactsOutlined, UserAddOutlined } from "@ant-design/icons";
import { slide as Menu } from "react-burger-menu";
import { observer } from "mobx-react";
import { withRouter } from "next/router";
import { Link } from "../Router.tsx";
import UserStore from "../../stores/UserStore";
import i18n from "../../core/i18n";
import NewOrderStore from "../../stores/Order/NewOrderStore";
import LanguageStore from "../../stores/Language/LanguageStore";

class MainMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    handleStateChange = (state) => {
        this.setState({ isOpen: state.isOpen });
    };

    closeMenu = () => {
        this.setState({ isOpen: false });
    };

    render() {
        const { router } = this.props;
        const { direction } = LanguageStore.getLocaleAndDirection(router.query.lang);

        // Conditionally render burger menu only for LTR
        const isRTL = direction === "rtl";

        return (
            <>
                {/* Desktop Menu */}
                <div className="deskMenu">
                    <ul className="main-menu">
                        <li className="main-menu__item main-menu__item--home" key="home">
                            <a href={i18n.t("Domovská stránka - url")} target="_blank" className="main-menu__item-link">
                                <span className="main-menu__item-text">
                                    <HomeOutlined />
                                    <span className="main-menu__item-content">{i18n.t("Hlavní stránka")}</span>
                                </span>
                            </a>
                        </li>
                        <li className="main-menu__item main-menu__item--default" key="order">
                            <Link
                                route="index"
                                params={{ lang: router.query.lang }}
                                onClick={() => {
                                    NewOrderStore.reset();
                                }}
                                className="main-menu__item-link"
                            >
                                <span className="main-menu__item-text">
                                    <ProfileOutlined />
                                    <span className="main-menu__item-content">{i18n.t("Vytvořit objednávku")}</span>
                                </span>
                            </Link>
                        </li>
                        {UserStore.user && (
                            <>
                                <li className="main-menu__item main-menu__item--default" key="orders">
                                    <Link
                                        route="orders"
                                        params={{ lang: router.query.lang, path: "orders" }}
                                        onClick={() => {
                                            NewOrderStore.reset();
                                        }}
                                        className="main-menu__item-link"
                                    >
                                        <span className="main-menu__item-text">
                                            <UserOutlined />
                                            <span className="main-menu__item-content">{i18n.t("Profil")}</span>
                                        </span>
                                    </Link>
                                </li>
                                <li
                                    className="main-menu__item main-menu__item--mobile main-menu__item--logout main-menu__item--pull"
                                    key="logout"
                                >
                                    <Link
                                        route="login"
                                        params={{ lang: router.query.lang, path: "login" }}
                                        onClick={() => {
                                            NewOrderStore.reset();
                                            UserStore.logout(router);
                                        }}
                                        className="main-menu__item-link"
                                    >
                                        <span className="main-menu__item-text">
                                            <LogoutOutlined />
                                            <span className="main-menu__item-content">{i18n.t("Odhlásit se")}</span>
                                        </span>
                                    </Link>
                                </li>
                            </>
                        )}

                        {!UserStore.user && (
                            <>
                                <li className="main-menu__item main-menu__item--mobile main-menu__item--pull" key="login">
                                    <Link
                                        route="login"
                                        params={{ lang: router.query.lang, path: "login" }}
                                        onClick={() => {
                                            NewOrderStore.reset();
                                        }}
                                        className="main-menu__item-link"
                                    >
                                        <span className="main-menu__item-text">
                                            <LogoutOutlined />
                                            <span className="main-menu__item-content">{i18n.t("Přihlásit se")}</span>
                                        </span>
                                    </Link>
                                </li>
                            </>
                        )}
                    </ul>
                </div>

                {/* Burger Menu */}
                {!isRTL && (
                    <Menu
                        id="sidebar"
                        className="my-menu"
                        isOpen={this.state.isOpen}
                        onStateChange={this.handleStateChange}
                        right={true} // Always open from right for LTR
                    >
                        <ul className="main-menu">
                            <li className="main-menu__item main-menu__item--home" key="home">
                                <a href={i18n.t("Domovská stránka - url")} target="_blank" className="main-menu__item-link">
                                    <span className="main-menu__item-text">
                                        <HomeOutlined />
                                        <span className="main-menu__item-content">{i18n.t("Hlavní stránka")}</span>
                                    </span>
                                </a>
                            </li>
                            <li className="main-menu__item main-menu__item--contact" key="contact">
                                <a href={i18n.t("Kontaktujte nas - url")} target="_blank" className="main-menu__item-link">
                                    <span className="main-menu__item-text">
                                        <ContactsOutlined />
                                        <span className="main-menu__item-content">{i18n.t("Kontaktujte nás")}</span>
                                    </span>
                                </a>
                            </li>
                            <li className="main-menu__item main-menu__item--default" key="order">
                                <Link
                                    route="index"
                                    params={{ lang: router.query.lang }}
                                    onClick={() => {
                                        this.closeMenu();
                                        NewOrderStore.reset();
                                    }}
                                    className="main-menu__item-link"
                                >
                                    <span className="main-menu__item-text">
                                        <ProfileOutlined />
                                        <span className="main-menu__item-content">{i18n.t("Vytvořit objednávku")}</span>
                                    </span>
                                </Link>
                            </li>
                            {UserStore.user && (
                                <>
                                    <li className="main-menu__item main-menu__item--default" key="orders">
                                        <Link
                                            route="orders"
                                            params={{ lang: router.query.lang, path: "orders" }}
                                            onClick={() => {
                                                this.closeMenu();
                                                NewOrderStore.reset();
                                            }}
                                            className="main-menu__item-link"
                                        >
                                            <span className="main-menu__item-text">
                                                <UserOutlined />
                                                <span className="main-menu__item-content">{i18n.t("Profil")}</span>
                                            </span>
                                        </Link>
                                    </li>
                                    <li
                                        className="main-menu__item main-menu__item--mobile main-menu__item--logout main-menu__item--pull"
                                        key="logout"
                                    >
                                        <Link
                                            route="login"
                                            params={{ lang: router.query.lang, path: "login" }}
                                            onClick={() => {
                                                this.closeMenu();
                                                NewOrderStore.reset();
                                                UserStore.logout(router);
                                            }}
                                            className="main-menu__item-link"
                                        >
                                            <span className="main-menu__item-text">
                                                <LogoutOutlined />
                                                <span className="main-menu__item-content">{i18n.t("Odhlásit se")}</span>
                                            </span>
                                        </Link>
                                    </li>
                                </>
                            )}

                            {!UserStore.user && (
                                <>
                                    <li className="main-menu__item main-menu__item--mobile main-menu__item--pull" key="login">
                                        <Link
                                            route="login"
                                            params={{ lang: router.query.lang, path: "login" }}
                                            onClick={() => {
                                                this.closeMenu();
                                                NewOrderStore.reset();
                                            }}
                                            className="main-menu__item-link"
                                        >
                                            <span className="main-menu__item-text">
                                                <LogoutOutlined />
                                                <span className="main-menu__item-content">{i18n.t("Přihlásit se")}</span>
                                            </span>
                                        </Link>
                                    </li>
                                    {/* Registration Link */}
                                    <li className="main-menu__item main-menu__item--register" key="register">
                                        <Link
                                            route="register"
                                            params={{ lang: router.query.lang, path: "register" }}
                                            onClick={() => {
                                                this.closeMenu();
                                                NewOrderStore.reset();
                                            }}
                                            className="main-menu__item-link"
                                        >
                                            <span className="main-menu__item-text">
                                                <UserAddOutlined />
                                                <span className="main-menu__item-content">{i18n.t("Registrace")}</span>
                                            </span>
                                        </Link>
                                    </li>
                                </>
                            )}
                        </ul>
                    </Menu>
                )}
            </>
        );
    }
}

export default withRouter(observer(MainMenu));
