import NewTourOrder from "../../../../../stores/Tours/NewTourOrder";
import PayAllModalStore from "../../../../../stores/Payment/PayAllModalStore";
import UserStore from "../../../../../stores/UserStore";
import { CookieStorage } from "../../../../../models/CookieStorage";

const AllowedTourType = (item) => {
    const getcan_invoice = CookieStorage.read("can_invoice");
    if (item.id == 6) {
        if (UserStore.user && getcan_invoice && UserStore.user.can_invoice) {
            return true;
        } else {
            return false;
        }
    }
    //console.log(PayAllModalStore.canBePayed());
    let isZero = NewTourOrder.hasSelectedCarsZeroPrice();

    return true;
};

export default AllowedTourType;
