import { observable, makeObservable } from "mobx";
import moment from "moment-timezone";
import RootStore from "../RootStore";
import Router from "next/router";
import LanguageStore from "../Language/LanguageStore";
import NotificationStore from "../NotificationStore";
import i18n from "../../core/i18n";
import TourListStore from "./TourListStore";
import { pushRoute } from "../../components/Router.tsx";
import { message } from "antd";
import config from "../../config/config";
import TourOrderList from "./TourOrderList";

class NewTourOrder {
    tourEditId = false;
    isLoading = false;
    editisLoading = false;
    adultCount = 1;
    childrenCount = 0;
    seniorsAwardCount = 0;
    youngerCount = 0;
    paymentStatus = null;
    showAllPayments = false;
    checkAvailability = false;
    error = null;
    isSaving = false;
    prefixLength = null;
    paymentCurrency = "CZK";
    tourStep = 1;
    paymentType = 1; // CASH
    contactPhone = null;
    bookingDate = null;
    timeSlots = null;
    bookingDateTemp = null;
    contactTagName = null;
    billingInformationChecked = false;
    ishowMore = false;
    editLoad = null;
    noteForDriver = null;

    isModalOpened = false;
    bookingTime = null;

    conditionsApproved = true;

    tagNameParts = {
        firstName: "",
        lastName: "",
        greeting: i18n.t("Pan"),
        email: "",
        phone: "",
    };

    formValues = {
        customerData: {},
        bookingDate: false,
        bookingInfo: {},
        noteForDriver: null,
    };
    onCreate = null;
    tagName = null;

    constructor() {
        makeObservable(this, {
            tourEditId: observable,
            isLoading: observable,
            editisLoading: observable,
            adultCount: observable,
            childrenCount: observable,
            seniorsAwardCount: observable,
            youngerCount: observable,
            paymentStatus: observable,
            showAllPayments: observable,
            checkAvailability: observable,
            error: observable,
            isSaving: observable,
            prefixLength: observable,
            paymentCurrency: observable,
            tourStep: observable,
            paymentType: observable,
            contactPhone: observable,
            bookingDate: observable,
            timeSlots: observable,
            bookingDateTemp: observable,
            contactTagName: observable,
            billingInformationChecked: observable,
            ishowMore: observable,
            editLoad: observable,
            noteForDriver: observable,
            isModalOpened: observable,
            bookingTime: observable,
            conditionsApproved: observable,
            tagNameParts: observable,
            formValues: observable,
        });
    }

    setTagName(tagName) {
        this.tagName = tagName;
    }
    setCustomerData(customerData) {
        this.formValues.customerData = customerData;
    }
    setBookingInfo(bookingvalues) {
        this.formValues.bookingInfo = bookingvalues;
    }
    setbookingDate(bookingDate) {
        this.formValues.bookingDate = bookingDate;
    }
    setNoteForDriver(noteForDriver) {
        this.formValues.noteForDriver = noteForDriver;
    }
    setCreateCallback(cb) {
        this.onCreate = cb;
    }
    editLoad() {
        //console.log('current loade...');
    }

    clearCreateCallback() {
        this.onCreate = null;
    }
    create(data) {
        const locale = LanguageStore.getLocale(Router);
        if (data.customerData.phone && data.customerData.phone.length < 10) {
            data.customerData.phone = null;
        }
        data.customerData.language = locale;
        const CurrencyStoreCode = localStorage.getItem("CurrencyStoreCode") ? localStorage.getItem("CurrencyStoreCode") : "EUR";

        const dataTemp = {
            ...data,
            dataTemp: this.dataTemp,
            adultPrice: TourListStore.adultPrice,
            childrenPrice: TourListStore.childrenPrice,
            seniorsAwardPrice: TourListStore.seniorsAwardPrice,
            youngerPrice: TourListStore.youngerPrice,
            getTotalPrice: TourListStore.getTotalPrice(),
            language: locale,
            locale: locale,
            paymentType: this.paymentType,
            timeSlots: this.timeSlots,
            noteForDriver: this.noteForDriver,
            paymentCurrency: CurrencyStoreCode,
        };
        this.isSaving = true;
        RootStore.api
            .post("/tourOrders/", dataTemp)
            .then((response) => {
                if (this.onCreate) {
                    this.onCreate(response);
                    this.clearCreateCallback();
                }
                if (response.redirectPayUrl && response.redirectPayUrl !== "error") {
                    const url = `${window.location.origin}/${locale}/tourbookings/?redirectPayUrl=${response.redirectPayUrl}`;
                    window.location.replace(url);
                } else {
                    message.success(i18n.t("Vaše rezervace byla dokončena a bude potvrzena co nejdříve.", 3));
                    pushRoute(router, "tourbookings", { lang: router.query.lang, path: "tourbookings" }, { shallow: true });
                }

                //this.isSaving = false;
            })
            .catch((e) => {
                this.isSaving = false;
                NotificationStore.processException(e, "new-order");
            });
    }

    reset() {
        this.isLoading = false;
        this.adultCount = 1;
        this.childrenCount = 0;
        this.seniorsAwardCount = 0;
        this.youngerCount = 0;
        this.paymentStatus = null;
        this.showAllPayments = false;
        this.checkAvailability = false;
        this.error = null;
        this.isSaving = false;
        this.prefixLength = null;
        this.paymentCurrency = "CZK";
        this.tourStep = 1;
        this.paymentType = 1; // CASH
        this.contactPhone = null;
        this.bookingDate = null;
        this.contactTagName = null;
        this.billingInformationChecked = false;
        this.ishowMore = false;
        this.conditionsApproved = false;
        TourListStore.adultCount = 0;
        TourListStore.childrenCount = 0;
        TourListStore.seniorsAwardCount = 0;
        TourListStore.youngerCount = 0;
        TourListStore.tour = null;
        TourListStore.isDropDownSelected = false;
        this.tourEditId = false;
        this.bookingDateTemp = null;
    }

    TourNewBooking(values) {
        console.log("Booking Values", values);
    }
    hasSelectedCarsZeroPrice() {
        return false;
    }
    scrollToMyRef() {
        window.scrollTo(0, 1300);
    }

    editTour(ItemData) {
        this.reset();
        this.tourEditId = ItemData.id ? ItemData.id : false;
        TourListStore.adultCount = ItemData.adultCount > 0 ? ItemData.adultCount : 0;
        TourListStore.childrenCount = ItemData.childrenCount > 0 ? ItemData.childrenCount : 0;
        TourListStore.seniorsAwardCount = ItemData.seniorCount > 0 ? ItemData.seniorCount : 0;
        TourListStore.youngerCount = ItemData.infantCount > 0 ? ItemData.infantCount : 0;
        TourListStore.tour = ItemData.tour ? ItemData.tour : null;
        TourListStore.calculatePrice();
        this.bookingDate = moment(ItemData.bookingDate);
        this.setCustomerData(ItemData.user);
        this.tagNameParts.firstName = ItemData.user.firstName ? ItemData.user.firstName : "";
        this.tagNameParts.lastName = ItemData.user.lastName ? ItemData.user.lastName : "";
        this.tagNameParts.email = ItemData.user.email ? ItemData.user.email : "";
        this.tagNameParts.phone = ItemData.user.phone ? ItemData.user.phone : "";
        this.isModalOpened = true;
        this.bookingDateTemp = this.bookingDate.format(config.webDateTimeFormat);
        this.bookingTime = ItemData.bookingTime;
        this.setNoteForDriver(ItemData.noteForDriver);
    }
    editBooking(data) {
        if (!this.tourEditId) {
            return "";
        }
        const locale = LanguageStore.getLocale(Router);
        if (data.customerData.phone && data.customerData.phone.length < 10) {
            data.customerData.phone = null;
        }
        data.customerData.language = locale;
        data.tourEditId = this.tourEditId;

        this.editisLoading = true;

        data.bookingDate = this.bookingDate ? moment(this.bookingDate).format(config.apiDateTimeFormat) : null;
        data.bookingTime = this.bookingTime || null;
        const dataTemp = {
            ...data,
            dataTemp: this.dataTemp,
            adultPrice: TourListStore.adultPrice,
            childrenPrice: TourListStore.childrenPrice,
            seniorsAwardPrice: TourListStore.seniorsAwardPrice,
            youngerPrice: TourListStore.youngerPrice,
            getTotalPrice: TourListStore.getTotalPrice(),
            language: locale,
            locale: locale,
            noteForDriver: this.noteForDriver,
        };
        this.isSaving = true;

        RootStore.api
            .put(`/tourOrders/${this.tourEditId}/edit/`, dataTemp)
            .then((response) => {
                message.success(i18n.t("Objednávka byla úspěšně upravena", 3));
                pushRoute(router, "tourbookings", { lang: router.query.lang, path: "tourbookings" }, { shallow: true });
                this.isModalOpened = false;
                TourOrderList.load(true);
                this.editisLoading = false;
            })
            .catch((e) => {
                this.isSaving = false;
                this.editisLoading = false;
                NotificationStore.processException(e, "new-order");
            });
    }

    close() {
        this.isModalOpened = false;
        this.reset();
    }
}

export default new NewTourOrder();
