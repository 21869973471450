import React from "react";

const StampIcon = (props) => (
    <span className="stamp-icon">
        <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
            <g clipRule="evenodd" fill="#212121" fillRule="evenodd">
                <path d="m11.8962 7.72006c.0371.09252.0717.18584.1038.27994h2c.5523 0 1 .44772 1 1v1s1 .0547 1 1-1 1-1 1v2c0 .5523-.4477 1-1 1h-12c-.55228 0-1-.4477-1-1v-2s-1-.0547-1-1 1-1 1-1v-1c0-.55228.44772-1 1-1h2c.03247-.09401.06756-.18732.10528-.27994.15514-.38093.38543-.67911.69086-.89455.30543-.21545.67066-.32395 1.09567-.32551.57693.55734 1.27991.83601 2.10893.83601.82903 0 1.53282-.27867 2.11136-.83601.4234 0 .7886.1085 1.0957.32551.307.217.5365.51518.6884.89455zm-9.8962 1.27994h12v5h-12zm1 1h10v1h-10zm9 2h-8v1h8z" />
                <path d="m5.84786 1.61548c-.26746.08719-.41224.37667-.32006.64245l.29299.8448h-.00519s-.25103.27061-.25103.36146c0 .03503.18253.11741.43949.20681-.00116.09699.00094.19682.00309.2995.00191.09093.00387.18409.00365.2795-.00021.09285-.00242.18362-.00458.27231-.01241.51034-.02314.95162.35778 1.32253.44711.43537.98921.65375 1.62631.65515.63709.0014 1.17919-.21698 1.6263-.65515.39579-.38792.39019-.84963.38369-1.38367v-.00001c-.0008-.06917-.00166-.13956-.00166-.21116 0 0 0-.26944-.0308-.59514.24336-.08216.41336-.15702.41336-.19067 0-.09085-.1715-.36146-.1715-.36146h-.005l.2577-.80055c.0801-.24902-.0451-.51763-.2879-.61509-.4141-.16625-1.02752-.40984-1.37563-.53552-.55978-.202095-1.08841-.202095-1.60371 0-.31145.12216-.91696.32362-1.3473.46391zm1.8924 1.02962c-.08543-.06382-.20651-.06068-.28156.01507l-.39378.39741c-.13911.1404-.0112.38002.18356.34636.28592-.04941.58947-.09518.733-.09518.14847 0 .47291.04897.77216.1003.19314.03313.32051-.20279.18436-.34373l-.39359-.40744c-.07252-.07507-.19043-.08147-.27572-.02132-.09507.06705-.21258.13944-.27677.13944-.06148 0-.1653-.0664-.25166-.13091z" />
            </g>
        </svg>
    </span>
);

export default StampIcon;
