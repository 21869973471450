import React, { Component } from "react";
import { observer } from "mobx-react";
import { List } from "antd";
import i18n from "../../../../../../core/i18n";
import NewOrderStore from "../../../../../../stores/Order/NewOrderStore";
import CurrencyStore from "../../../../../../stores/Currency/CurrencyStore";
import getTotalPriceInForeignCurrency from "../../../../../../utils/newOrderStore/getTotalPriceInForeignCurrency";
import PriceAndRecapDiscountBothWays from "./PriceAndRecapDiscountBackRoute";
import PriceAndRecapDiscountWayThere from "./PriceAndRecapDiscountWayThere";
import getTotalPrice from "../../../../../../utils/newOrderStore/getTotalPrice";

class PriceAndRecapDiscount extends Component {
    render() {
        const ListItem = List.Item;

        const { totalPrice, extrasTotalPrice, extrasTotalPriceForeignCurrency } = getTotalPrice();

        const hasSelectedCarsZeroPrice = NewOrderStore.hasSelectedCarsZeroPrice();
        const showForeignCurrency =
            !hasSelectedCarsZeroPrice && CurrencyStore.selectedCurrency !== CurrencyStore.CZK && CurrencyStore.currencies;

        return (
            <div className="order-form-priceAndRecap FlipDiv">
                {NewOrderStore.step == 1 && <h2>{i18n.t("Cena a rekapitulace")}</h2>}
                <div className="order-form__card">
                    <div className="order-form__price">
                        <List itemLayout="horizontal">
                            {NewOrderStore.backRoute && (
                                <PriceAndRecapDiscountBothWays
                                    hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                                    showForeignCurrency={showForeignCurrency}
                                />
                            )}

                            {!NewOrderStore.backRoute && (
                                <PriceAndRecapDiscountWayThere
                                    hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                                    showForeignCurrency={showForeignCurrency}
                                />
                            )}

                            {!!(!hasSelectedCarsZeroPrice && extrasTotalPrice) && (
                                <ListItem>
                                    <div className="order-form__total">
                                        <p className="order-form__total-text">{i18n.t("Cena extras")}</p>
                                        <p className="order-form__total-price">
                                            {!showForeignCurrency && (
                                                <span>
                                                    {extrasTotalPrice}
                                                    &nbsp;
                                                    {CurrencyStore.CZK}
                                                </span>
                                            )}
                                            {!!(extrasTotalPrice && showForeignCurrency) && (
                                                <span>{`${extrasTotalPriceForeignCurrency} ${CurrencyStore.selectedCurrency}`}</span>
                                            )}
                                        </p>
                                    </div>
                                </ListItem>
                            )}
                            <ListItem>
                                <div className="order-form__total">
                                    <p className="order-form__total-text">{i18n.t("Cena celkem")}</p>
                                    <p className="order-form__total-price">
                                        {hasSelectedCarsZeroPrice && <span>{i18n.t("Bude určena později")}</span>}
                                        {!hasSelectedCarsZeroPrice && !showForeignCurrency && (
                                            <strong>
                                                {totalPrice}
                                                &nbsp;
                                                {CurrencyStore.CZK}
                                            </strong>
                                        )}
                                        {!!(totalPrice && showForeignCurrency) && (
                                            <strong>{getTotalPriceInForeignCurrency(totalPrice, CurrencyStore.selectedCurrency)}</strong>
                                        )}
                                    </p>
                                </div>
                            </ListItem>
                        </List>
                    </div>
                </div>
            </div>
        );
    }
}

export default observer(PriceAndRecapDiscount);
