import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { toJS, runInAction } from "mobx";
import { Col, Row, Button, Spin, Alert } from "antd";
import { WarningOutlined } from "@ant-design/icons"; // Import Warning icon
import NewOrderStore from "../../../../stores/Order/NewOrderStore";
import i18n from "../../../../core/i18n";
import UserStore from "../../../../stores/UserStore";
import { Gdpr } from "../orderStep1Page/form/Contact/gdpr";

const OrderFormSummaryFooter = observer(() => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [isTimeout, setIsTimeout] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    const handleConfirmClick = () => {
        runInAction(() => {
            NewOrderStore.isSaving = true; // Start loading
        });

        const hashv = window.location.hash;
        if (hashv) {
            if (hashv.indexOf("a_aid") !== -1) {
                NewOrderStore.a_aid = hashv.substring(7);
            }
        }
        runInAction(() => {
            NewOrderStore.isModalOpened = false;
        });
        const values = toJS(NewOrderStore.formValues);

        // Clear any existing error messages and timeouts
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        }
        setErrorMessage(null);
        setIsTimeout(false);

        // Set the create callback to handle both success and error
        NewOrderStore.setCreateCallback((response) => {
            if (response.tokens) {
                // **Success Handling**
                UserStore.storeTokenData(response.email, response.tokens.token, response.tokens.refresh_token, response.can_invoice);
                runInAction(() => {
                    NewOrderStore.isSaving = false;
                });
            } else if (response.error) {
                // **Error Handling**
                setErrorMessage("Nebylo možné dokončit objednávku. Zkuste to prosím znovu.");
                runInAction(() => {
                    NewOrderStore.isSaving = false;
                });

                // Initiate a 10-second timeout to reset the error message and re-enable the button
                const newTimeoutId = setTimeout(() => {
                    setErrorMessage(null);
                    setIsTimeout(false);
                    setTimeoutId(null);
                }, 15000); // 10 seconds

                setTimeoutId(newTimeoutId);
                setIsTimeout(true);
            } else {
                // **Unhandled Response**
                runInAction(() => {
                    NewOrderStore.isSaving = false;
                });
            }

            // Clear the callback after handling to prevent memory leaks
            NewOrderStore.clearCreateCallback();
        });

        // Initiate the booking creation
        NewOrderStore.create(values);
    };

    useEffect(() => {
        const handleRouteChange = () => {
            runInAction(() => {
                NewOrderStore.isSaving = false;
            });
        };

        window.addEventListener("beforeunload", handleRouteChange);

        return () => {
            window.removeEventListener("beforeunload", handleRouteChange);
            // Clear any remaining timeouts when component unmounts
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    return (
        <Row type="flex" align="middle" className="order-form__summary-footer">
            {!NewOrderStore.editOrder && (
                <Col sm={24} xs={24}>
                    <Gdpr />
                </Col>
            )}
            <Col sm={12} xs={24}>
                <Button
                    className="order-form__summary-footer-back"
                    onClick={() => {
                        NewOrderStore.preventRouteByUrlParameter = true;
                        NewOrderStore.step = 1;
                        NewOrderStore.isModalOpened = false;
                        NewOrderStore.clearDuplicateRouteIds();
                        NewOrderStore.setCheckDuplicateError(true);
                    }}
                    disabled={NewOrderStore.isSaving}
                >
                    {i18n.t("Zpět")}
                </Button>
            </Col>

            <Col sm={12} xs={24} className="text-align--right">
                <Spin spinning={NewOrderStore.isSaving}>
                    <Button
                        size="large"
                        type="primary"
                        className="order-form__summary-footer-confirm"
                        onClick={handleConfirmClick}
                        disabled={!NewOrderStore.conditionsApproved || NewOrderStore.isSaving || isTimeout} // Disable based on conditionsApproved, isSaving, or timeout
                    >
                        {i18n.t("Potvrdit a dokončit objednávku")}
                    </Button>
                </Spin>
            </Col>

            {/* Display Alert Message on Error */}
            {errorMessage && (
                <Col span={24} style={{ marginTop: "16px" }}>
                    <Alert
                        message={errorMessage}
                        type="error"
                        showIcon
                        icon={<WarningOutlined />}
                        closable
                        onClose={() => {
                            setErrorMessage(null);
                            if (timeoutId) {
                                clearTimeout(timeoutId);
                                setTimeoutId(null);
                            }
                            setIsTimeout(false);
                        }}
                    />
                </Col>
            )}
        </Row>
    );
});

export default OrderFormSummaryFooter;
