import React, { Component } from "react";
import { Card, Row, Col } from "antd";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import i18n from "../../../../core/i18n";
import RouteSummary from "./renderSummary/RouteSummary";
import NewOrderStore from "../../../../stores/Order/NewOrderStore";
import TravelerSummary from "./renderSummary/TravelerSummary";

class RenderSummary extends Component {
    static propTypes = {
        values: PropTypes.objectOf(PropTypes.any).isRequired,
        formItemLayout: PropTypes.objectOf(PropTypes.any).isRequired,
        index: PropTypes.number.isRequired,
        route: PropTypes.objectOf(PropTypes.any).isRequired,
    };

    render() {
        const { values, formItemLayout, index, route } = this.props;

        const RouteTitle = () => {
            if (index === 0 && !NewOrderStore.backRoute) {
                if (NewOrderStore.editOrder) {
                    return i18n.t("Editace objednávky");
                }
                return null;
            }
            if (index === 0) {
                return i18n.t("Cesta tam");
            }
            return i18n.t("Cesta zpět");
        };

        return (
            <div className={index === 1 ? "summary-headline" : "summary-headline2"}>
                <h3>
                    <RouteTitle index={index} />
                </h3>

                <Card className="order-form__card order-form__card--auto-width order-form__card--summary custom-card-summary" key={index}>
                    <Row gutter={45}>
                        <Col md={24}>
                            <RouteSummary route={route} formItemLayout={formItemLayout} index={index} />
                            <TravelerSummary values={values} formItemLayout={formItemLayout} />
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }
}

export default observer(RenderSummary);
