import { observable, action, makeObservable } from "mobx";
import RootStore from "../RootStore";
import NotificationStore from "../NotificationStore";
import ExtraDecorator from "./ExtraDecorator";
import NewOrderStore from "../Order/NewOrderStore";

class ExtraStore {
    isLoading = false;

    extras = [];

    categories = [];

    onLoad = null;

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            extras: observable,
            categories: observable,
            load: action,
        });
    }

    setCallback(cb) {
        this.onLoad = cb;
    }

    clearCallback() {
        this.onLoad = null;
    }

    load(force = false, orderRouteId = null) {
        if (force === false && this.extras.length > 0) {
            return;
        }

        this.extras = [];
        this.isLoading = true;
        NotificationStore.reset();

        const query = {
            ignorePagination: true,
            orderRouteId: NewOrderStore.editRouteId ? NewOrderStore.editRouteId : orderRouteId,
        };

        return RootStore.api
            .get("/extras/", query)
            .then((response) => {
                const extras = [];
                Object(response).forEach((extra) => {
                    extras.push(new ExtraDecorator(extra));
                });
                this.extras = extras;

                this.isLoading = false;
                if (this.onLoad) {
                    this.onLoad(this.extras);
                    this.clearCallback();
                }
            })
            .catch((e) => {
                this.isLoading = false;
                NotificationStore.processException(e, "extras");
            });
    }

    loadCategories() {
        this.isLoading = true;
        NotificationStore.reset();

        RootStore.api
            .get("/extra-categories/")
            .then((response) => {
                this.categories = response;

                this.isLoading = false;
            })
            .catch((e) => {
                this.isLoading = false;
                NotificationStore.processException(e, "extras");
            });
    }
}

export default new ExtraStore();
