import React, { Component } from "react";
import { Button, Select, Divider, Form } from "antd";
import { PlusOutlined, MinusOutlined, BulbOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import i18n from "../../../../../core/i18n";
import NewOrderStore from "../../../../../stores/Order/NewOrderStore";
import ExtraStore from "../../../../../stores/Extra/ExtraStore";
import ExtrasGrid from "./Extras/ExtrasGrid";

class Extras extends Component {
    static propTypes = {
        formValues: PropTypes.objectOf(PropTypes.any),
    };

    static defaultProps = {
        formValues: {},
    };

    state = {
        extras: false,
        selectedCategory: "0",
        isMobile: false, // State to manage screen size
        extrasLoaded: false,
    };

    async componentDidMount() {
        this.handleResize(); // Set initial screen size
        window.addEventListener("resize", this.handleResize); // Listen for window resize
    
        // Determine if extras should be loaded based on URL parameters or selected category
        const hasExtrasParameter = window.location.search.includes("&extras=");
        const hasSelectedCategory = NewOrderStore.selectedExtraCategory !== 0;
    
        if (hasExtrasParameter || hasSelectedCategory) {
            try {
                // First, load the categories
                await ExtraStore.loadCategories();
    
                // Then, load the extras
                await this.loadExtras();
    
                // Update the state with the selected category
                this.setState({
                    extras: true,
                    selectedCategory: NewOrderStore.selectedExtraCategory.toString(),
                });
    
                // Scroll to the extras category if applicable
                NewOrderStore.scrollToExtraCategory();
            } catch (error) {
                console.error("Error loading extras or categories:", error);
                // Optionally, handle the error (e.g., show a notification)
            }
        }
    }
    

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize); // Cleanup on unmount
    }

    handleResize = () => {
        // Check if the screen is mobile size (less than 768px wide)
        this.setState({ isMobile: window.innerWidth <= 768 });
    };

    toggleExtras = async () => {
        const { extras, extrasLoaded } = this.state;

        if (!extras && !extrasLoaded) {
            // Load extras only on the first click
            ExtraStore.loadCategories();
            await this.loadExtras();
            this.setState({ extrasLoaded: true });
        }

        this.setState({
            extras: !extras,
        });
    };

    async loadExtras() {
        await ExtraStore.load();

        if (ExtraStore.extras.length > 0) {
            this.transformSelectedExtras();
            NewOrderStore.setFilteredExtras(NewOrderStore.selectedExtraCategory);
        }
    }

    transformSelectedExtras() {
        const { formValues } = this.props;
        const { extraOrderRoutes } = formValues.routes[0];

        if (extraOrderRoutes) {
            formValues.routes[0].extraOrderRoutes.forEach((extraOrderRoute) => {
                const extra = ExtraStore.extras.find((e) => e.id === extraOrderRoute.extra.id);

                NewOrderStore.selectedExtras[NewOrderStore.getRouteWay()].push(
                    NewOrderStore.transformExtraToFilteredExtra(extra, extraOrderRoute.amount)
                );
            });
        }
    }

    handleCategoryChange = (categoryId) => {
        this.setState({ selectedCategory: categoryId });
        NewOrderStore.setFilteredExtras(categoryId);
    };

    render() {
        const { Option } = Select;
        const { extras, selectedCategory, isMobile } = this.state;

        const formItemFlightLayout = {
            colon: false,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 7 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 17 },
            },
        };

        const extraCategoriesOptions = ExtraStore.categories.map((category) => (
            <Option key={category.id}>{i18n.exists(category.name.key) ? i18n.t(category.name.key) : i18n.t(category.name.cz)}</Option>
        ));

        const selectedExtras = NewOrderStore.selectedExtras[NewOrderStore.getRouteWay()];

        // Determine the button label based on whether there are existing selected extras
        const buttonLabel = selectedExtras.length > 0 ? i18n.t("Zobrazit") : i18n.t("Přidat");

        return (
            <div className="order-form-extra FlipDiv">
                <div id="extras-section" className="order-form__extras">
                    <h2 className="order-form__extras-title">
                        {i18n.t("Doplňkové služby/extras")}
                        <span className="order-form__extras-button">
                            <Button type="ghost" style={{
                                    backgroundColor: "#ebe7e7",
                                    marginLeft: 10,
                                    border: "1px solid rgba(0, 0, 0, 0.25)", 
                                }}  
                                onClick={this.toggleExtras}>
                                {extras ? (
                                    <span className="labelText">
                                        <MinusOutlined style={{ marginRight: 6 }} />
                                        <span className="extra-label">{i18n.t("Skrýt extras")}</span>
                                    </span>
                                ) : (
                                    <span className="labelText">
                                        <PlusOutlined style={{ marginRight: 6 }} />
                                        <span className="extra-label">{buttonLabel}</span>
                                    </span>
                                )}
                            </Button>
                        </span>
                    </h2>
                </div>
                {extras ? (
                    <>
                    <span>
                        {NewOrderStore.backRoute && !NewOrderStore.backRouteStep ? (
                            <p style={{ fontSize: "13px" }}>
                                <BulbOutlined style={{ marginRight: 8, color: "#005093" }} />
                                {i18n.t("Extras neplatí pro zpáteční cestu.")} {i18n.t("Popis extras")}
                            </p>
                        ) : (
                            <p style={{ fontSize: "13px" }}>
                                <BulbOutlined style={{ marginRight: 8, color: "#005093" }} />
                                {i18n.t("Popis extras")}
                            </p>
                        )}

                        {selectedExtras.length > 0 && (
                            <React.Fragment>
                                <Divider orientation="left" style={{ color: "#005093" }}>
                                    {i18n.t("Obsah nákupního košíku")}
                                </Divider>
                                <ExtrasGrid extras={selectedExtras} readOnly />
                            </React.Fragment>
                        )}

                        <Divider orientation="left" style={{ color: "#005093" }}>
                            {i18n.t("Přidat položku")}
                        </Divider>

                        <Form.Item
                            {...formItemFlightLayout}
                            label={i18n.t("Kategorie")}
                            style={{ fontWeight: 500, color: "#005093", fontSize: "16px", marginBottom: 20 }}
                        >
                            <Select value={selectedCategory} style={{ width: "100%" }} onChange={this.handleCategoryChange}>
                                {selectedCategory === "0" && <Option value="0">-- {i18n.t("Vybrat")} --</Option>}
                                {extraCategoriesOptions}
                            </Select>
                        </Form.Item>

                        {selectedCategory === "0" && <p style={{ color: "red" }}>{i18n.t("Vyberte kategorii")}</p>}

                        {NewOrderStore.filteredExtras.length > 0 ? (
                            <ExtrasGrid extras={NewOrderStore.filteredExtras} readOnly={false} />
                        ) : (
                            selectedCategory !== "0" && (
                                <p style={{ color: "red" }}>{i18n.t("Items not found. Please try to select a different vehicle.")}</p>
                            )
                        )}
                    </span>
                    <Divider />
                    </>
                ) : (
                    <Divider />
                )}
            </div>
        );
    }
}

export default observer(Extras);
