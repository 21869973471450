import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: "en",
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safeguards from XSS
        },
        debug: false,
        react: {
            useSuspense: false, // or false based on your app's needs
        },
    });

export default i18n;
