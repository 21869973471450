import getConfig from "next/config";
import Router from "next/router";
import fetch from "node-fetch";
import https from "https";
import UserStore from "../stores/UserStore";
import { CookieStorage } from "../models/CookieStorage";

// Define the Api class
export default class Api {
    constructor() {
        this.config = getConfig().publicRuntimeConfig;
    }

    /**
     * Performs a GET request to the specified endpoint with optional query parameters.
     * @param {string} endpoint - The API endpoint.
     * @param {object} queryParams - An object representing query parameters.
     * @returns {Promise<object>} - The JSON response from the API.
     */
    async get(endpoint, queryParams = {}) {
        const agent = new https.Agent({
            rejectUnauthorized: false,
        });

        const ops = {
            method: "GET",
            headers: this.getHeaders(),
        };

        // Include HTTPS agent only if the API URL is HTTPS
        if (this.config.apiUrl.startsWith("https")) {
            ops.agent = agent;
        }

        // Construct the full URL with query parameters if any
        let url = `${this.config.apiUrlPath}${endpoint}`;
        if (Object.keys(queryParams).length > 0) {
            url += (url.includes("?") ? "&" : "?") + this.queryParams(queryParams);
        }

        return this.fetchJson(url, ops);
    }

    /**
     * Performs a POST request to the specified endpoint with provided data.
     * @param {string} endpoint - The API endpoint.
     * @param {object} data - The data to send in the request body.
     * @returns {Promise<object>} - The JSON response from the API.
     */
    async post(endpoint, data = {}) {
        const ops = {
            method: "POST",
            headers: this.getHeaders(data),
            body: this.getData(data),
        };

        return this.fetchJson(`${this.config.apiUrlPath}${endpoint}`, ops);
    }

    /**
     * Renews the authentication token by making a POST request.
     * @param {string} endpoint - The API endpoint.
     * @param {object} data - The data to send in the request body.
     * @returns {Promise<object>} - The JSON response from the API.
     */
    async renewToken(endpoint, data = {}) {
        const ops = {
            method: "POST",
            headers: this.getHeaders(data),
            body: this.getData(data),
        };

        return this.fetchJson(`/api${endpoint}`, ops);
    }

    /**
     * Logs in the user by making a POST request.
     * @param {string} endpoint - The API endpoint.
     * @param {object} data - The data to send in the request body.
     * @returns {Promise<object>} - The JSON response from the API.
     */
    async login(endpoint, data = {}) {
        const ops = {
            method: "POST",
            headers: this.getHeaders(data),
            body: this.getData(data),
        };

        return this.fetchJson(`/api${endpoint}`, ops);
    }

    /**
     * Logs out the user by making a GET request.
     * @param {string} endpoint - The API endpoint.
     * @param {object} data - (Optional) Additional data if needed.
     * @returns {Promise<object>} - The JSON response from the API.
     */
    async logout(endpoint, data = {}) {
        const ops = {
            method: "GET",
            headers: this.getHeaders(data),
        };

        return this.fetchJson(`/api${endpoint}`, ops);
    }

    /**
     * Performs a PUT request to the specified endpoint with provided data.
     * @param {string} endpoint - The API endpoint.
     * @param {object} data - The data to send in the request body.
     * @returns {Promise<object>} - The JSON response from the API.
     */
    async put(endpoint, data = {}) {
        const ops = {
            method: "PUT",
            headers: this.getHeaders(data),
            body: this.getData(data),
        };

        return this.fetchJson(`${this.config.apiUrlPath}${endpoint}`, ops);
    }

    /**
     * Performs a DELETE request to the specified endpoint.
     * @param {string} endpoint - The API endpoint.
     * @returns {Promise<object>} - The JSON response from the API.
     */
    async remove(endpoint) {
        const ops = {
            method: "DELETE",
            headers: this.getHeaders(),
        };
        return this.fetchJson(`${this.config.apiUrlPath}${endpoint}`, ops);
    }

    /**
     * Fetches JSON data from the specified URL with given options.
     * Handles token expiration and redirects to logout if necessary.
     * @param {string} endpoint - The API endpoint.
     * @param {object} options - Fetch options.
     * @returns {Promise<object>} - The JSON response from the API.
     */
    async fetchJson(endpoint, options = {}) {
        let url = `${this.config.apiUrl}${endpoint}`;

        // Redirect to test API if running in Docker and specific endpoints are hit
        if (
            this.config.runInDocker === true &&
            (endpoint.includes("/api/booking/init") || endpoint.includes("/api/booking/currencies/"))
        ) {
            url = `http://api-test.prague-airport-transfers.co.uk${endpoint}`;
        }

        try {
            const response = await fetch(url, options);
            const json = await response.json();

            if (response.status === 200) {
                return json;
            } else {
                // Handle JWT token expiration or invalidation
                if (
                    json.message === "Expired JWT Token" ||
                    json.message === "Invalid JWT Token" ||
                    json.message === "JWT Token not found"
                ) {
                    UserStore.logout(Router, true);
                    return;
                }
                // Throw the error message for further handling
                throw json;
            }
        } catch (error) {
            // Re-throw the error for the calling function to handle
            throw error;
        }
    }

    /**
     * Constructs headers for the HTTP request.
     * Includes Authorization token if available and sets Content-Type.
     * @param {object} data - The data to be sent in the request.
     * @returns {object} - The headers object.
     */
    getHeaders(data) {
        const headers = {};

        const token = CookieStorage.read("token");
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }

        // If data is FormData and running in the browser, omit Content-Type for multipart/form-data
        if (typeof window !== "undefined" && data instanceof FormData) {
            return headers;
        }

        headers["Content-Type"] = "application/json";
        return headers;
    }

    /**
     * Prepares the request body data.
     * If data is FormData, returns it as is; otherwise, stringifies the JSON.
     * @param {object} data - The data to be sent in the request.
     * @returns {FormData|string} - The processed data for the request body.
     */
    getData(data) {
        if (data instanceof FormData) {
            return data;
        }

        return JSON.stringify(data);
    }

    /**
     * Converts an object of query parameters into a URL-encoded string.
     * @param {object} params - The query parameters.
     * @returns {string} - The URL-encoded query string.
     */
    queryParams(params) {
        return Object.keys(params)
            .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
            .join("&");
    }
}
