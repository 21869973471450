import React, { Component } from "react";
import { observer } from "mobx-react";
import { List } from "antd";
import i18n from "../../../../../../core/i18n";
import NewOrderStore from "../../../../../../stores/Order/NewOrderStore";
import getAmountInForeignCurrency from "../../../../../../utils/newOrderStore/getAmountInForeignCurrency";
import CurrencyStore from "../../../../../../stores/Currency/CurrencyStore";
import getTotalPrice from "../../../../../../utils/newOrderStore/getTotalPrice";
import getTotalPriceInForeignCurrency from "../../../../../../utils/newOrderStore/getTotalPriceInForeignCurrency";

class PriceAndRecap extends Component {
    render() {
        const ListItem = List.Item;

        const { priceCarsEnd, priceCarsStart, totalPrice, extrasTotalPrice, extrasTotalPriceForeignCurrency } = getTotalPrice();

        const hasSelectedCarsZeroPrice = NewOrderStore.hasSelectedCarsZeroPrice();
        const showForeignCurrency =
            !hasSelectedCarsZeroPrice && CurrencyStore.selectedCurrency !== CurrencyStore.CZK && CurrencyStore.currencies;

        const priceForRouteText = () => {
            if (NewOrderStore.mode === "hour-rental") {
                return i18n.t("Cena pronájmu vozidla");
            } else if (NewOrderStore.mode === "excursion") {
                return i18n.t("Cena exkurze");
            } else {
                return i18n.t("Cena přepravy");
            }
        };

        return (
            <div className="order-form-priceAndRecap FlipDiv">
                {NewOrderStore.step == 1 && <h2>{i18n.t("Cena a rekapitulace")}</h2>}
                <div className="order-form__card">
                    <div className="order-form__price">
                        <List itemLayout="horizontal">
                            {NewOrderStore.mode !== "excursion" && NewOrderStore.mode !== "hour-rental" && NewOrderStore.backRoute && (
                                <React.Fragment>
                                    <ListItem>
                                        <div className="order-form__total">
                                            <p className="order-form__total-text">{i18n.t("Cena tam")}</p>
                                            <p className="order-form__total-price">
                                                {hasSelectedCarsZeroPrice && <span>{i18n.t("Bude určena později")}</span>}
                                                {!hasSelectedCarsZeroPrice && !showForeignCurrency && (
                                                    <span>
                                                        {priceCarsStart}
                                                        &nbsp;
                                                        {CurrencyStore.CZK}
                                                    </span>
                                                )}
                                                {!!(priceCarsStart && showForeignCurrency) && (
                                                    <span>
                                                        {getAmountInForeignCurrency(priceCarsStart, CurrencyStore.selectedCurrency)}
                                                    </span>
                                                )}
                                            </p>
                                        </div>
                                    </ListItem>
                                    <ListItem>
                                        <div className="order-form__total">
                                            <p className="order-form__total-text">{i18n.t("Cena zpět")}</p>
                                            <p className="order-form__total-price">
                                                {hasSelectedCarsZeroPrice && <span>{i18n.t("Bude určena později")}</span>}
                                                {!hasSelectedCarsZeroPrice && !showForeignCurrency && (
                                                    <span>
                                                        {priceCarsEnd}
                                                        &nbsp;
                                                        {CurrencyStore.CZK}
                                                    </span>
                                                )}
                                                {!!(priceCarsEnd && showForeignCurrency) && (
                                                    <span>{getAmountInForeignCurrency(priceCarsEnd, CurrencyStore.selectedCurrency)}</span>
                                                )}
                                            </p>
                                        </div>
                                    </ListItem>
                                </React.Fragment>
                            )}

                            {!NewOrderStore.backRoute && (
                                <ListItem>
                                    <div className="order-form__total">
                                        <p className="order-form__total-text">{priceForRouteText()}</p>
                                        <p className="order-form__total-price">
                                            {hasSelectedCarsZeroPrice && <span>{i18n.t("Bude určena později")}</span>}
                                            {!hasSelectedCarsZeroPrice && !showForeignCurrency && (
                                                <span>
                                                    {priceCarsStart}
                                                    &nbsp;
                                                    {CurrencyStore.CZK}
                                                </span>
                                            )}
                                            {!!(
                                                showForeignCurrency &&
                                                getAmountInForeignCurrency(priceCarsStart, CurrencyStore.selectedCurrency)
                                            ) && <span>{getAmountInForeignCurrency(priceCarsStart, CurrencyStore.selectedCurrency)}</span>}
                                        </p>
                                    </div>
                                </ListItem>
                            )}
                            {!!(!hasSelectedCarsZeroPrice && extrasTotalPrice) && (
                                <ListItem>
                                    <div className="order-form__total">
                                        <p className="order-form__total-text">{i18n.t("Cena extras")}</p>
                                        <p className="order-form__total-price">
                                            {!showForeignCurrency && (
                                                <span>
                                                    {extrasTotalPrice}
                                                    &nbsp;
                                                    {CurrencyStore.CZK}
                                                </span>
                                            )}
                                            {!!(extrasTotalPrice && showForeignCurrency) && (
                                                <span>{`${extrasTotalPriceForeignCurrency} ${CurrencyStore.selectedCurrency}`}</span>
                                            )}
                                        </p>
                                    </div>
                                </ListItem>
                            )}
                            <ListItem>
                                <div className="order-form__total">
                                    <p className="order-form__total-text">{i18n.t("Cena celkem")}</p>
                                    <p className="order-form__total-price">
                                        {hasSelectedCarsZeroPrice && <span>{i18n.t("Bude určena později")}</span>}
                                        {!hasSelectedCarsZeroPrice && !showForeignCurrency && (
                                            <strong>
                                                {totalPrice}
                                                &nbsp;
                                                {CurrencyStore.CZK}
                                            </strong>
                                        )}
                                        {!!(totalPrice && showForeignCurrency) && (
                                            <strong>{getTotalPriceInForeignCurrency(totalPrice, CurrencyStore.selectedCurrency)}</strong>
                                        )}
                                    </p>
                                </div>
                            </ListItem>
                        </List>
                    </div>
                </div>
            </div>
        );
    }
}

export default observer(PriceAndRecap);
