// Router.tsx
import React from "react";
import NextLink from "next/link";
import { useRouter as useNextRouter } from "next/router";
import LanguageStore from "../stores/Language/LanguageStore";
//import translations from "../../www/translations";

/**
 * Define all route names and their corresponding paths.
 * Ensure that dynamic routes include placeholders like [slug], [token], etc.
 */
const routeDefinitions = {
    index: { path: "/[lang]" },
    "hour-rental": { path: "/[lang]/hour-rental" },
    excursion: { path: "/[lang]/excursion" },
    "page-new-password": { path: "/[lang]/new-password" },
    "new-password/[token]": { path: "/[lang]/new-password/[token]" },
    orders: { path: "/[lang]/orders" },
    partner: { path: "/[lang]/partner" },
    tours: { path: "/[lang]/tours" },
    "tours/[slug]": { path: "/[lang]/tours/[slug]" },
    tourbookings: { path: "/[lang]/tourbookings" },
    "stripePayment/[orderId]/[orderType]": { path: "/[lang]/stripePayment/[orderId]/[orderType]" },
    // Add any additional routes here
};

/**
 * Helper function to replace dynamic segments in the path with actual params
 */
function replaceDynamicSegments(path, params) {
    return path.replace(/\[([^\]]+)\]/g, (match, p1) => {
        if (params[p1]) {
            return encodeURIComponent(params[p1]);
        }
        console.warn(`Missing parameter '${p1}' for path '${path}'`);
        return match; // Keep the placeholder if param is missing
    });
}

/**
 * Translate path by setting default language and trimming inputs
 */
function translatePath(params) {
    const newParams = { ...params };
    newParams.lang = newParams.lang?.trim() || LanguageStore.lang || "en"; // Trim and set default language
    newParams.path = newParams.path?.trim(); // Trim any unintended spaces or slashes
    return newParams;
}

/**
 * Build the final URL from routeName + params.
 */
function getRouteUrl(routeName, params = {}) {
    const def = routeDefinitions[routeName];
    const { lang, path } = translatePath(params);

    if (!def && routeName !== "index") {
        // Fallback: assume /[lang]/{routeName}
        return `/${lang}/${routeName}`.replace(/\/+/g, "/");
    }

    if (routeName === "index") {
        return `/${lang}`.replace(/\/+/g, "/");
    }

    let pathTemplate = def.path;

    // Replace dynamic segments with actual params
    pathTemplate = replaceDynamicSegments(pathTemplate, params);

    return pathTemplate.replace(/\/+/g, "/");
}

/**
 * Custom <Link> component
 * Usage: <Link route="hour-rental" params={{ lang: 'en' }}>...</Link>
 */
export function Link({ route, params = {}, children, ...props }) {
    const href = getRouteUrl(route, params);

    return (
        <NextLink href={href} {...props}>
            {children}
        </NextLink>
    );
}

/**
 * pushRoute: Programmatically navigate to a route
 * Usage: pushRoute(router, "hour-rental", { lang: 'en' })
 */
export function pushRoute(router, routeName, params = {}, options = {}) {
    if (!router || typeof router.push !== "function") {
        console.error("Invalid router instance passed to pushRoute:", router);
        return;
    }

    const href = getRouteUrl(routeName, params).replace(/\/+/g, "/");
    //console.log(`Navigating to: ${href}`); // Debugging log

    // Use Next.js router's push method
    router.push(href, href, options);
}

/**
 * dynamicRoutes: Generate URLs for dynamic routes
 * Usage: dynamicRoutes({ lang: 'en', path: 'some-path' })
 */
export function dynamicRoutes(params) {
    const { lang, path } = translatePath(params);
    return `/${lang}/${path || ""}`.replace(/\/+/g, "/");
}

/**
 * tourDynamicRoutes: Specifically for tours with slugs
 * Usage: tourDynamicRoutes({ lang: 'en', path: 'tour-slug' })
 */
export function tourDynamicRoutes({ lang, slug }) {
    const newParams = translatePath({ lang, slug });
    return replaceDynamicSegments("/[lang]/[slug]", newParams);
}

/**
 * windowLocation: Redirect the browser to a specific route
 * Usage: windowLocation("hour-rental", { lang: 'en' })
 */
export function windowLocation(routeName, params = {}, isNewslug = false) {
    const newParams = translatePath(params);
    let finalUrl = getRouteUrl(routeName, newParams);

    if (params?.slug) {
        finalUrl = finalUrl.replace("[slug]", encodeURIComponent(params.slug));
    }

    window.location.replace(finalUrl);
}
