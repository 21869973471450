import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "next/router";
import Logo from "./header/Logo";
import MainMenu from "./header/MainMenu";
import CurrencyMenu from "./header/CurrencyMenu";
import LanguagesMenu from "./header/LanguagesMenu";
import UserMenu from "./header/UserMenu";

class Header extends Component {
    componentDidMount() {
        window.addEventListener("scroll", this.resizeHeaderOnScroll);
    }

    resizeHeaderOnScroll = () => {
        const distanceY = window.pageYOffset || document.documentElement.scrollTop;
        const shrinkOn = 50;
        const headerEl = document.getElementById("js-header");

        if (headerEl && distanceY > shrinkOn) {
            headerEl.classList.add("header--off-canvas");
        }

        if (headerEl && distanceY === 0) {
            headerEl.classList.remove("header--off-canvas");
        }
    };

    componentWillUnmount() {
        window.removeEventListener("scroll", this.resizeHeaderOnScroll);
    }

    render() {
        return (
            <header className="header" id="js-header">
                <div className="container">
                    <div className="header__wrapper">
                        <Logo />

                        <div className="header__main-menu">
                            <MainMenu />
                        </div>

                        <div className="header__settings-menu">
                            <CurrencyMenu />

                            <LanguagesMenu />
                        </div>

                        <div className="header__user-menu">
                            <UserMenu />
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default withRouter(observer(Header));
