import { observable, makeObservable } from "mobx";

export default class PaymentTypeDecorator {
    id = null;

    name = null;

    title = null;

    gatewayTypeId = null;

    allowedCurrencies = [];

    inAdvance = false;

    inAdvanceForTour = false;

    inCar = false;

    selectedCurrencies = [];

    selectedCurrencyToPay = null;

    icons = [];

    help = null;

    constructor(data, paymentCurrency) {
        makeObservable(this, {
            name: observable,
            title: observable,
            gatewayTypeId: observable,
            allowedCurrencies: observable,
            inAdvance: observable,
            inAdvanceForTour: observable,
            inCar: observable,
            selectedCurrencies: observable,
            selectedCurrencyToPay: observable,
            icons: observable,
            help: observable,
        });

        if (data) {
            this.setAll(data, paymentCurrency);
        }
    }

    setAll(data) {
        this.id = data.id || null;
        this.name = data.name || null;
        this.title = data.title || null;
        this.gatewayTypeId = data.gatewayTypeId || null;
        this.allowedCurrencies = data.allowedCurrencies || [];
        this.inAdvance = data.inAdvance || false;
        this.inAdvanceForTour = data.inAdvanceForTour || false;
        this.inCar = data.inCar || false;
        this.selectedCurrencies = data.selectedCurrencies || [];
        this.selectedCurrencyToPay = data.selectedCurrencyToPay;
        this.icons = data.icons || [];
        this.help = data.help || null;
    }
}
