import React from "react";
import { List, Radio, Popover } from "antd";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import i18n from "../../core/i18n";
import CurrencyStore from "../../stores/Currency/CurrencyStore";
import AllowedCurrencies from "./PaymentList/GetAllowedCurrencies";
import isTypeAllowed from "../../utils/MethodOfPayment/isTypeAllowed";
import UserStore from "../../stores/UserStore";
import PaymentStore from "../../stores/Payment/PaymentStore";
import HelpButton from "../HelpButton";
import NewOrderStore from "../../stores/Order/NewOrderStore";
import OrdersListStore from "../../stores/Order/OrdersListStore";
import { CookieStorage } from "../../models/CookieStorage";

const RadioGroup = Radio.Group;
const ListItem = List.Item;

const getColor = (item) => {
    return isTypeAllowed(item) ? "#f9f9f9" : "#cac7c7";
};

const getCursor = (item) => {
    return isTypeAllowed(item) ? "pointer" : "not-allowed";
};

const paymentListOptions = ({
    data,
    value,
    defaultCurrency,
    onClick,
    showCount,
    showOnlyPayInAdvance,
    showPayLater,
    paymentStatus,
    calledFromModal,
}) => {
    const showBillingMethod = (item) =>
        item.name !== PaymentStore.TYPE_BILLING_NAME || (item.name === PaymentStore.TYPE_BILLING_NAME && !calledFromModal);

    const notAllowedTypes = [];
    const allowedTypesWithSelectedCurrency = [];
    const allowedTypes = [];

    data.forEach((originalType) => {
        const type = { ...originalType };
        if (value === originalType.id && originalType.allowedCurrencies.includes(defaultCurrency)) {
            type.selectedCurrencyToPay = defaultCurrency;
        }

        if (!isTypeAllowed(type)) {
            notAllowedTypes.push(type);
        } else if (
            isTypeAllowed(type) &&
            type.selectedCurrencies.some((selectedCurrency) => selectedCurrency.code === CurrencyStore.selectedCurrency)
        ) {
            const canInvoice = CookieStorage.read("can_invoice");
            if (type.id === PaymentStore.TYPE_BILLING && UserStore?.user?.can_invoice && canInvoice) {
                allowedTypesWithSelectedCurrency.unshift(type);
            } else {
                allowedTypesWithSelectedCurrency.push(type);
            }
        } else {
            allowedTypes.push(type);
        }
    });

    const dataTemp = [...allowedTypesWithSelectedCurrency, ...allowedTypes, ...notAllowedTypes];

    let lastIndex = 0;
    const hasSelectedCarsZeroPrice = NewOrderStore.hasSelectedCarsZeroPrice();

    return (
        <React.Fragment>
            {dataTemp
                .filter(
                    (item) =>
                        !paymentStatus ||
                        paymentStatus === OrdersListStore.PAYMENT_STATUS_NOT_PAID ||
                        (paymentStatus !== OrdersListStore.PAYMENT_STATUS_NOT_PAID && item.id === value)
                )
                .filter((item) => item.id !== PaymentStore.TYPE_PAY_LATER)
                .map((item, index) => {
                    if (index >= showCount || !showBillingMethod(item)) {
                        return null;
                    }

                    if (item.inAdvance || !showOnlyPayInAdvance) {
                        lastIndex = index;
                        return (
                            <ListItem
                                className="order-form__custom-car order-form__payment"
                                aria-disabled={!isTypeAllowed(item)}
                                style={
                                    item.id === value
                                        ? {
                                              boxShadow: "inset 0 0 0 2px #005093",
                                              backgroundColor: getColor(item),
                                              cursor: getCursor(item),
                                          }
                                        : {
                                              backgroundColor: getColor(item),
                                              cursor: getCursor(item),
                                          }
                                }
                                onClick={() => {
                                    CurrencyStore.selectCurrency(item.selectedCurrencyToPay);
                                    if (isTypeAllowed(item)) {
                                        onClick(item.id);
                                    }
                                }}
                                actions={[
                                    <AllowedCurrencies item={item} paymentStatus={paymentStatus} key={`allowed-currencies-${item.id}`} />,
                                ]}
                                key={item.id} // Use unique ID instead of index
                            >
                                <div className="order-form__payment-radio">
                                    <Radio disabled={!isTypeAllowed(item)} value={item.id || 3} />
                                </div>
                                <span className="order-form__payment-text">{i18n.t(item.title)}</span>
                                {item.icons && (
                                    <div className="order-form__payment-icons">
                                        {item.icons.map((icon) => (
                                            <svg width="25" height="25" key={icon.src}>
                                                <use xlinkHref={`${icon.src}#icon`} />
                                            </svg>
                                        ))}
                                    </div>
                                )}
                                <div className="order-form__payment-help">
                                    {item.id === PaymentStore.TYPE_BILLING && !UserStore.user ? (
                                        <Popover
                                            overlayClassName="order-form__popover"
                                            content={i18n.t(
                                                "Dostupné pouze pro schválené partnery po vašem přihlášení. Faktura bude vystavena zpětně po implementaci služby."
                                            )}
                                            title={false}
                                        >
                                            <HelpButton />
                                        </Popover>
                                    ) : (
                                        <Popover overlayClassName="order-form__popover" content={i18n.t(item.help)} title={false}>
                                            <HelpButton />
                                        </Popover>
                                    )}
                                </div>
                            </ListItem>
                        );
                    }
                    return null; // Explicitly return null if condition fails
                })}
            {showPayLater && showCount > lastIndex + 1 && (
                <ListItem
                    className="order-form__custom-car order-form__payment"
                    style={value === PaymentStore.TYPE_PAY_LATER ? { boxShadow: "inset 0 0 0 2px #005093" } : {}}
                    onClick={() => {
                        onClick(PaymentStore.TYPE_PAY_LATER);
                    }}
                    key={PaymentStore.TYPE_PAY_LATER}
                >
                    <div className="order-form__payment-radio">
                        <Radio value={PaymentStore.TYPE_PAY_LATER} />
                    </div>
                    <span className="order-form__payment-text">{i18n.t("Zaplatím později")}</span>
                    <div className="order-form__payment-icons">
                        <svg width="25" height="25" key="later">
                            <use xlinkHref="/img/paylater.svg#icon" />
                        </svg>
                    </div>
                    <div className="order-form__payment-help">
                        {hasSelectedCarsZeroPrice ? (
                            <Popover
                                overlayClassName="order-form__popover"
                                content={i18n.t(
                                    "Možnost úhrady bude umožněna, jakmile bude stanovena cena dispečinkem, a to nejpozději do 24 hodin. Případně nás kontaktujte telefonicky. Děkujeme."
                                )}
                                title={false}
                            >
                                <HelpButton />
                            </Popover>
                        ) : (
                            <Popover
                                overlayClassName="order-form__popover"
                                content={i18n.t(
                                    "Objednávka vyžaduje platbu předem, pokud nebude uhrazena den před termínem vyzvednutí dojde k jejímu stornování."
                                )}
                                title={false}
                            >
                                <HelpButton />
                            </Popover>
                        )}
                    </div>
                </ListItem>
            )}
        </React.Fragment>
    );
};

const PaymentsList = ({
    data,
    value = null,
    defaultCurrency,
    onClick,
    showCount = 3,
    showOnlyPayInAdvance = false,
    showPayLater,
    paymentStatus,
    calledFromModal = false,
}) => (
    <RadioGroup value={value} style={{ width: "100%" }}>
        <List itemLayout="horizontal" bordered>
            {paymentListOptions({
                data,
                value,
                defaultCurrency,
                onClick,
                showCount,
                showOnlyPayInAdvance,
                showPayLater,
                paymentStatus,
                calledFromModal,
            })}
        </List>
    </RadioGroup>
);

PaymentsList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    value: PropTypes.number,
    defaultCurrency: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    showCount: PropTypes.number,
    showOnlyPayInAdvance: PropTypes.bool,
    showPayLater: PropTypes.bool.isRequired,
    paymentStatus: PropTypes.number,
    calledFromModal: PropTypes.bool,
};

export default observer(PaymentsList);
