import { observable, makeObservable } from "mobx";

export default class CarCategoryDecorator {
    NAME_DICTIONARY_PREFIX = "carCategory_";

    DESCRIPTION_DICTIONARY_PREFIX = "carCategoryDescription_";

    id = null;

    carCount = 0;

    imgId = 0;

    isForRental = false;

    name = null;

    nameWithDictionaryPrefix = null;

    descriptionWithDictionaryPrefix = null;

    isDistributable = false;

    price = 0;

    priceLowCost = 0;

    priceWithVat = 0;

    priceWithoutVat = 0;

    thereDiscount = null;

    backDiscount = null;

    thereSurcharges = null;

    backSurcharges = null;

    thereDiscountMinDate = null;

    thereDiscountMaxDate = null;

    thereSurchargesMinDate = null;

    thereSurchargesMaxDate = null;

    backSurchargesMinDate = null;

    backSurchargesMaxDate = null;

    backDiscountMinDate = null;

    backDiscountMaxDate = null;

    payAdvance = false;

    isShowFirst = false;

    isreturnDiscount = false;

    discountPercentage = 0;

    zoneId = null;

    capacity = 0;

    luggageCapacity = null;

    refundCancellation = 0;

    catDiscountParcentage = 0;

    isShowFirst = false;
    

    constructor(data) {
        makeObservable(this, {
            id: observable,
            carCount: observable,
            imgId: observable,
            isForRental: observable,
            name: observable,
            nameWithDictionaryPrefix: observable,
            descriptionWithDictionaryPrefix: observable,
            isDistributable: observable,
            price: observable,
            priceLowCost: observable,
            priceWithVat: observable,
            priceWithoutVat: observable,
            thereDiscount: observable,
            backDiscount: observable,
            thereSurcharges: observable,
            backSurcharges: observable,
            thereDiscountMinDate: observable,
            thereDiscountMaxDate: observable,
            thereSurchargesMinDate: observable,
            thereSurchargesMaxDate: observable,
            backSurchargesMinDate: observable,
            backSurchargesMaxDate: observable,
            backDiscountMinDate: observable,
            backDiscountMaxDate: observable,
            payAdvance: observable,
            isShowFirst: observable,
            isreturnDiscount: observable,
            discountPercentage: observable,
            zoneId: observable,
            capacity: observable,
            luggageCapacity: observable,
            refundCancellation: observable,
            catDiscountParcentage: observable,
            isShowFirst: observable,
        });

        if (data) {
            this.setAll(data);
        }
    }

    setAll(data) {
        this.id = data.carCategoryId || null;
        this.payAdvance = data.payAdvance || false;
        this.imgId = data.carCategoryImgId || null;
        this.carCount = data.carCount || 0;
        this.isForRental = data.isForRental || 0;
        this.name = data.carCategoryName || false;
        this.description = data.carCategoryDescription || null;
        this.nameWithDictionaryPrefix = this.NAME_DICTIONARY_PREFIX + data.carCategoryName || false;
        this.isDistributable = data.isDistributable || false;
        this.price = data.price || 0;
        this.priceLowCost = data.priceLowCost || 0;
        this.priceWithVat = data.priceWithVat || 0;
        this.priceWithoutVat = data.priceWithoutVat || 0;
        this.zoneId = data.zoneId || null;
        this.capacity = data.capacity || 0;

        // surcharges
        this.thereSurcharges = data.thereSurcharges || 0;
        this.backSurcharges = data.backSurcharges || 0;
        this.thereSurchargesMinDate = data.thereSurchargesMinDate || null;
        this.thereSurchargesMaxDate = data.thereSurchargesMaxDate || null;
        this.backSurchargesMinDate = data.backSurchargesMinDate || null;
        this.backSurchargesMaxDate = data.backSurchargesMaxDate || null;

        // discount
        this.thereDiscount = data.thereDiscount || 0;
        this.backDiscount = data.backDiscount || 0;
        this.thereDiscountMinDate = data.thereDiscountMinDate || null;
        this.thereDiscountMaxDate = data.thereDiscountMaxDate || null;
        this.backDiscountMinDate = data.backDiscountMinDate || null;
        this.backDiscountMaxDate = data.backDiscountMaxDate || null;
        this.discountPercentage = data.discountPercentage || 0;

        this.luggageCapacity = data.luggageCapacity || 0;
        this.statusName = data.statusName || "";
        this.isNoBabySeat = data.isNoBabySeat || 0;
        this.isreturnDiscount = data.isreturnDiscount || false;

        this.refundCancellation = data.refundCancellation || 0;

        this.catDiscountParcentage = data.catDiscountParcentage || 0;


        this.isShowFirst = data.isShowFirst || false;

        this.descriptionWithDictionaryPrefix = `${this.DESCRIPTION_DICTIONARY_PREFIX}${this.name} ${this.description || "description"}`;
    }
}
