import locale from "locale";
import { DEFAULT_LANG } from "../stores/Language/LanguageStore";
import { CtxRedirect } from "./CtxRedirect";

export class LanguageDetector {
    static detect(ctx) {
        const defaultLang = DEFAULT_LANG;
        const urlLang = ctx.asPath ? ctx.asPath.substring(1, 3) : null;
        const routeLang = ctx.query.lang;

        let langKey = routeLang;
        if (!langKey) {
            langKey = urlLang;

            if (!langKey) {
                langKey = defaultLang;
                if (ctx.req) {
                    langKey = new locale.Locales(ctx.req.headers["accept-language"]).best().toString().substring(0, 2);
                }

                CtxRedirect.redirect(ctx, `/${langKey}/`);
            }
        }
        return langKey;
    }
}
