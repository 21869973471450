import React from "react";
import { Carousel } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";
import FileUploadStore from "../../../../../../stores/FileUploadStore";

const GetExtrasImg = ({ documents }) => {
    let img;
    if (documents && documents.length > 1) {
        img = (
            <Carousel autoplay>
                {documents.map((document) => (
                    <div key={document.id}>
                        <img src={FileUploadStore.getImgBaseUrl(document.id)} alt="" />
                    </div>
                ))}
            </Carousel>
        );
    } else {
        img =
            documents && documents.length > 0 ? (
                <img src={FileUploadStore.getImgBaseUrl(documents && documents.length > 0 ? documents[0].id : null)} alt="" />
            ) : (
                <ShoppingCartOutlined />
            );
    }

    return img;
};

export default GetExtrasImg;
