import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { Card, Button, Badge, message, Space } from "antd";
import { MinusOutlined, PlusOutlined, CloseCircleFilled } from "@ant-design/icons";
import i18n from "../../../../../../core/i18n";
import InputInt from "../../../../../form/InputInt";
import NewOrderStore from "../../../../../../stores/Order/NewOrderStore";
import GetExtrasImg from "./GetExtrasImg";
import RenderPrice from "../../RenderPrice";

class ExtrasItem extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        readOnly: PropTypes.bool.isRequired,
    };

    handleRemove = () => {
        NewOrderStore.deleteFromExtra(this.props.item);
        NewOrderStore.setIfRouteHasToBePaidInAdvance();
    };

    handleDecrease = () => {
        const { item } = this.props;
        item.amount -= 1;
        if (item.amount === 0) {
            NewOrderStore.deleteFromExtra(item);
            NewOrderStore.setIfRouteHasToBePaidInAdvance();
        } else {
            NewOrderStore.calculatePrice();
        }
    };

    handleIncrease = () => {
        const { item } = this.props;
        item.amount += 1;
        NewOrderStore.calculatePrice();
    };

    handleInputChange = (value) => {
        const { item } = this.props;
        NewOrderStore.extraAmounts[item.id] = parseInt(value, 10);
    };

    handleAddExtra = () => {
        const { item } = this.props;
        NewOrderStore.changeExtraAmount(item);
        NewOrderStore.setIfRouteHasToBePaidInAdvance();
        message.success(i18n.t("Extras bylo přidáno"), 3);
    };

    render() {
        const { item, readOnly } = this.props;

        const itemPrice = readOnly ? (
            <div className="extras-item__price extras-item__price--total">
                <div className="extras-item__price-label">{i18n.t("Celková cena")}:</div>
                {RenderPrice(item.amount, item.price)}
            </div>
        ) : (
            <div className="extras-item__price">
                <div className="extras-item__price-label">{i18n.t("Cena")}:</div>
                {RenderPrice(1, item.price)}
            </div>
        );

        return (
            <div className="extras-item__wrapper">
                {readOnly && (
                    <div
                        className="extras-item__remove"
                        onClick={this.handleRemove}
                        style={{ cursor: "pointer" }}
                        aria-label="Remove Extra"
                    >
                        <CloseCircleFilled />
                    </div>
                )}
                <Card
                    hoverable
                    className="extras-item"
                    cover={
                        <div className="extras-item__img">
                            <GetExtrasImg documents={item.img} />
                        </div>
                    }
                >
                    <Card.Meta title={item.item} description={itemPrice} />

                    {readOnly ? (
                        <div className="extras-item__counter">
                            <div className="extras-item__counter-badge">
                                <Badge count={`${item.amount}x`} />
                            </div>
                            <Button.Group style={{ width: "100%" }}>
                                <Button
                                    type="primary"
                                    style={{ width: "50%" }}
                                    icon={<MinusOutlined />}
                                    onClick={this.handleDecrease}
                                    aria-label="Decrease Amount"
                                />
                                <Button
                                    type="primary"
                                    style={{ width: "50%" }}
                                    icon={<PlusOutlined />}
                                    onClick={this.handleIncrease}
                                    aria-label="Increase Amount"
                                />
                            </Button.Group>
                        </div>
                    ) : (
                        <Space.Compact style={{ marginTop: 15, width: "100%" }}>
                            <InputInt
                                defaultValue={NewOrderStore.extraAmounts[item.id] || 1}
                                min={1}
                                style={{ width: "40%" }}
                                onChange={this.handleInputChange}
                                aria-label="Extra Amount"
                            />
                            <Button
                                style={{ width: "60%", justifyContent: "center", fontSize: 14 }}
                                type="primary"
                                onClick={this.handleAddExtra}
                            >
                                {i18n.t("Přidat")}
                            </Button>
                        </Space.Compact>
                    )}
                </Card>
            </div>
        );
    }
}

export default observer(ExtrasItem);
