import React, { Component } from "react";
import { Dropdown } from "antd";
import { observer } from "mobx-react";
import { DownOutlined } from "@ant-design/icons";
import { toJS } from "mobx";
import i18n from "../../core/i18n";
import CurrencyStore from "../../stores/Currency/CurrencyStore";
import getSymbolFromCurrency from "currency-symbol-map";

class CurrencyMenu extends Component {
    // Optional fallback (only if needed)
    customCurrencySymbols = {
        //CZK: 'Kč',
    };

    getCurrencySymbol = (code) => {
        // Combine library + fallback + code
        return getSymbolFromCurrency(code) || this.customCurrencySymbols[code] || code;
    };

    componentDidMount() {
        const CurrencyStoreCode = localStorage.getItem("CurrencyStoreCode");
        if (CurrencyStoreCode) {
            CurrencyStore.selectCurrency(CurrencyStoreCode);
            CurrencyStore.selectTourCurrency(CurrencyStoreCode);
        }
    }

    render() {
        // If `menuCurrencies` is an object, convert it to an array:
        let currencyList = [];
        if (CurrencyStore.menuCurrencies) {
            currencyList = Object.values(toJS(CurrencyStore.menuCurrencies));
        }

        // Create dropdown items: symbol + (code) with a lighter color for the code
        const items = currencyList.map((currency) => {
            const symbol = this.getCurrencySymbol(currency.key);
            return {
                key: currency.key,
                label: (
                    <span>
                        {symbol} <span style={{ color: "#999" }}>({currency.key})</span>
                    </span>
                ),
            };
        });

        const onClick = ({ key }) => {
            document.body.removeAttribute("style");
            CurrencyStore.selectCurrency(key);
            CurrencyStore.selectTourCurrency(key);
            localStorage.setItem("CurrencyStoreCode", key);
        };

        const { selectedCurrency } = CurrencyStore;
        const selectedSymbol = this.getCurrencySymbol(selectedCurrency);

        return (
            <div className="settings-menu settings-menu--currency">
                <span style={{ marginLeft: "10px", marginRight: "10px" }}>{i18n.t("Měna")}</span>
                <Dropdown menu={{ items, onClick }} trigger={["click"]}>
                    <a onClick={(e) => e.preventDefault()} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                        {selectedSymbol}
                        {/* Add a space before the parentheses */}
                        &nbsp;
                        <span style={{ color: "#999" }}>({selectedCurrency})</span>
                        &nbsp;
                        <DownOutlined />
                    </a>
                </Dropdown>
            </div>
        );
    }
}

export default observer(CurrencyMenu);
