import React, { Component } from "react";
import { Card, Row, Col, Form, Popover, Alert, Checkbox, Button } from "antd";
import { LikeOutlined, CloseOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { withRouter } from "next/router";
import UserStore from "../../../../../stores/UserStore";
import i18n from "../../../../../core/i18n";
import NewOrderStore from "../../../../../stores/Order/NewOrderStore";
import HelpButton from "../../../../HelpButton";
import LanguageStore from "../../../../../stores/Language/LanguageStore";
import UserListStore from "../../../../../stores/User/UserListStore";
import Notification from "../../../../Notification";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import react-phone-input-2 styles
import { parsePhoneNumberFromString } from "libphonenumber-js/max";

class Contact extends Component {
    static propTypes = {
        formValues: PropTypes.objectOf(PropTypes.any).isRequired,
        form: PropTypes.objectOf(PropTypes.any).isRequired,
    };

    constructor(props) {
        super(props);
        const { form } = this.props;
        this.getFieldDecorator = form.getFieldDecorator;

        // Initialize state
        this.state = {
            isNotMobile: false, // Flag to show/hide the alert for non-mobile numbers
            isPhoneValid: null, // null: untouched, true: valid, false: invalid
            phoneErrorMessage: "", // To store error messages
        };

        // Bind methods
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.debounceTimer = null;
    }

    handlePhoneChange(value, countryData) {
        // Clear existing debounce timer
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }

        // Set a new debounce timer
        this.debounceTimer = setTimeout(() => {
            this.validatePhoneNumber(value, countryData);
        }, 1200); // 1200ms debounce delay
    }

    validatePhoneNumber(value, countryData) {
        if (!value) {
            // Phone number is optional
            this.setState({
                isNotMobile: false,
                isPhoneValid: null,
                phoneErrorMessage: "",
            });
            return;
        }

        // Remove non-digit characters
        const digits = value.replace(/\D/g, "");

        // Define the minimum length for mobile numbers (adjust as needed)
        const MIN_MOBILE_LENGTH = 7;

        if (digits.length < MIN_MOBILE_LENGTH) {
            this.setState({
                isNotMobile: false,
                isPhoneValid: false,
                phoneErrorMessage: i18n.t("Please enter a complete phone number."),
            });
            return;
        }

        // Parse the phone number using libphonenumber-js with country code
        const phoneNumber = parsePhoneNumberFromString(`+${value}`, countryData?.countryCode);

        if (phoneNumber && phoneNumber.isValid()) {
            const type = phoneNumber.getType();

            // Check if the type is not MOBILE or FIXED_LINE_OR_MOBILE
            if (type !== "MOBILE" && type !== "FIXED_LINE_OR_MOBILE") {
                this.setState({
                    isNotMobile: true,
                    isPhoneValid: true, // It's valid but not mobile
                    phoneErrorMessage: "",
                });
            } else {
                this.setState({
                    isNotMobile: false,
                    isPhoneValid: true,
                    phoneErrorMessage: "",
                });
            }
        } else {
            // If the phone number is invalid
            this.setState({
                isNotMobile: false,
                isPhoneValid: false,
                phoneErrorMessage: i18n.t("Invalid phone number."),
            });
        }
    }

    componentWillUnmount() {
        // Clear debounce timer on unmount to prevent memory leaks
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }
    }

    render() {
        const formItemLayout = {
            colon: false,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 12 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 12 },
            },
        };

        const FormItem = Form.Item;
        const { form, formValues, router } = this.props;
        const { direction } = LanguageStore.getLocaleAndDirection(router.query.lang);
        const { billingInformationChecked } = NewOrderStore;
        const { firstName, lastName, greeting } = NewOrderStore.tagNameParts;
        const { contactPersonTagName } = NewOrderStore.formValues.routes[0];

        let tagName;
        if (contactPersonTagName) {
            tagName = contactPersonTagName;
        } else if (direction === "ltr") {
            tagName = `${i18n.t(greeting)} ${firstName || UserListStore.user?.firstName || ""} ${lastName || UserListStore.user?.lastName || ""}`;
        } else {
            tagName = `${i18n.t(greeting)} ${lastName || UserListStore.user?.lastName || ""} ${firstName || UserListStore.user?.firstName || ""}`;
        }

        let user = {};

        if (formValues.customerData.phone) {
            user = formValues.customerData;
        } else if (UserListStore.user.phone) {
            user = UserListStore.user;
        }

        let phone = null;
        let prefix = null;

        if (user && user.phone) {
            phone = UserStore.getPhoneNum(user.phone);
            prefix = UserStore.getPrefix(user.phone);
        }

        if (NewOrderStore.contactPhone !== null) {
            phone = UserStore.getPhoneNum(NewOrderStore.contactPhone);
            prefix = UserStore.getPrefix(NewOrderStore.contactPhone);
        }

        if (NewOrderStore.returnRouteMode) {
            return null;
        }

        const renderPhoneInput = () => (
            <FormItem
                className="ant-legacy-form-item phoneNumber"
                {...formItemLayout}
                label={
                    <span>
                        {i18n.t("Mobilní telefon")}
                        <Popover
                            placement="top"
                            overlayClassName="order-form__popover"
                            content={i18n.t("Doporučujeme uvést mobilní telefon, nikoliv pevnou linku")}
                        >
                            <HelpButton />
                        </Popover>
                    </span>
                }
                validateStatus={this.state.isPhoneValid === null ? "" : this.state.isPhoneValid ? "success" : "error"}
                help={this.state.isPhoneValid === false ? this.state.phoneErrorMessage : ""}
            >
                <div style={{ position: "relative" }}>
                    {this.getFieldDecorator(form.items["customerData[phone]"].id, {
                        initialValue: phone || LanguageStore.getPhonePrefix(router.query.lang),
                        // Use getValueFromEvent to handle value changes
                        getValueFromEvent: (value, countryData) => {
                            const fullNumber = `+${value}`;
                            // Update MobX store
                            NewOrderStore.contactPhone = fullNumber;
                            NewOrderStore.formValues.customerData.phone = fullNumber;
                            NewOrderStore.isPhoneaaded = fullNumber;

                            // Perform validation
                            this.handlePhoneChange(value, countryData);

                            // Return the value to be stored in the form
                            return value;
                        },
                    })(
                        <PhoneInput
                            country={LanguageStore.getPhonePrefix(router.query.lang)}
                            inputProps={{
                                name: "phone",
                                id: "phone",
                            }}
                            prefix="+"
                            searchPlaceholder={i18n.t("search")}
                            // Remove the value prop to let getFieldDecorator manage it
                            enableSearch
                            countryCodeEditable
                        />
                    )}
                    {/* Validation Icons */}
                    {this.state.isPhoneValid === true && (
                        <CheckCircleOutlined
                            style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                color: "green",
                            }}
                            aria-label="Valid phone number"
                            title="Valid phone number"
                        />
                    )}
                    {this.state.isPhoneValid === false && (
                        <ExclamationCircleOutlined
                            style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                color: "red",
                            }}
                            aria-label="Invalid phone number"
                            title="Invalid phone number"
                        />
                    )}
                </div>
            </FormItem>
        );

        const token = UserStore.token;

        if (!UserStore.user) {
            return (
                <div className="order-form-contact">
                    <div>
                        <h2>{i18n.t("Kontaktní informace")}</h2>
                        <Card className="order-form__card order-form__card--auto-width order-form__card--last custom-card">
                            <Row>
                                <Col xs={24}>
                                    {form.render(
                                        "customerData[firstName]",
                                        { initialValue: user.firstName || NewOrderStore.tagNameParts.firstName },
                                        {
                                            labelCol: formItemLayout.labelCol,
                                            wrapperCol: formItemLayout.wrapperCol,
                                        }
                                    )}
                                    {form.render(
                                        "customerData[lastName]",
                                        { initialValue: user.lastName || NewOrderStore.tagNameParts.lastName },
                                        {
                                            labelCol: formItemLayout.labelCol,
                                            wrapperCol: formItemLayout.wrapperCol,
                                        }
                                    )}
                                    {form.render(
                                        "customerData[tagName]",
                                        {
                                            initialValue: tagName || "",
                                        },
                                        {
                                            labelCol: formItemLayout.labelCol,
                                            wrapperCol: formItemLayout.wrapperCol,
                                        }
                                    )}

                                    {renderPhoneInput()}

                                    {/* Display a warning if the phone is not mobile */}
                                    {this.state.isNotMobile && (
                                        <Alert
                                            message={i18n.t("We recommend that you enter your mobile phone instead!")}
                                            type="warning"
                                            showIcon
                                            style={{ marginBottom: 16 }}
                                        />
                                    )}

                                    {form.render(
                                        "customerData[email]",
                                        { initialValue: user.email || NewOrderStore.tagNameParts.email },
                                        {
                                            labelCol: formItemLayout.labelCol,
                                            wrapperCol: formItemLayout.wrapperCol,
                                            validateStatus:
                                                NewOrderStore.error && !form.form.getFieldValue("customerData[email]") ? "error" : null,
                                            help:
                                                NewOrderStore.error && !form.form.getFieldValue("customerData[email]")
                                                    ? i18n.t("Prosím vyplňte email")
                                                    : "",
                                        }
                                    )}

                                    {UserStore.isEmailExists && (
                                        <div>
                                            <div style={{ marginTop: 20, marginBottom: 20 }}>
                                                {!UserStore.sentRegisterHash && UserStore.isEmailExists && (
                                                    <Alert
                                                        message={
                                                            <div style={{ fontSize: "15px" }}>
                                                                {i18n.t(
                                                                    "Chcete odeslat ověřovací kód pro vytvoření rezervace pod tímto účtem?"
                                                                )}
                                                                <div className="button-row">
                                                                    <Button
                                                                        className="ml-eamil-veri"
                                                                        type="primary"
                                                                        size="default"
                                                                        icon={<LikeOutlined />}
                                                                        onClick={() => {
                                                                            UserStore.sendRegisterHash();
                                                                        }}
                                                                    >
                                                                        {i18n.t("Odeslat ověřovací kód")}
                                                                    </Button>
                                                                    <Button
                                                                        className="ml-eamil-veri"
                                                                        type="primary"
                                                                        size="default"
                                                                        icon={<CloseOutlined />}
                                                                        onClick={() => {
                                                                            UserStore.isEmailExists = false;
                                                                        }}
                                                                    >
                                                                        {i18n.t("Ne, děkuji")}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        }
                                                        type="warning"
                                                    />
                                                )}
                                            </div>
                                            {form.render("customerData[loginHash]", {}, formItemLayout)}
                                        </div>
                                    )}

                                    {UserStore.sentRegisterHash && !UserStore.isHashVerified && (
                                        <div style={{ marginTop: 18, marginBottom: 18 }}>
                                            <Alert
                                                message={
                                                    <div>
                                                        {i18n.t("Na váš e-mail byl odeslán kód pro dokončení rezervace.")}
                                                        <Button
                                                            className="ml-10"
                                                            type="default"
                                                            size="small"
                                                            onClick={() => {
                                                                UserStore.sentRegisterHash = false;
                                                                UserStore.isEmailExists = false;
                                                            }}
                                                        >
                                                            {i18n.t("Zrušit ověření")}
                                                        </Button>
                                                    </div>
                                                }
                                                type="info"
                                            />
                                        </div>
                                    )}

                                    {UserStore.isHashVerified && (
                                        <div style={{ marginTop: 15, marginBottom: 15 }}>
                                            <Alert
                                                message={i18n.t("Ověření proběhlo úspěšně, můžete dokončit rezervaci.")}
                                                type="success"
                                            />
                                        </div>
                                    )}

                                    <Checkbox
                                        style={{ margin: "1em 0em", fontSize: 13 }}
                                        checked={billingInformationChecked}
                                        id="checkbox-billing-information"
                                        onChange={() => {
                                            NewOrderStore.billingInformationChecked = !NewOrderStore.billingInformationChecked;
                                        }}
                                    >
                                        <div style={{ fontSize: 14 }}>
                                            {i18n.t("Zadat fakturační údaje")}

                                            <Popover
                                                placement="top"
                                                overlayClassName="order-form__popover"
                                                content={i18n.t("Your billing details will appear on your invoice.")}
                                            >
                                                <HelpButton />
                                            </Popover>
                                        </div>
                                    </Checkbox>

                                    {billingInformationChecked && (
                                        <div>
                                            {form.render(
                                                "customerData[companyName]",
                                                { initialValue: user.companyName || "" },
                                                {
                                                    labelCol: formItemLayout.labelCol,
                                                    wrapperCol: formItemLayout.wrapperCol,
                                                }
                                            )}
                                            {form.render(
                                                "customerData[companyAddress][street]",
                                                { initialValue: user.companyAddress ? user.companyAddress.street : "" },
                                                formItemLayout
                                            )}
                                            {form.render(
                                                "customerData[companyAddress][city]",
                                                { initialValue: user.companyAddress ? user.companyAddress.city : "" },
                                                formItemLayout
                                            )}
                                            {form.render(
                                                "customerData[companyAddress][zip]",
                                                { initialValue: user.companyAddress ? user.companyAddress.zip : "" },
                                                formItemLayout
                                            )}
                                            {form.render(
                                                "customerData[companyAddress][country]",
                                                { initialValue: user.companyAddress ? user.companyAddress.country : "" },
                                                formItemLayout
                                            )}
                                            {form.render(
                                                "customerData[identificationNumber]",
                                                { initialValue: user.identificationNumber || "" },
                                                formItemLayout
                                            )}
                                            {form.render(
                                                "customerData[taxIdentificationNumber]",
                                                { initialValue: user.taxIdentificationNumber || "" },
                                                formItemLayout
                                            )}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            <Notification type="contact" />
                        </Card>
                    </div>
                </div>
            );
        }

        if (token && UserStore.user && UserStore.user.email) {
            return (
                <div className="order-form-contact">
                    <div>
                        <h2>{i18n.t("Kontaktní informace")}</h2>
                        <Card className="order-form__card order-form__card--auto-width order-form__card--last custom-card">
                            <Row>
                                <Col xs={24}>
                                    {form.render(
                                        "customerData[tagName]",
                                        {
                                            initialValue: tagName || "",
                                        },
                                        formItemLayout
                                    )}

                                    {renderPhoneInput()}

                                    {/* Display a warning if the phone is not mobile */}
                                    {this.state.isNotMobile && (
                                        <Alert
                                            message={i18n.t("We recommend that you enter your mobile phone instead!")}
                                            type="warning"
                                            showIcon
                                            style={{ marginBottom: 16 }}
                                        />
                                    )}

                                    <FormItem
                                        label={
                                            <span>
                                                {i18n.t("E-mail")}
                                                <Popover
                                                    placement="top"
                                                    overlayClassName="order-form__popover"
                                                    content={i18n.t(
                                                        "If you want to place an order using a different email account, you must log out first."
                                                    )}
                                                >
                                                    <HelpButton />
                                                </Popover>
                                            </span>
                                        }
                                        {...formItemLayout}
                                    >
                                        <span>{UserStore.user.email}</span>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Notification type="contact" />
                        </Card>
                    </div>
                </div>
            );
        }

        return null; // Ensure that the component always returns something
    }
}

export default withRouter(observer(Contact));
