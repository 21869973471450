const Config = {
    apiDateFormat: "YYYY-MM-DD",
    apiDateTimeFormat: "YYYY-MM-DD HH:mm",
    apiTimeFormat: "hh:mm",
    webDateFormat: "DD.MM.YYYY",
    webDayNameFormat: "dddd",
    webDateTimeFormat: "DD.MM.YYYY HH:mm",
    webTimeFormat: "HH:mm",
};

export default Config;
