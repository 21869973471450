import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import ExtrasItem from "./ExtrasItem";

class ExtrasGrid extends Component {
    static propTypes = {
        extras: PropTypes.object.isRequired,
        readOnly: PropTypes.bool.isRequired,
    };

    render() {
        const { extras, readOnly } = this.props;

        const extrasItems = extras.map((extra) => {
            const canShow = (!readOnly && extra.isActive) || (readOnly && extra.isActive) || (readOnly && !extra.isActive);

            if (!canShow) {
                return null;
            }

            return (
                <Col key={extra.id} xs={24} sm={12} md={8} lg={6}>
                    <ExtrasItem item={extra} readOnly={readOnly} />
                </Col>
            );
        });

        return <Row gutter={20}>{extrasItems}</Row>;
    }
}

export default observer(ExtrasGrid);
