import React, { Component } from "react";
import { Row, Col, Button, Card } from "antd";
import { observer } from "mobx-react";
import PaymentsList from "../../../../order/PaymentsList";
import NewOrderStore from "../../../../../stores/Order/NewOrderStore";
import i18n from "../../../../../core/i18n";
import PaymentStore from "../../../../../stores/Payment/PaymentStore";

class MethodOfPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth <= 768,
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize() {
        const isMobile = window.innerWidth <= 768;
        if (isMobile !== this.state.isMobile) {
            this.setState({ isMobile });
        }
    }

    render() {
        const { isMobile } = this.state;
        const DEFAULT_PAYMENTS_COUNT = isMobile ? 2 : 3;

        const onPaymentSelect = (value) => {
            NewOrderStore.paymentType = value;
        };

        const showOnlyPayLater = NewOrderStore.hasSelectedCarsZeroPrice();
        const showPayLater = showOnlyPayLater || NewOrderStore.haveToPayInAdvance.thereRoute || NewOrderStore.haveToPayInAdvance.backRoute;

        const defaultValue =
            !showOnlyPayLater && !showPayLater && NewOrderStore.paymentType === 8 && NewOrderStore.car && !NewOrderStore.car.payAdvance
                ? 1
                : NewOrderStore.paymentType || PaymentStore.getDefaultPaymentType();

        let paymentTypes = PaymentStore.types;
        if (showOnlyPayLater) {
            paymentTypes = [];
        }

        return (
            <div className="order-form-methodOfPayment FlipDiv">
                <h2>{i18n.t(NewOrderStore.returnRouteMode ? "Způsob platby zpáteční cesty" : "Způsob platby (můžete změnit později)")}</h2>

                <Card className="order-form__card custom-card">
                    <div className="order-form__payment-content">
                        <PaymentsList
                            paymentStatus={NewOrderStore.paymentStatus ? NewOrderStore.paymentStatus.id : null}
                            showPayLater={showPayLater}
                            data={paymentTypes}
                            value={defaultValue}
                            defaultCurrency={NewOrderStore.paymentCurrency}
                            onClick={(id) => onPaymentSelect(id)}
                            showCount={NewOrderStore.showAllPayments ? Infinity : DEFAULT_PAYMENTS_COUNT}
                        />
                        {!showOnlyPayLater && PaymentStore.types && PaymentStore.types.length > DEFAULT_PAYMENTS_COUNT && (
                            <Row type="flex" justify="center">
                                <Col style={{ textAlign: "center" }}>
                                    <Button
                                        style={{ marginTop: 10 }}
                                        type="primary"
                                        onClick={() => {
                                            NewOrderStore.showAllPayments = !NewOrderStore.showAllPayments;
                                        }}
                                    >
                                        {i18n.t(NewOrderStore.showAllPayments ? "Zobrazit méně" : "Zobrazit více")}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        {!NewOrderStore.paymentType && <span>{i18n.t("Vyberte platební metodu")}</span>}
                    </div>
                </Card>
            </div>
        );
    }
}

export default observer(MethodOfPayment);
