import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { Row, Col, Card, Divider, Popover } from "antd";
import {
  PushpinOutlined,
  HourglassOutlined,
  RetweetOutlined,
  PictureOutlined,
  CheckCircleOutlined 
} from "@ant-design/icons";
import { withRouter } from "next/router";
import { Form } from "@ant-design/compatible";
import NewOrderFormSchema from "../../../forms/Order/NewOrderFormSchema";
import OrderStep1Page from "./OrderStep1Page";
import OrderStep2Page from "./OrderStep2Page";
import OrderTourPage from "../Tours/OrderTourPage";
import NewOrderStore from "../../../stores/Order/NewOrderStore";
import Sidebar from "../../order/Sidebar";
import i18n from "../../../core/i18n";
import NotificationStore from "../../../stores/NotificationStore";
import InvalidAddressModal from "./orderStep1Page/InvalidAddressModal";
import ContactUsModal from "./orderStep1Page/ContactUsModal";
import RouteDuplicateErrorModal from "../../orderList/RouteDuplicateErrorModal";
import { pushRoute } from "../../Router.tsx";

const { Meta } = Card;

class OrderPage extends Component {
  static propTypes = {
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    router: PropTypes.object.isRequired,
  };

  componentDidMount() {
    NewOrderStore.mode = this.getActiveCard(this.props.router);
  }

  getActiveCard(router) {
    const { pathname } = router;
    let activeCard = "order";
    if (pathname.includes("hour-rental")) {
      activeCard = "hour-rental";
    } else if (pathname.includes("excursion")) {
      activeCard = "excursion";
    } else if (pathname.includes("tours")) {
      activeCard = "tours";
    }
    return activeCard;
  }

  handleCardClick = (key) => {
    NewOrderStore.reset();
    if (key === "excursion" || key === "hour-rental") {
      NewOrderStore.backRoute = false;
    }
    NewOrderStore.mode = key;
    NotificationStore.reset();
    NewOrderStore.resetForm = true;

    const adjustedKey = key === "order" ? "index" : key;
    const params = { lang: this.props.router.query.lang };
    if (adjustedKey !== "index") {
      params.path = adjustedKey;
    }
    pushRoute(this.props.router, adjustedKey, params, { shallow: true });
  };

  renderContent = () => {
    const activeCard = this.getActiveCard(this.props.router);
    const { step } = NewOrderStore;

    if (activeCard === "tours") {
      return <OrderTourPage />;
    }

    return (
      <div className="content">
        {step === 1 && <OrderStep1Page form={this.form} mode={activeCard} />}
        {step === 2 && <OrderStep2Page form={this.form} mode={activeCard} />}
      </div>
    );
  };

  renderNavigationCards(activeCard) {
    const cards = [
      {
        key: "order",
        title: i18n.t("Transfery"),
        icon: <RetweetOutlined style={{ fontSize: "48px", color: "#1890ff" }} />,
      },
      {
        key: "hour-rental",
        title: i18n.t("Hodinový pronájem"),
        icon: <HourglassOutlined style={{ fontSize: "48px", color: "#52c41a" }} />,
      },
      {
        key: "excursion",
        title: i18n.t("Exkurze"),
        icon: <PushpinOutlined style={{ fontSize: "48px", color: "#faad14" }} />,
      },
      {
        key: "tours",
        title: i18n.t("Zajezdy"),
        icon: <PictureOutlined style={{ fontSize: "48px", color: "#eb2f96" }} />,
      },
    ];

    return (
      <Row gutter={[16, 16]}>
        {cards.map((card) => (
          <Col xs={12} sm={12} md={12} lg={6} key={card.key}>
            <Card
              hoverable
              onClick={() => this.handleCardClick(card.key)}
              className={`order-card ${activeCard === card.key ? "active" : ""}`}
              role="button"
              tabIndex={0}
              onKeyUp={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  this.handleCardClick(card.key);
                }
              }}
              aria-pressed={activeCard === card.key}
            ><Popover content={card.title} placement="top" overlayClassName="order-form__popover">
                <div className="order-card-cover">{card.icon}</div>
              </Popover>
                <Meta
                  title={card.title}
                  className="order-card-meta-title"
                />
              
            </Card>
          </Col>
        ))}
      </Row>
    );
  }

  render() {
    const activeCard = this.getActiveCard(this.props.router);
    const { form } = this.props;
    const { step } = NewOrderStore;

    this.form = new NewOrderFormSchema(this.props.router, form, activeCard);

    return (
      <div style={{ background: "#fff", minHeight: "100vh" }}>
        {/* Modals */}
        <RouteDuplicateErrorModal form={this.form} />
        <InvalidAddressModal />
        <ContactUsModal />

        <Row gutter={[30, 30]} style={{ padding: "24px" }}>
          <Col xs={24} sm={24} md={18}>
            {/* Conditionally render the card-based navigation */}
            {step !== 2 && this.renderNavigationCards(activeCard)}

            {/* Main Content */}
            <div>{this.renderContent()}</div>
          </Col>

          <Col xs={24} sm={24} md={6}>
            <div className="order-sidebar">
              <Sidebar />

              {NewOrderStore.step === 1 && (
                <div className="order-sidebar__box">
                <div className="order-sidebar__headline">
                  <h3 className="order-sidebar__title">{i18n.t("Free extras")}</h3>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CheckCircleOutlined style={{ color: "#1890ff", marginRight: "8px" }} />
                    <a
                      href={i18n.t("free tour - url")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {i18n.t("Free tour voucher")}
                    </a>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CheckCircleOutlined style={{ color: "#1890ff", marginRight: "8px" }} />
                    <a
                      href={i18n.t("guide book - url")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {i18n.t("Guidebook & map")}
                    </a>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CheckCircleOutlined style={{ color: "#1890ff", marginRight: "8px" }} />
                    <span>{i18n.t("Free bottled water")}</span>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CheckCircleOutlined style={{ color: "#1890ff", marginRight: "8px" }} />
                    <span>{i18n.t("Wi-fi hotspot")}</span>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CheckCircleOutlined style={{ color: "#1890ff", marginRight: "8px" }} />
                    <span>{i18n.t("Quality service")}</span>
                  </div>
                </div>
                <p>
                  {i18n.t(
                    "Veškeré promo materiály zahrnující mapy, průvodce Prahou, voucher na 4 hodinovou free túru Prahou atd. Obdržíte přímo od našeho řidiče."
                  )}
                </p>
              </div>
              )}

              {NewOrderStore.step === 2 && (
                <div className="order-sidebar__box">
                  <div className="order-sidebar__headline">
                    <h3 className="order-sidebar__title">
                      {i18n.t("Užitečné informace")}
                    </h3>
                  </div>
                  <h6 className="order-sidebar__subtitle">
                    {i18n.t("Jak funguje proces rezervace")}
                  </h6>
                  <p>{i18n.t("Instrukce")}</p>
                  <Divider />
                  <h6 className="order-sidebar__subtitle">
                    {i18n.t("Free Cancellation")}
                  </h6>
                  <p>
                    {i18n.t(
                      "Most services can be cancelled anytime free of charge with no deposit required"
                    )}
                  </p>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Form.create()(withRouter(observer(OrderPage)));
