import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "next/router";
import { Link } from "../Router.tsx";
import Image from 'next/image';
class Logo extends Component {
    render() {
        return (
            <div className="header__logo">
                <Link route="index" params={{}}>
                    <Image
                        src="/img/logo.svg"
                        alt="Prague Airport Transfers"
                        width={418}
                        height={107}
                        priority
                        className="responsiveImage"
                    />
                </Link>
            </div>
        );
    }
}

export default withRouter(observer(Logo));
