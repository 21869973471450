import SettingsStore from "../stores/Settings/SettingsStore";

class gptManager {
    gtag = () => {};

    init() {
        const { gTagAw } = SettingsStore.settings;

        window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }
        gtag("js", new Date());

        //gtag('config', gTagUa); // UA-7197138-1
        gtag("config", gTagAw); // AW-1069358169

        this.gtag = gtag;
    }

    initPostAffTracker() {
        const { postAffTrackerId } = SettingsStore.settings;
        PostAffTracker.setAccountId(postAffTrackerId);
        try {
            PostAffTracker.track();
        } catch (err) {}
    }

    orderSubmit(value, currency, transaction_id, transportPrice = 0, mode = null) {
        if (typeof this.gtag !== "undefined") {
            this.gtag("event", "conversion", {
                send_to: "AW-1069358169/5jkRCPnNwwgQ2bj0_QM",
                value: value,
                currency: currency,
                transaction_id: transaction_id,
            });
        }

        const sale = PostAffTracker.createSale();

        sale.setTotalCost(transportPrice);
        sale.setOrderID(transaction_id);
        sale.setProductID(mode);
        sale.setStatus("P");
        PostAffTracker.register();

        //console.log('PostAffTracker',PostAffTracker);
    }
}
export default new gptManager();
