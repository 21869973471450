import React, { Component } from "react";
import { Card, Row, Col } from "antd";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import NewOrderStore from "../../../../../stores/Order/NewOrderStore";

class PassengersCountAndRentHours extends Component {
    static propTypes = {
        routeNumber: PropTypes.number.isRequired,
        formValues: PropTypes.objectOf(PropTypes.any).isRequired,
        formItemLayout: PropTypes.objectOf(PropTypes.any).isRequired,
        form: PropTypes.objectOf(PropTypes.any).isRequired,
    };

    render() {
        const { routeNumber, formValues, formItemLayout, form } = this.props;
        const { mode } = NewOrderStore;

        return (
            <Card className="order-form__card custom-card">
                <Row gutter={40}>
                    <Col xs={24} md={24}>
                        {form.render(
                            `routes[${routeNumber}][passengersCount]`,
                            { initialValue: formValues.routes[routeNumber].passengersCount || 2 },
                            formItemLayout
                        )}

                        {mode === "hour-rental" && (
                            <div style={{ marginTop: 15 }}>
                                {form.render(
                                    `routes[${routeNumber}][rentHours]`,
                                    { initialValue: formValues.routes[routeNumber].rentHours || 1 },
                                    formItemLayout
                                )}
                            </div>
                        )}
                    </Col>
                </Row>
            </Card>
        );
    }
}

export default observer(PassengersCountAndRentHours);
