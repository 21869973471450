import React, { Component } from "react";
import { Alert, Steps } from "antd";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import NewOrderStore from "../../../../stores/Order/NewOrderStore";
import i18n from "../../../../core/i18n";

class OrderFormSteps extends Component {
    static propTypes = {
        viewport: PropTypes.number.isRequired,
    };

    render() {
        const { Step } = Steps;

        const { viewport } = this.props;
        let title;
        if (NewOrderStore.addReturnRoute) {
            title = "Zpáteční cesta";
        } else {
            title = "Nová objednávka";
        }
        if (NewOrderStore.editOrder) {
            title = "Editace objednávky";
        }
        return (
            <div className="order-form-steps">
                    <div className="order-form__steps">
                        <Steps progressDot current={NewOrderStore.returnRouteMode ? 1 : 0}>
                            <Step title={NewOrderStore.editOrder ? i18n.t("Editace objednávky") : i18n.t("Vyplnit formulář")} />
                            {NewOrderStore.backRouteStep && <Step title={i18n.t("Zpáteční cesta")} />}
                            <Step title={i18n.t("Souhrn")} />
                        </Steps>
                </div>

                <div ref={NewOrderStore.myRef}>{!!NewOrderStore.error && <Alert message={NewOrderStore.error} type="error" />}</div>
            </div>
        );
    }
}

export default observer(OrderFormSteps);
