import React from "react";
import { observer } from "mobx-react";
import { configure } from "mobx";
import { Layout, message, ConfigProvider, theme, Spin } from "antd";
import App from "next/app";
import getConfig from "next/config";
import Script from 'next/script';
import { withRouter } from "next/router";
import UserStore from "../stores/UserStore";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AutoCompleteLocationStore from "../stores/AutoCompleteLocation/AutoCompleteLocationStore";
import CurrencyStore from "../stores/Currency/CurrencyStore";
import i18n from "../core/i18n";
import LanguageStore, { DEFAULT_LANG } from "../stores/Language/LanguageStore";
import { CtxRedirect } from "../models/CtxRedirect";
import { CookieStorage } from "../models/CookieStorage";
import { isProfilePage } from "../models/isProfilePage.ts";
import "../../public/less/main.less";
import "../components/customStyle.css";
import "../components/pages/Tours/detailsPage/style.css";
import "../components/pages/Stripe/stripe-style.css";
import "react-phone-input-2/lib/style.css";
import { LanguageDetector } from "../models/LanguageDetector";
import gptManager from "../utils/gptManager";
import SklikManager from "../utils/sklikManager";
import moment from "moment-timezone";
import PaymentStore from "../stores/Payment/PaymentStore";
import SettingsStore from "../stores/Settings/SettingsStore";


const { publicRuntimeConfig } = getConfig();
const SERVER_TIME_ZONE = "Europe/Prague";
moment.tz.setDefault(SERVER_TIME_ZONE);

configure({
    enforceActions: "never", // Turns off strict mode
});

class MyApp extends App {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };

        // Bind the initMap function to the window object
        if (typeof window !== "undefined") {
            window.initMap = this.initMap.bind(this);
        }
    }

    /**
     * Global callback function invoked by Google Maps API once it's loaded.
     */
    initMap() {
        try {
            AutoCompleteLocationStore.initGoogle();
            this.handleLoad();
        } catch (error) {
            console.error("Error initializing Google Maps:", error);
            this.handleLoad(); // Ensure loading state is handled even if initialization fails
        }
    }

    static async getInitialProps(appContext) {
        // Extract context
        const { ctx } = appContext;

        // Read user data from cookies
        const token = CookieStorage.read("token", ctx);
        const refreshToken = CookieStorage.read("refreshToken", ctx);
        const user = CookieStorage.read("user", ctx) ? JSON.parse(CookieStorage.read("user", ctx)) : null;

        // Detect language
        const langKey = LanguageDetector.detect(ctx);
        let lang;
        if (ctx.res) {
            const init = await LanguageStore.getInit(langKey);
            LanguageStore.setInit(init);
            lang = init.language;
            await i18n.changeLanguage(lang);
        } else {
            lang = LanguageStore.findLanguageKey(langKey) || DEFAULT_LANG;
        }

        // Check if accessing a protected route without token
        if (!token && isProfilePage(ctx)) {
            CtxRedirect.redirect(ctx, `/${lang}/login`);
        }

        LanguageStore.lang = lang;

        const appProps = await App.getInitialProps(appContext);
        return {
            ...appProps,
            lang,
            userData: { token, refreshToken, user },
            init: {
                language: lang,
                translations: LanguageStore.translations,
                pageTranslations: LanguageStore.pageTranslations,
                languages: LanguageStore.languages,
            },
        };
    }

    componentDidMount() {
        // Configure Ant Design messages
        message.config({
            maxCount: 3,
            duration: 4,
            top: 70,
        });

        // Initialize stores with props
        LanguageStore.lang = this.props.lang;
        UserStore.setUserData(this.props.userData); // Initialize UserStore
        LanguageStore.setInit(this.props.init);
        CurrencyStore.load();
        i18n.changeLanguage(this.props.lang);

        // Additional store initializations
        PaymentStore.loadTypes();
        SettingsStore.load();

        // Load external managers
        gptManager.init();
        SklikManager.init();

        // Note: Loading state is handled in the initMap callback
    }

    /**
     * Sets the loading state to false.
     */
    handleLoad = () => {
        this.setState({ loading: false });
    };

    render() {
        const { Component, pageProps } = this.props;
        const { loading } = this.state;

        const { locale, direction } = LanguageStore.getLocaleAndDirection(this.props.lang);

        const directionClass = direction === "ltr" ? "ant-layout-ltr page" : "page";

        return (
            <ConfigProvider
                locale={locale}
                direction={direction}
                theme={{
                    algorithm: theme.defaultAlgorithm,
                }}
            >
                <Layout style={{ minHeight: "100vh" }} className={directionClass}>
                    <Layout>
                        <div>
                            {loading ? (
                                <div style={{ textAlign: "center", paddingTop: "50px", height: "100vh" }}>
                                    <Spin size="large" />
                                </div>
                            ) : (
                                <div>
                                    <Header />
                                    <Layout.Content>
                                        <div className="container">
                                            <Component {...pageProps} />
                                        </div>
                                    </Layout.Content>
                                    <Footer />
                                </div>
                            )}
                        </div>
                        <div style={{ display: "none" }} id="map" />
                    </Layout>

                    {/* External Scripts */}
                    <Script
                        src={`https://maps.googleapis.com/maps/api/js?key=${publicRuntimeConfig.googleServerApiKey}&libraries=places&language=${this.props.lang}&callback=initMap&loading=async`}
                        id="google-maps"
                        strategy="afterInteractive"
                        onError={() => console.error('Failed to load Google Maps')}
                    />

                    {publicRuntimeConfig.envDev === "prod" && (
                        <>
                            <Script
                                src="https://www.googletagmanager.com/gtag/js?id=AW-1069358169"
                                id="gtag-js"
                                strategy="afterInteractive"
                                onError={() => console.error('Failed to load GTM')}
                            />
                            <Script
                                src="https://www.prague-airport-transfers.co.uk/affiliates2/scripts/trackjs.js"
                                id="pap_x2s6df8d"
                                strategy="lazyOnload"
                                onError={() => console.error('Failed to load PostAffTracker')}
                            />
                            <Script
                                src="/js/ms_tracking.js"
                                id="ms-tracking"
                                strategy="lazyOnload"
                                onError={() => console.error('Failed to load MS Tracking')}
                            />
                            <Script
                                src="//code.tidio.co/6k9wjb2ws9c6metflawhkqqmsrwovis0.js"
                                id="tidio"
                                strategy="lazyOnload"
                                onError={() => console.error('Failed to load Tidio')}
                            />
                            <Script
                                src="https://c.seznam.cz/js/rc.js"
                                id="sklik-js"
                                strategy="lazyOnload"
                                onLoad={() => SklikManager.init()}
                                onError={() => console.error('Failed to load Sklik')}
                            />
                            <Script
                                src="https://cdn-cookieyes.com/client_data/587e00221f9d6e925ffd48da/script.js"
                                id="cookieyes"
                                strategy="lazyOnload"
                                onError={() => console.error('Failed to load CookieYes')}
                            />
                        </>
                    )}
                </Layout>
            </ConfigProvider>
        );
    }
}

export default withRouter(observer(MyApp));
