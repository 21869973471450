import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "../RootStore";
import NotificationStore from "../NotificationStore";
import i18n from "../../core/i18n";
import LanguageStore from "../Language/LanguageStore";

class UserListStore {
    user = {
        partners: [],
    };

    isLoading = false;

    isSaving = false;

    sentForm = null;

    onLoad = null;

    constructor() {
        makeAutoObservable(this);
    }

    // Synchronously set a callback
    setCallback = (cb) => {
        this.onLoad = cb;
    };

    // Synchronously clear the callback
    clearCallback = () => {
        this.onLoad = null;
    };

    // Synchronously clear user data
    clearUser = () => {
        runInAction(() => {
            this.user = { partners: [] };
        });
    };

    // Asynchronous method to load user data
    loadUser = (saveLanguage) => {
        this.isLoading = true;
        NotificationStore.reset();

        RootStore.api
            .get("/users/load/")
            .then((response) => {
                runInAction(() => {
                    this.user = response;

                    if (saveLanguage && response.language !== LanguageStore.lang) {
                        this.save({ language: LanguageStore.lang }, false);
                    }

                    this.isLoading = false;
                    if (this.onLoad) {
                        this.onLoad(this.user);
                        this.clearCallback();
                    }
                });
            })
            .catch((e) => {
                runInAction(() => {
                    this.isLoading = false;
                });
                NotificationStore.processException(e, "user");
            });
    };

    // Asynchronous method to save user data
    save = (values, loadUser = true) => {
        this.isSaving = true;
        NotificationStore.reset();

        RootStore.api
            .put(`/users/${this.user.id}/`, values)
            .then(() => {
                runInAction(() => {
                    this.isSaving = false;
                    if (loadUser) {
                        this.loadUser();
                    }
                    NotificationStore.processSuccess(i18n.t("Údaje byly uloženy"), "user");
                });
            })
            .catch((e) => {
                runInAction(() => {
                    this.isSaving = false;
                    if (loadUser) {
                        this.loadUser();
                    }
                });
                NotificationStore.processException(e, "user");
            });
    };

    // Action to remove a partner by index
    removePartner = (index) => {
        runInAction(() => {
            this.user.partners = this.user.partners.filter((partner, i) => i !== index);
        });
        this.save(this.user);
    };
}

export default new UserListStore();
