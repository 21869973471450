import { observable, action, runInAction, makeObservable } from "mobx";
import _ from "lodash";
import NewOrderStore from "../Order/NewOrderStore";
import getAddress from "../../utils/autoCompleteLocation/getAddress";
import i18n from "../../core/i18n";
import LocationStore from "../Location/LocationStore";

class AutoCompleteLocationStore {
    location = {
        lat: 50.0824098,
        lng: 14.4260982,
    };

    /**
     * @param {window.google.maps.Map}
     */
    map;

    /**
     * @param {window.google.maps.places.AutocompleteService}
     */
    autocompleteService;

    /**
     * @param {window.google.maps.places.PlacesService}
     */
    placesService;

    startResult = {
        favoriteLocations: [],
        googleLocations: [],
    };

    finishResult = {
        favoriteLocations: [],
        googleLocations: [],
    };

    googleResults = [];

    placeDetail = null;

    isLoading = false;

    isMapChoose = false;
    startcurrentLocation = false;
    finishcurrentLocation = false;

    markerPointA = null;
    markerPointB = null;

    constructor() {
        makeObservable(this, {
            startResult: observable,
            finishResult: observable,
            googleResults: observable,
            placeDetail: observable,
            isLoading: observable,
            isMapChoose: observable,
            startcurrentLocation: observable,
            finishcurrentLocation: observable,
            markerPointA: observable,
            markerPointB: observable,
            searchPlaces: action,
            clearResults: action.bound,
            initGoogle: action.bound,
            initOptions: action.bound,
        });

        this.debouncedSearch = _.debounce(this.search, 300);
        this.removeGoogleDuplicates = this.removeGoogleDuplicates.bind(this);
        this.removeGoogleDuplicatesByDescription = this.removeGoogleDuplicatesByDescription.bind(this);

        // Load locations asynchronously
        LocationStore.loadLocations().then(() => {
            runInAction(() => {
                this.locations = LocationStore.locations;
            });
        });
    }

    getPlaceDetail = async (placeId) => {
        await this.placesService.getDetails(
            {
                placeId,
                fields: ["name", "formatted_address", "address_components", "place_id"],
            },
            (res) => {
                this.placeDetail = res;
            }
        );
    };

    search(phrase, isStart = false, sessionToken) {
        if (phrase.length >= 3 && typeof window !== "undefined" && window.google) {
            this.initOptions(phrase, isStart);

            if (phrase.length < 5 && this.getFavoriteLocations(phrase, isStart).length === 0) {
                this.searchPlaces({ phrase, isStart, sessionToken });
            }
            if (phrase.length >= 5) {
                this.searchPlaces({ phrase, isStart, sessionToken });
            }
        } else {
            this.initOptions(phrase, isStart);
        }
    }

    searchPlaces({ phrase, isStart, sessionToken }) {
        this.isLoading = true;
        const options = {
            input: phrase,
            types: ["establishment", "geocode"],
            locationRestriction: new google.maps.LatLngBounds(
                new google.maps.LatLng(44.65998923149729, 3.776896607917837),
                new google.maps.LatLng(55.99886514990494, 23.766936876837846)
            ),
            sessionToken,
        };
        this.autocompleteService.getPlacePredictions(options, (places, status) => {
            runInAction(() => {
                if (status !== window.google.maps.places.PlacesServiceStatus.OK || !places) {
                    this.googleResults = [];
                } else {
                    this.googleResults = this.removeGoogleDuplicatesByDescription(places);
                }
                this.initOptions(phrase, isStart);
                this.isLoading = false;
            });
        });
    }

    clearResults() {
        this.startResult = {
            favoriteLocations: [],
            googleLocations: [],
        };
        this.finishResult = {
            favoriteLocations: [],
            googleLocations: [],
        };
        this.googleResults = [];
    }

    initGoogle() {
        if (typeof window !== "undefined" && window.google) {
            if (this.autocompleteService && this.placesService) {
                return;
            }

            this.map = new window.google.maps.Map(document.getElementById("map"), {
                center: this.location,
                zoom: 7,
            });

            this.autocompleteService = new window.google.maps.places.AutocompleteService();
            this.placesService = new window.google.maps.places.PlacesService(this.map);
        }
    }

    initOptions(value, isStart) {
        const newLocations = JSON.parse(JSON.stringify(this.locations));
        if (newLocations.length > 0) {
            newLocations.forEach((location) => {
                const originalName = location.name; // Original name (Czech)
                const translatedName = i18n.t(location.name); // Translated name

                // Store the translated name and map it back to the original name
                location.translatedName = translatedName;
                NewOrderStore.translatedLocations[translatedName] = originalName;
                NewOrderStore.translatedLocations[originalName] = originalName; // Ensure original name is also stored
            });
        }

        const varName = isStart ? "startResult" : "finishResult";

        let allLocations = [];
        if (!value || value.length < 2) {
            allLocations = newLocations;
        } else {
            allLocations = newLocations.filter((location) => {
                return location.translatedName && location.translatedName.toLowerCase().includes(value.toLowerCase());
            });
        }

        let uniqueLocations = allLocations.map((location) => {
            return {
                ...location,
                name: location.translatedName, // Use translated name for display
            };
        });

        uniqueLocations = uniqueLocations.filter(
            (location) => !!(getAddress(location) !== (isStart ? NewOrderStore.finishValue : NewOrderStore.startValue))
        );

        if (isStart) {
            uniqueLocations = uniqueLocations.filter((location) => location.suggestInStart);
        } else {
            uniqueLocations = uniqueLocations.filter((location) => location.suggestInFinish);
        }

        const favoriteLocationsResult = uniqueLocations.slice(0, 4);

        const favoritePlaceIds = uniqueLocations.map((location) => location.placeId);
        this[varName].googleLocations = this.removeGoogleDuplicates(this.googleResults, favoritePlaceIds);
        this[varName].favoriteLocations = favoriteLocationsResult;
        this.isLoading = false;
    }

    getFavoriteLocations(value, isStart) {
        let allLocations = [];
        if (!value || value.length < 2) {
            allLocations = this.locations.slice(0, 4);
        } else {
            allLocations = this.locations.filter((location) => location.name.toLowerCase().includes(value.toLowerCase()));
        }

        let uniqueLocations = allLocations.map((location) => {
            if (location.name in NewOrderStore.translatedLocations) {
                return NewOrderStore.translatedLocations[location.name];
            }
            return location;
        });

        uniqueLocations = uniqueLocations.slice(0, 4);
        uniqueLocations = uniqueLocations.filter(
            (location) => !!(getAddress(location) !== (isStart ? NewOrderStore.finishValue : NewOrderStore.startValue))
        );

        if (isStart) {
            uniqueLocations = uniqueLocations.filter((location) => location.suggestInStart);
        } else {
            uniqueLocations = uniqueLocations.filter((location) => location.suggestInFinish);
        }

        return uniqueLocations;
    }

    removeGoogleDuplicates(googleLocations, favoritePlaceIds) {
        const filteredLocations = googleLocations.filter((location) => {
            if (favoritePlaceIds.includes(location.place_id)) {
                return false;
            }
            return true;
        });
        return filteredLocations;
    }

    removeGoogleDuplicatesByDescription(googleLocations) {
        const usedLocations = [];
        return googleLocations.filter((location) => {
            if (!usedLocations.includes(location.description)) {
                usedLocations.push(location.description);
                return true;
            }
            return false;
        });
    }
}

export default new AutoCompleteLocationStore();
