import React from "react";
import { Carousel, Badge, Avatar } from "antd";
import { CarOutlined } from "@ant-design/icons";
import FileUploadStore from "../../stores/FileUploadStore";
import i18n from "../../core/i18n";

// Helper to render a plain Avatar.
const renderAvatar = (src) => (
  <Avatar
    src={src}
    shape="square"
    className="custom-avatar"
    icon={<CarOutlined />}
  />
);

// For single items: wrap avatar with a discount badge that includes the discount percentage.
const renderAvatarWithDiscount = (src, discountValue) => {
  const avatar = renderAvatar(src);
  return discountValue > 0 ? (
    <Badge
      count={`${discountValue}% ${i18n.t("off")}`}
      offset={[-25, 11]}
      color="#c0392b"
    >
      {avatar}
    </Badge>
  ) : (
    avatar
  );
};

// For combination items: if a discount exists for any sub-car, wrap avatar with a badge 
// that displays only a text label (here "reduced"); otherwise, render a plain avatar.
const renderAvatarWithCombinationBadge = (src) => {
  const avatar = renderAvatar(src);
  return (
    <Badge
      count={i18n.t("reduced")}
      offset={[-25, 11]}
      color="#c0392b"
    >
      {avatar}
    </Badge>
  );
};

// For a popular badge (no discount, non-combination, and isShowFirst true).
const renderAvatarWithPopularBadge = (src) => {
  const avatar = renderAvatar(src);
  return (
    <Badge
    count={i18n.t("popular")}
      offset={[-25, 11]}
      // Customize the color as needed; here we're using a primary blue.
      color="#185a97"
    >
      {avatar}
    </Badge>
  );
};

const GetCarImg = ({ item }) => {
  if (item.combination && item.combination.length >= 1) {
    // Only check the catDiscountParcentage field for discount.
    const hasDiscount = item.combination.some(
      (car) => (car.catDiscountParcentage || 0) > 0
    );
    if (item.combination.length > 1) {
      return (
        <Carousel autoplay>
          {item.combination.map((car, index) => (
            <div key={index}>
              {hasDiscount
                ? renderAvatarWithCombinationBadge(
                    FileUploadStore.getImgBaseUrl(car.imgId)
                  )
                : renderAvatar(FileUploadStore.getImgBaseUrl(car.imgId))}
            </div>
          ))}
        </Carousel>
      );
    } else {
      // Single combination image.
      const singleCombination = item.combination[0];
      const discountValue = singleCombination.catDiscountParcentage || 0;
      return discountValue > 0
        ? renderAvatarWithCombinationBadge(
            FileUploadStore.getImgBaseUrl(singleCombination.imgId)
          )
        : renderAvatar(FileUploadStore.getImgBaseUrl(singleCombination.imgId));
    }
  } else {
    // For a single (non-combination) image, only consider catDiscountParcentage.
    const discount = item.catDiscountParcentage || 0;
    const baseImg = FileUploadStore.getImgBaseUrl(item.imgId);
    
    // If there's a discount (catDiscountParcentage > 0), show discount badge.
    if (discount > 0) {
      return renderAvatarWithDiscount(baseImg, discount);
    }
    
    // If no discount and the item should show the 'Popular' badge.
    if (item.isShowFirst) {
      return renderAvatarWithPopularBadge(baseImg);
    }
    
    // Otherwise, just show the plain avatar.
    return renderAvatar(baseImg);
  }
};

export default GetCarImg;
