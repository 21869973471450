import React from "react";
import { observer } from "mobx-react";
import { PhoneOutlined, WhatsAppOutlined, FormOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import i18n from "../../core/i18n";
import ContactUsModalStore from "../../stores/Order/ContactUsModalStore";
import SettingsStore from "../../stores/Settings/SettingsStore";
import Image from 'next/image';

const Sidebar = observer(() => {
    const { settings } = SettingsStore;
    const {
        imagecerTifikaturl = "https://www.prague-airport-transfers.co.uk/data/tripadvisor_1.jpg",
        tripadvisorurl = "https://www.tripadvisor.co.uk/Attraction_Review-g274707-d2474971-Reviews-Prague_Airport_Transfers_Day_Tours-Prague_Bohemia.html",
    } = settings;

    /**
     * Opens a new window with the specified URL.
     * Adds "noopener,noreferrer" to enhance security by preventing the new page from accessing the window.opener property.
     *
     * @param {string} url - The URL to open.
     */
    const openWindow = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <div>
            {/* Contact Information Section */}
            <div className="order-sidebar__box">
                <div className="order-sidebar__headline">
                    <h3 className="order-sidebar__title">{i18n.t("Rezervace je bezpečná")}</h3>
                </div>
                <div>{i18n.t("Rezervaci můžete provést rovněž telefonicky na bezplatné lince")}</div>
                <div>
                    {/* WhatsApp Contact */}
                    <Divider style={{ margin: "7px 0 0 0" }} />
                    <WhatsAppOutlined style={{ color: "#25D366", marginRight: "8px" }} />
                    <Button size="large" type="link" onClick={() => openWindow("https://wa.me/447449011780")}>
                        +44 7449 011780
                    </Button>

                    {/* Phone Contact */}
                    <Divider style={{ margin: "0px 0" }} />
                    <PhoneOutlined style={{ color: "#170686", marginRight: "8px" }} />
                    <Button size="large" type="link" onClick={() => openWindow("tel:+420222554211")}>
                        +420 222 554 211
                    </Button>

                    {/* FAQ Link */}
                    <Divider style={{ margin: "0px 0" }} />
                    <InfoCircleOutlined style={{ color: "#002943", marginRight: "8px" }} />
                    <Button
                        size="large"
                        type="link"
                        onClick={() => openWindow(i18n.t("https://www.prague-airport-transfers.co.uk/frequently-asked-questions/"))}
                    >
                        {i18n.t("FAQ")}
                    </Button>

                    {/* Contact Form */}
                    <Divider style={{ margin: "0px 0" }} />
                    <FormOutlined style={{ color: "#002943", marginRight: "8px" }} />
                    <Button size="large" type="link" onClick={() => ContactUsModalStore.open()}>
                        {i18n.t("Kontaktní formulář")}
                    </Button>
                </div>
            </div>
            <div style={{ marginBottom: 28 }}></div>
            {/* Recommended Section */}
            <div className="order-sidebar__box">
                <div className="order-sidebar__headline">
                    <h3 className="order-sidebar__title">{i18n.t("Doporučeno na")}</h3>
                </div>
                {tripadvisorurl && imagecerTifikaturl && (
                    <a href={tripadvisorurl} title={i18n.t("Doporučeno na")} target="_blank" rel="noopener noreferrer">
                        <div className="imageWrapper">
                            <Image
                                src={imagecerTifikaturl}
                                alt={i18n.t("Doporučeno na")}
                                width={311}
                                height={240}
                                priority
                                className="responsiveImage"
                            />
                        </div>
                    </a>
                )}
            </div>
            <div style={{ marginBottom: 28 }}></div>
        </div>
    );
});

export default Sidebar;
