import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { Alert } from "antd";
import NotificationStore from "../stores/NotificationStore";
import i18n from "../core/i18n";

class Notification extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
    };

    render() {
        const { type } = this.props;
        //console.log("check",NotificationStore.message)
        if (NotificationStore.message.length === 0 || NotificationStore.type !== type) {
            return null;
        }

        return (
            <Alert
                style={{ marginBottom: 30 }}
                type={NotificationStore.isError ? "error" : "success"}
                showIcon
                message={i18n.t(NotificationStore.message)}
            />
        );
    }
}

export default observer(Notification);
