import { observable, makeObservable } from "mobx";
import CarCategoryDecorator from "./CarCategoryDecorator";

export default class CarCategoryCombinationDecorator {
    id = 0;

    price = null;

    capacity = 0;

    luggageCapacity = 0;

    refundCancellation = 0;

    combination = [];

    discount = null;

    surcharges = null;

    discountAndSurchargeMaxDate = null;

    discountAndSurchargeMinDate = null;

    catDiscountParcentage = 0;

    constructor(data) {
        makeObservable(this, {
            id: observable,
            price: observable,
            capacity: observable,
            luggageCapacity: observable,
            refundCancellation: observable,
            combination: observable,
            discount: observable,
            surcharges: observable,
            discountAndSurchargeMaxDate: observable,
            discountAndSurchargeMinDate: observable,
            catDiscountParcentage: observable,
        });

        if (data) {
            this.setAll(data);
        }
    }

    setAll(data) {
        this.id = data.carCategoryId || null;
        this.price = data.price || null;
        this.capacity = data.capacity || 0;
        this.luggageCapacity = data.luggageCapacity || 0;
        this.discount = data.discount || 0;
        this.surcharges = data.surcharges || 0;
        this.discountAndSurchargeMaxDate = data.discountAndSurchargeMaxDate || null;
        this.discountAndSurchargeMinDate = data.discountAndSurchargeMinDate || null;
        this.refundCancellation = data.refundCancellation || 0;
        this.catDiscountParcentage = data.catDiscountParcentage || 0;

        data.combination.forEach((carCategory) => {
            this.combination.push(new CarCategoryDecorator(carCategory));
        });
    }
}
