const getAddress = (location) => {
    // eslint-disable-line class-methods-use-this
    const address = location?.address || location?.structured_formatting?.secondary_text;
    const name = location?.name || location?.structured_formatting?.main_text?.toUpperCase();
    if (address !== name && address) {
        return `${name} - ${address}`;
    }
    return name || address;
};

export default getAddress;
