import { observable, action, makeObservable, runInAction } from "mobx";
import RootStore from "./RootStore";
import NotificationStore from "./NotificationStore";
import NewOrderStore from "./Order/NewOrderStore";
import { CookieStorage } from "../models/CookieStorage";
import { pushRoute } from "../components/Router.tsx";
import UserListStore from "./User/UserListStore";
import AddReviewModalStore from "./Order/AddReviewModalStore";
import OrdersListStore from "./Order/OrdersListStore";
import i18n from "../core/i18n";

class UserStore {
    // Observables
    inactivity = false;
    token = null;
    refreshToken = null;
    user = null;
    isEmailExists = false;
    isHashVerified = false;
    sentRegisterHash = false;
    userEmail = null;
    changingEmail = false;
    emailLoading = false;
    isLoggingIn = false;
    typingTimeout = null;
    isRegistering = false;

    constructor() {
        makeObservable(this, {
            // Observables
            inactivity: observable,
            token: observable,
            refreshToken: observable,
            user: observable,
            isEmailExists: observable,
            isHashVerified: observable,
            sentRegisterHash: observable,
            userEmail: observable,
            changingEmail: observable,
            emailLoading: observable,
            isLoggingIn: observable,
            isRegistering: observable,

            // Actions
            setUserData: action.bound,
            getUserData: action.bound,
            storeToken: action.bound,
            storeRefreshToken: action.bound,
            storeUserData: action.bound,
            login: action.bound,
            logout: action.bound,
            checkEmail: action.bound,
            sendRegisterHash: action.bound,
            verifyRegisterHash: action.bound,
            canOrderContinue: action.bound,
            userCreateValidation: action.bound,
            getPhoneNum: action.bound,
            getPrefix: action.bound,
            register: action.bound,
            storeTokenData: action.bound,
        });
    }

    // Action Methods

    setUserData({ token, refreshToken, user }) {
        this.token = token;
        this.refreshToken = refreshToken;
        this.user = user;
    }

    getUserData(ctx) {
        return {
            token: CookieStorage.read("token", ctx),
            refreshToken: CookieStorage.read("refreshToken", ctx),
            user: CookieStorage.read("user", ctx) ? JSON.parse(CookieStorage.read("user", ctx)) : null,
        };
    }

    storeToken(token) {
        this.token = token;
        CookieStorage.write("token", token);
    }

    storeRefreshToken(refreshToken) {
        this.refreshToken = refreshToken;
        CookieStorage.write("refreshToken", refreshToken);
    }

    storeUserData(user) {
        this.user = user;

        CookieStorage.write("stripeCustId", JSON.stringify(this.user.stripeCustId));
        CookieStorage.write("can_invoice", JSON.stringify(this.user.can_invoice));
        CookieStorage.write("user", JSON.stringify(this.user));
    }

    login(values, router, routeId = null) {
        this.isLoggingIn = true;
        NotificationStore.reset();
        values.isUserView = true;
        return RootStore.api
            .login("/login", values)
            .then((response) => {
                this.storeTokenData(response.email, response.token, response.refresh_token, response.can_invoice, response.stripeCustId);

                pushRoute(router, "orders", { lang: router.query.lang, path: "orders" }, { shallow: true });

                if (routeId) {
                    AddReviewModalStore.routeHasReview(routeId).then((result) => {
                        if (!result) {
                            AddReviewModalStore.open(parseInt(routeId, 10));
                        } else {
                            OrdersListStore.setShowReviewExistModal(true);
                        }
                    });
                }

                this.isLoggingIn = false;
            })
            .catch((e) => {
                this.isLoggingIn = false;
                NotificationStore.processException(e, "login");
            });
    }

    storeTokenData(email, token, refreshToken, can_invoice = false, stripeCustId = null) {
        this.inactivity = false;
        this.storeUserData({ email, can_invoice, stripeCustId });
        this.storeToken(token);
        this.storeRefreshToken(refreshToken);
    }

    logout(router, inactivity = false) {
        return RootStore.api
            .logout("/booking/users/logout/")
            .then((response) => {
                if (response.success) {
                    this.inactivity = inactivity;
                    this.user = null;
                    UserListStore.clearUser();
                    this.token = null;
                    CookieStorage.destroy("token");
                    CookieStorage.destroy("user");
                    CookieStorage.destroy("refreshToken");
                    CookieStorage.destroy("can_invoice");
                    CookieStorage.destroy("stripeCustId");
                    pushRoute(router, "login", { lang: router.query.lang, path: "login" }, { shallow: true });
                }
            })
            .catch((e) => {
                NotificationStore.processException(e, "login");
            });
    }

    checkEmail(email) {
        this.changingEmail = true;

        if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
        }

        this.typingTimeout = setTimeout(() => {
            this.emailLoading = true;

            RootStore.api
                .get("/users/email/", { email })
                .then((response) => {
                    this.changingEmail = false;
                    this.emailLoading = false;
                    this.isEmailExists = false;
                    if (response.success === true) {
                        this.isEmailExists = true;
                        this.userEmail = email;
                    }
                })
                .catch((e) => {
                    this.changingEmail = false;
                    this.emailLoading = false;
                    NotificationStore.processException(e, "login");
                });
        }, 1500);
    }

    sendRegisterHash() {
        this.sentRegisterHash = true;
        return RootStore.api
            .get("/users/register-hash/", { email: this.userEmail })
            .then((response) => {
                if (response.success !== true) {
                    this.sentRegisterHash = false;
                }
            })
            .catch((e) => {
                NotificationStore.processException(e, "login");
            });
    }

    verifyRegisterHash(hash) {
        if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
        }

        this.typingTimeout = setTimeout(
            () =>
                RootStore.api
                    .get("/users/verify-hash/", { email: this.userEmail, hash })
                    .then((response) => {
                        this.changingEmail = false;
                        if (response.success === true) {
                            this.isHashVerified = true;
                        }
                    })
                    .catch((e) => {
                        NotificationStore.processException(e, "login");
                    }),
            500
        );
    }

    canOrderContinue() {
        if (this.sentRegisterHash && !this.isHashVerified) {
            return false;
        }

        if (
            !NewOrderStore.paymentType &&
            ((NewOrderStore.backRouteStep && !NewOrderStore.returnRouteMode) || !NewOrderStore.backRouteStep)
        ) {
            return false;
        }

        return true;
    }

    userCreateValidation = (phone, email) => {
        phone = phone || "";
        NewOrderStore.isLoading = true;
        return RootStore.api.get("/users/create-validation/", {
            phone,
            email,
        });
    };

    getPhoneNum = (phoneStr) => {
        const len = NewOrderStore.prefixLength ? NewOrderStore.prefixLength : 4;
        return phoneStr;
    };

    getPrefix = (phoneStr) => {
        const len = NewOrderStore.prefixLength ? NewOrderStore.prefixLength : 4;
        return phoneStr.substr(0, len);
    };

    // Registration method
    async register(values, router) {
        this.isRegistering = true;
        NotificationStore.reset();

        try {
            const payload = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                password: values.password,
                phoneNumber: values.phoneNumber,
                language: values.language,
            };

            const response = await RootStore.api.post("/users/registration/", payload);

            runInAction(() => {
                if (response.status) {
                    // Remove the success notification from here
                    // NotificationStore.processSuccess(i18n.t("Registrace byla úspěšná."), "registration");

                    this.user = null;
                    this.token = null;
                    this.refreshToken = null;
                    CookieStorage.destroy("token");
                    CookieStorage.destroy("refreshToken");
                    CookieStorage.destroy("can_invoice");
                    CookieStorage.destroy("user");
                    CookieStorage.destroy("stripeCustId");

                    pushRoute(
                        router,
                        "login",
                        {
                            lang: router.query.lang,
                            path: "login",
                            registered: true,
                        },
                        { shallow: true }
                    );
                } else {
                    NotificationStore.processError(response.message || i18n.t("Registrace selhala."), "registration");
                }
                this.isRegistering = false;
            });
        } catch (e) {
            runInAction(() => {
                this.isRegistering = false;
                NotificationStore.processException(e, "registration");
            });
        }
    }
}

export default new UserStore();
