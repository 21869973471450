import { observable, makeObservable } from "mobx";
import { message } from "antd";
import RootStore from "../RootStore";
import NotificationStore from "../NotificationStore";
import OrdersListStore from "../Order/OrdersListStore";
import { pushRoute } from "../../components/Router.tsx";
import i18n from "../../core/i18n";
import PaymentStore from "./PaymentStore";
import CurrencyStore from "../Currency/CurrencyStore";
import UserStore from "../UserStore";
import AllowedTourType from "../../components/pages/Tours/detailsPage/BookingSection/AllowedTourType";
import { CookieStorage } from "../../models/CookieStorage";

class PayAllModalStore {
    isLoading = false;

    isModalOpened = false;

    routes = [];

    totalPaymentPrice = 0;

    selectedPaymentMethod = null;

    selectedCurrency = null;

    canBePayed = null;

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            isModalOpened: observable,
            routes: observable,
            totalPaymentPrice: observable,
            selectedPaymentMethod: observable,
            selectedCurrency: observable,
            canBePayed: observable,
        });
    }

    /**
     * Closes the payment modal and resets relevant state.
     */
    close() {
        this.isModalOpened = false;
        this.routes = [];
        this.totalPaymentPrice = 0;
        this.canBePayed = false;
    }

    /**
     * Sets the selected payment method based on available options and current state.
     *
     * @param {Array} data - The list of available payment types.
     * @param {string} value - The currently selected payment method value.
     * @param {boolean} calledFromModal - Indicates if the selection was made from the modal.
     * @param {number} showCount - The number of payment options to display.
     * @param {string} paymentStatus - The current payment status of the route.
     */
    setSelectedPaymentMethod(data, value, calledFromModal, showCount, paymentStatus) {
        const showBillingMethod = (item) =>
            item.name !== PaymentStore.TYPE_BILLING_NAME || (item.name === PaymentStore.TYPE_BILLING_NAME && !calledFromModal);

        const notAllowedTypes = [];
        const allowedTypesWithSelectedCurrency = [];
        const allowedTypes = [];

        data.forEach((originalType) => {
            const type = { ...originalType };
            if (!AllowedTourType(type)) {
                notAllowedTypes.push(type);
            } else if (
                AllowedTourType(type) &&
                !!type.selectedCurrencies.find((selectedCurrency) => selectedCurrency.code === CurrencyStore.selectedCurrency)
            ) {
                const canInvoice = CookieStorage.read("can_invoice");
                if (type.id === PaymentStore.TYPE_BILLING && UserStore && UserStore.user && canInvoice) {
                    allowedTypesWithSelectedCurrency.unshift(type);
                } else {
                    allowedTypesWithSelectedCurrency.push(type);
                }
                if (type.id === PaymentStore.TYPE_COMGATE) {
                    PaymentStore.moveObjectInArray(
                        allowedTypesWithSelectedCurrency,
                        PaymentStore.TYPE_COMGATE_NAME,
                        PaymentStore.TYPE_PAYPAL_NAME
                    );
                }
            } else {
                allowedTypes.push(type);
            }
        });

        const dataTemp = [...allowedTypesWithSelectedCurrency, ...allowedTypes, ...notAllowedTypes];

        const itemData = dataTemp
            .filter(
                (item) =>
                    !paymentStatus ||
                    paymentStatus === OrdersListStore.PAYMENT_STATUS_NOT_PAID ||
                    (paymentStatus !== OrdersListStore.PAYMENT_STATUS_NOT_PAID && item.id === value)
            )
            .filter((item) => item.id !== PaymentStore.TYPE_PAY_LATER)
            .filter((item, index) => index < showCount && showBillingMethod(item) && item.inAdvance);

        if (itemData[0]) {
            this.selectedPaymentMethod = itemData[0].id;
        }
    }

    /**
     * Opens the payment modal with the specified routes.
     *
     * @param {Array|string} routesIds - The IDs of the routes to be paid.
     */
    open(routesIds) {
        this.isModalOpened = true;
        if (Array.isArray(routesIds)) {
            this.routes = routesIds;
        } else {
            this.routes = [routesIds];
        }
        this.routes = OrdersListStore.routes.filter((route) => routesIds.includes(route.id));
        this.getTotalPaymentPrice();
        this.canBePayed = this.hasValidDate();

        const route = this.routes.length === 1 ? this.routes[0] : null;
        if (route && route.arrear < route.totalPrice) {
            this.selectedPaymentMethod = route ? route.paymentType.id : null;
        } else {
            this.setSelectedPaymentMethod(PaymentStore.types, this.selectedPaymentMethod, true, 10, route ? route.paymentStatus.id : null);
        }
        this.selectedCurrency = localStorage.getItem("CurrencyStoreCode");
        // this.selectedCurrency = route ? route.paymentCurrency : null;
    }

    /**
     * Opens the payment modal for a specific tour.
     *
     * @param {Object} tourData - The data of the tour to be paid.
     */
    openTour(tourData) {
        this.isModalOpened = true;
        this.totalPaymentPrice = tourData.totalPrice ? tourData.totalPrice : 0;
        this.canBePayed = true;
        this.selectedCurrency = tourData.paymentCurrency ? tourData.paymentCurrency : null;
        this.selectedPaymentMethod = tourData.paymentId ? tourData.paymentId : null;
    }

    /**
     * Checks if any car associated with the routes has a zero price.
     *
     * @returns {boolean} - Returns true if any car has a zero price, otherwise false.
     */
    hasCarsZeroPrice() {
        for (const route of this.routes) {
            for (const car of route.orderRouteCars) {
                if (car.priceClient <= 0) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Initiates payment for all selected routes.
     *
     * @param {NextRouter} router - The router instance from next/router.
     */
    payRoutes(router) {
        this.isLoading = true;
        const payableRoutes = this.routes.filter((route) => route.arrear !== 0);
        const options = {
            routeIds: payableRoutes.map((route) => route.id),
            paymentMethodId: this.selectedPaymentMethod,
            paymentCurrency: this.selectedCurrency,
        };

        RootStore.api
            .post("/payments/pay-routes-in-advance/", options)
            .then((response) => {
                if (response.redirectPayUrl && response.redirectPayUrl !== "error") {
                    window.location.replace(response.redirectPayUrl);
                } else {
                    OrdersListStore.load();
                    pushRoute(router, "orders", { lang: router.query.lang, path: "orders" }, { shallow: true });

                    if (response.isProforma) {
                        message.warning(i18n.t("Na email byl zaslán zálohový daňový doklad, prosíme o provedení platby"));
                    }

                    if (response.redirectPayUrl && (response.redirectPayUrl === "error" || response.redirectPayUrl === "")) {
                        message.error(
                            i18n.t("Vyskytla se chyba na platební bráně. Opakujte platbu později nebo zvolte jinou platební možnost")
                        );
                    }
                }
            })
            .catch((e) => {
                message.error(i18n.t("Vyskytla se chyba na platební bráně. Opakujte platbu později nebo zvolte jinou platební možnost"));
                NotificationStore.processException(e, "pay-all-modal");
            })
            .finally(() => {
                this.isLoading = false;
                this.close();
            });
    }

    /**
     * Calculates the total payment price for all selected routes.
     */
    getTotalPaymentPrice() {
        this.isLoading = true;
        const options = {
            routeIds: this.routes.map((route) => (route.id ? route.id : route)),
        };

        RootStore.api
            .post("/payments/get-total-payment-price/", options)
            .then((response) => {
                this.totalPaymentPrice = response;
            })
            .catch((e) => {
                NotificationStore.processException(e, "pay-all-modal");
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    /**
     * Checks if the current date allows for payment.
     *
     * @returns {boolean} - Returns true if payment can be made, otherwise false.
     */
    hasValidDate() {
        //const currentTime = moment();
        let canBePayed = true;
        for (const route of this.routes) {
            const routeDate = route.pickupDate.split("+")[0];
            // Customer can now pay anytime
            // if (currentTime.isBetween(moment(routeDate).subtract(SettingsStore.settings.hoursPaymentInAdvance, "h"), moment(routeDate))) {
            //    canBePayed = false;
            //    break;
            // }
        }
        return canBePayed;
    }
}

export default new PayAllModalStore();
