import nookies from "nookies";
import getConfig from "next/config";


const { publicRuntimeConfig } = getConfig();
const isProduction = publicRuntimeConfig.envDev === "prod";
export class CookieStorage {

    
    static getOptions(ctx) {
        return {
            path: "/",
            ...(isProduction && { secure: true }),
            sameSite: "Lax",
        };
    }

    static read(key, ctx = null) {
        const cookies = nookies.get(ctx);
        return cookies[key] || null;
    }

    static write(key, value, ctx = null) {
        nookies.set(ctx, key, value, this.getOptions(ctx));
    }

    static destroy(key, ctx = null) {
        nookies.destroy(ctx, key, this.getOptions(ctx));
    }
}
