import NewOrderStore from "../../stores/Order/NewOrderStore";
import PayAllModalStore from "../../stores/Payment/PayAllModalStore";
import UserStore from "../../stores/UserStore";
import { CookieStorage } from "../../models/CookieStorage";

const isTypeAllowed = (item) => {
    const getcan_invoice = CookieStorage.read("can_invoice");
    if (item.id == 6) {
        if (UserStore.user && getcan_invoice && UserStore.user.can_invoice) {
            return true;
        } else {
            return false;
        }
    }
    //console.log(PayAllModalStore.canBePayed());
    let isZero = NewOrderStore.hasSelectedCarsZeroPrice();
    if (PayAllModalStore.isModalOpened) {
        isZero = PayAllModalStore.hasCarsZeroPrice();
        if (!PayAllModalStore.canBePayed) {
            return false;
        }
    }

    if (
        !isZero &&
        (!NewOrderStore.haveToPayInAdvance[NewOrderStore.getRouteWay()] ||
            (NewOrderStore.haveToPayInAdvance[NewOrderStore.getRouteWay()] && item.inAdvance))
    ) {
        return true;
    }

    return false;
};

export default isTypeAllowed;
