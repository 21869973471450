import { observable, makeObservable } from "mobx";
import { indexOf } from "lodash";
import RootStore from "../RootStore";
import NotificationStore from "../NotificationStore";
import i18n from "../../core/i18n";
import CurrencyStore from "../Currency/CurrencyStore";
import NewOrderStore from "../Order/NewOrderStore";
import PaymentTypeDecorator from "./PaymentTypeDecorator";
import isTypeAllowed from "../../utils/MethodOfPayment/isTypeAllowed";
import UserStore from "../../stores/UserStore";
import { CookieStorage } from "../../models/CookieStorage";

class PaymentStore {
    TYPE_CASH = 1;

    TYPE_CREDIT_CARD_DRIVER = 2;

    TYPE_CREDIT_CARD_ONLINE = 3;

    TYPE_AMEX_CARD = 4;

    TYPE_PAYPAL = 5;

    TYPE_BILLING = 6;

    TYPE_DIRECT_DEBIT = 7;

    TYPE_PAY_LATER = 8;

    TYPE_STRIPE = 9;

    TYPE_COMGATE = 10;

    TYPE_CASH_NAME = "cash";

    TYPE_CREDIT_CARD_DRIVER_NAME = "credit_card_driver";

    TYPE_CREDIT_CARD_ONLINE_NAME = "credit_card_online";

    TYPE_AMEX_CARD_NAME = "amex_card";

    TYPE_PAYPAL_NAME = "paypal";

    TYPE_BILLING_NAME = "billing";

    TYPE_DIRECT_DEBIT_NAME = "direct_debit";

    TYPE_PAY_LATER_NAME = "pay_later";

    TYPE_STRIPE_NAME = "stripe";

    TYPE_COMGATE_NAME = "comgate";

    isTourLoding = false;

    types = [];

    paymentTypesInAdvance = [];

    withCard = i18n.t("Kartou řidiči");

    getIcons = (paymentName) => {
        switch (paymentName) {
            case this.TYPE_CASH_NAME:
                return [{ src: "/img/cash-payment.svg" }];
            case this.TYPE_CREDIT_CARD_DRIVER_NAME:
                return [{ src: "/img/visa.svg" }, { src: "/img/mastercard.svg" }, { src: "/img/american-express.svg" }];
            case this.TYPE_CREDIT_CARD_ONLINE_NAME:
                return [{ src: "/img/visa.svg" }, { src: "/img/mastercard.svg" }];
            case this.TYPE_AMEX_CARD_NAME:
                return [{ src: "/img/american-express.svg" }];
            case this.TYPE_PAYPAL_NAME:
                return [{ src: "/img/paypal.svg" }];
            case this.TYPE_BILLING_NAME:
                return [{ src: "/img/invoice.svg" }];
            case this.TYPE_DIRECT_DEBIT_NAME:
                return [{ src: "/img/transfer.svg" }];
            case this.TYPE_PAY_LATER_NAME:
                return [{ src: "/img/paylater.svg" }];
            case this.TYPE_STRIPE_NAME:
                return [
                    { src: "/img/stripe.svg" },
                    { src: "/img/visa.svg" },
                    { src: "/img/mastercard.svg" },
                    { src: "/img/american-express.svg" },
                ];
            case this.TYPE_COMGATE_NAME:
                return [{ src: "/img/comgate.svg" }];
            default:
                return [];
        }
    };

    defaultSelectedCurrency = (selectedCurrencies) => {
        if (selectedCurrencies.find((selectedCurrency) => selectedCurrency.code === CurrencyStore.selectCurrency)) {
            return CurrencyStore.selectCurrency;
        }
        return selectedCurrencies[0].code;
    };

    moveObjectInArray = (relatedArray, fromName, toName) => {
        const from = relatedArray.find((oneType) => oneType.name === fromName);
        const toIndex = relatedArray.findIndex((oneType) => oneType.name === toName);
        relatedArray.splice(indexOf(relatedArray, from), 1);
        relatedArray.splice(toIndex, 0, from);
    };

    constructor() {
        makeObservable(this, {
            isTourLoding: observable,
            types: observable,
            paymentTypesInAdvance: observable,
        });
    }

    loadTypes() {
        this.isLoading = true;
        this.isTourLoding = true;
        RootStore.api
            .get("/payments/types/")
            .then((response) => {
                this.types = response.map(
                    (paymentMethod) =>
                        new PaymentTypeDecorator({
                            ...paymentMethod,
                            icons: this.getIcons(paymentMethod.name),
                            selectedCurrencyToPay: this.defaultSelectedCurrency(paymentMethod.selectedCurrencies),
                        })
                );

                // CurrencyStore.selectCurrency(LanguageStore.language.translation.currencyCode);
                CurrencyStore.selectCurrencyByLocalStorage();
                this.isLoading = false;
                this.isTourLoding = false;
            })
            .catch((e) => {
                this.isLoading = false;
                this.isTourLoding = false;
                NotificationStore.processException(e, "types-list");
            });
    }

    loadTypesForTours() {
        this.isLoading = true;
        this.isTourLoading = true;
        RootStore.api
            .get("/payments/types/")
            .then((response) => {
                this.types = response
                    .filter((paymentMethod) => paymentMethod.inAdvanceForTour)
                    .map(
                        (paymentMethod) =>
                            new PaymentTypeDecorator({
                                ...paymentMethod,
                                icons: this.getIcons(paymentMethod.name),
                                selectedCurrencyToPay: this.defaultSelectedCurrency(paymentMethod.selectedCurrencies),
                            })
                    );

                // CurrencyStore.selectCurrency(LanguageStore.language.translation.currencyCode);
                CurrencyStore.selectCurrencyByLocalStorage();
                this.isLoading = false;
                this.isTourLoading = false;
            })
            .catch((e) => {
                this.isLoading = false;
                this.isTourLoading = false;
                NotificationStore.processException(e, "types-list");
            });
    }

    loadPaymentTypesInAdvance() {
        RootStore.api
            .get("/payments/paymentTypesInAdvance/")
            .then((response) => {
                this.paymentTypesInAdvance = response;
            })
            .catch(() => {});
    }

    downloadAccountingDocument = (documentEntity) => {
        RootStore.api
            .get(`/payments/accounting-document-download/${documentEntity.id}`)
            .then((response) => {
                const linkSource = `data:application/pdf;base64,${response}`;
                const downloadLink = document.createElement("a");

                downloadLink.href = linkSource;
                downloadLink.download = documentEntity.name;
                downloadLink.click();
            })
            .catch((e) => {
                NotificationStore.processException(e, "payments-downloadInvoice");
            });
    };

    getAllowedPaymentTypes() {
        return this.getSelectedCurrencies().filter((pt) => isTypeAllowed(pt));
    }

    getDefaultPaymentType() {
        const paymentTypes = this.getAllowedPaymentTypes();
        const getcan_invoice = CookieStorage.read("can_invoice");
        // console.log('NewOrderStore',);
        if (UserStore && UserStore.user && getcan_invoice && UserStore.user.can_invoice) {
            return 6;
        } else {
            if (paymentTypes[0]) {
                return paymentTypes[0].id;
            }
        }

        return 1;
    }

    getSelectedCurrencies = () =>
        this.types.filter((type) => {
            if (NewOrderStore.haveToPayInAdvance[NewOrderStore.getRouteWay()]) {
                if (!type.inAdvance) {
                    return false;
                }
            }

            return type.selectedCurrencies.find((currencyTemp) => currencyTemp.code === CurrencyStore.selectedCurrency);
        });
}

export default new PaymentStore();
