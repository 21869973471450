import React, { Component } from "react";
import { Button } from "antd";
import { observer } from "mobx-react";
import { withRouter } from "next/router";
import { Link } from "../Router.tsx";
import i18n from "../../core/i18n";
import UserStore from "../../stores/UserStore";

class UserMenu extends Component {
    render() {
        const { router } = this.props;

        return (
            <>
                {!UserStore.user && (
                    <>
                        <Link route="login" params={{ lang: router.query.lang, path: "login" }}>
                            <Button type="primary" className="user-menu-button">
                                {i18n.t("Přihlásit se")}
                            </Button>
                        </Link>
                        <Link route="register" params={{ lang: router.query.lang, path: "register" }}>
                            <Button type="primary" className="user-menu-button">
                                {i18n.t("Registrace")}
                            </Button>
                        </Link>
                    </>
                )}
                {UserStore.user && (
                    <>
                        <Link
                            route="login"
                            params={{ lang: router.query.lang, path: "login" }}
                            onClick={() => {
                                UserStore.logout(router);
                            }}
                        >
                            <Button type="primary" className="user-menu-button">
                                {i18n.t("Odhlásit se")}
                            </Button>
                        </Link>
                    </>
                )}
            </>
        );
    }
}

export default withRouter(observer(UserMenu));
