import getConfig from "next/config";
import React from "react";
import { Input } from "antd";
import { Form } from "@ant-design/compatible";
import ReCAPTCHA from "react-google-recaptcha";
import i18n from "../core/i18n";

export default class BaseFormSchema {
    constructor(form) {
        this.config = getConfig().publicRuntimeConfig;
        this.form = form;
        this.getFieldDecorator = form.getFieldDecorator;
        this.resetFields = form.resetFields;

        this.items = {
            firstName: {
                id: "firstName",
                label: i18n.t("Jméno"),
                options: {
                    rules: [
                        { required: true, message: i18n.t("Prosím vyplňte jméno") },
                        {
                            pattern: /^[\p{L}\s'.-]+$/u,
                            message: i18n.t("Pouze písmena, mezery, pomlčky, apostrofy a tečky jsou povoleny."),
                        },
                    ],
                },
                element: <Input placeholder={i18n.t("Jméno")} />,
            },
            lastName: {
                id: "lastName",
                label: i18n.t("Příjmení"),
                options: {
                    rules: [
                        { required: true, message: i18n.t("Prosím vyplňte příjmení") },
                        {
                            pattern: /^[\p{L}\s'.-]+$/u,
                            message: i18n.t("Pouze písmena, mezery, pomlčky, apostrofy a tečky jsou povoleny."),
                        },
                    ],
                },
                element: <Input placeholder={i18n.t("Příjmení")} />,
            },
            email: {
                id: "email",
                label: i18n.t("E-mail"),
                options: { rules: [{ required: true, message: i18n.t("Prosím vyplňte email") }] },
                element: <Input type="email" placeholder={i18n.t("E-mail")} />,
            },
            phone: {
                id: "phone",
                label: i18n.t("Mobilní telefon"),
                options: {},
                element: <Input placeholder={i18n.t("Mobilní telefon")} />,
            },
            recaptcha: {
                id: "recaptcha",
                options: { rules: [{ required: true, message: i18n.t("Prosím potvrďte, že nejste robot") }] },
                element: <ReCAPTCHA sitekey={this.config.recaptchaSiteKey} />,
            },
        };
    }

    render(id, options = {}, formItemLayout = {}) {
        if (!this.items[id]) {
            console.error(
                `Item "${id}" does not exist in BaseFormSchema. Check the provided 'id' or ensure it's defined in 'items'.`,
                new Error("Debug Stack Trace")
            );
            return null;
        }

        if (!this.items[id].options.rules) {
            this.items[id].options.rules = [];
        }

        if (options.initialValue) {
            this.items[id].options.initialValue = options.initialValue;
        }

        if (options.valuePropName) {
            this.items[id].options.valuePropName = options.valuePropName;
        }

        const requiredMessage = this.getRequiredMessage(id, options);
        if (requiredMessage) {
            this.items[id].options.rules.push({ required: true, message: requiredMessage });
        }

        const label = this.getLabel(id, options);

        return (
            <Form.Item label={label} {...formItemLayout}>
                {this.getFieldDecorator(id, this.items[id].options)(this.items[id].element)}
            </Form.Item>
        );
    }

    getLabel(id, options) {
        let { label } = options;
        const { label: itemLabel } = this.items[id];

        if (!(label && label.length > 0) && !itemLabel) {
            label = false;
        } else if (itemLabel) {
            label = itemLabel;
        }

        return label;
    }

    getRequiredMessage(id, options) {
        if (options.required && options.required.length > 0) {
            return options.required;
        }

        if (options.required && options.required === true) {
            const { requiredMessage } = this.items[id];
            if (requiredMessage && requiredMessage.length > 0) {
                return requiredMessage;
            }
            console.error(`No require message for item ${id}`); // eslint-disable-line no-console
        }

        return null;
    }
}
